import { createSlice } from '@reduxjs/toolkit'
const currencyChangerSlice = createSlice({
    name: 'currency',
    initialState: {
        currency: 'INR',
        symbol: '₹', 
    },
    reducers: {
        changeCurrency(state, actions) {
            state.currency = actions.payload.currency
            state.symbol = actions.payload.currency === 'USD' ? '$' :'₹'
        },
    }
})

export const { changeCurrency } = currencyChangerSlice.actions;
export default currencyChangerSlice;
