import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux"

export default function AuthLayout() {
    const location = useLocation();
    const token = useSelector((state: any) => state.user.token) 

    return (
        token ?
            <div>
                <Outlet />
            </div>
            :
            <Navigate to="/login" state={{ from: location }} replace />
    );
}