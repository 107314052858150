import DataTable from 'react-data-table-component';
import tagService from '../../service/tagService';
import axios from '../../service/api';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

const TagList = () => {
    const [tag, setTag]=useState<any>()
    const navigate = useNavigate();
    const { slug } = useParams()

    useEffect(() => {
        getTag();
    }, []);
    const getTag = async () => {
        try {
            const response = await tagService.GetAllTag();
            setTag(response?.data?.data);
        } catch (error) {
            console.error(error);
        }
    }

    // Edit Category
    const tagEdit = (editid: any) => {
        // console.log(slugid);
        navigate("edit/" + editid)

    }
    // Delete Category
    const tagDelete = async (deletetitle: any) => {
        alert(deletetitle)
        try {
            const response = await axios.delete("http://localhost:5050/api/category/" + deletetitle);
            // const response = await productService.categoryDelete(deleteid);
            getTag()
            toast.success('Product Delete Successfully')

        } catch (error) {
            console.error(error);
        }

    }
    // console.log(category);
    const columns = [
        {
            name: 'Tag',
            selector: (row: any) => row.name,
            sortable: true,
        },
        {
            name: 'Created',
            selector: (row: any) => row.createdAt,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row: any) => <><button onClick={() => tagEdit(row.name)} className='btn btn-success btn_edit'><i className="bi bi-pencil-square"></i></button>
                <button onClick={() => tagDelete(row.title)} className='btn_edit btn btn-danger'><i className="bi bi-trash"></i></button>
            </>,
        },
    ];

    return (
        <>
            <div className="card p-4">
                <DataTable
                    columns={columns}
                    data={tag}
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight='600px'
                    selectableRows
                    selectableRowsHighlight
                    highlightOnHover
                    subHeader
                    defaultSortAsc={true}
                    subHeaderComponent={
                        <input className='searchbar' type='text' placeholder='Search Here' />
                    }
                />
            </div>
        </>
    )
}

export default TagList