import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import priceSettingService from '../../../../service/priceSettingService';

const DiamondTab = () => {
    const [diamondPrice, setDiamondPrice] = useState<any>()

    useEffect(() => {
        getPriceSetting()
    }, [])

    const { values, handleChange, handleSubmit, setFieldValue, } = useFormik({

        initialValues: {
            name: "",
            price: ""
        },
        onSubmit: async (value) => {
            try {
                let response = await priceSettingService.CreateDiamondPriceSetting(value)
                toast.success(response?.data?.msg)
                setFieldValue('name', "")
                setFieldValue('price', "")
                getPriceSetting()

            } catch (error) {
                toast.error((error as any)?.response?.data?.msg ? (error as any)?.response?.data?.msg : 'error')
            }
        },
    });

    const getPriceSetting = async () => {
        try {
            const response = await priceSettingService.GetAllDiamondPrice()
            setDiamondPrice(response?.data?.data)

        } catch (err) {

        }
    }

    const handleRemove = async (id:any)=>{
        const res = await priceSettingService.DeleteDiamondPriceSetting(id)
      
        toast.success(res?.data?.msg)
        getPriceSetting()
    }
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="card-body">
                    {
                        diamondPrice ? diamondPrice.map((i: any) =>

                            <div className="mb-2" key={i._id}>
                                <div className="price-setting-row">
                                    <input type="text" placeholder={i.name} value={i.name} className="form-control create-product"  />
                                    <input type="text" placeholder={i.price} className="form-control create-product" disabled />
                                    <i className="bi bi-trash3-fill" onClick={() =>handleRemove(i._id)}></i>
                                </div>
                            </div>
                        ) : ""
                    }

                    <div className="card-top">
                        <h5 className='mt-5'>Add New</h5>
                    </div>
                    <div className="mb-4">
                        <div className="price-setting-row">
                            <input type="text" placeholder="name" className="form-control create-product" name="name" value={values.name} onChange={handleChange} />
                            <input type="text" placeholder="price" className="form-control create-product" name="price" value={values.price} onChange={handleChange} />
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="product-btn-area">
                            <button className='primary-button ms-1' type='submit'>Create</button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default DiamondTab