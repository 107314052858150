import DataTable from 'react-data-table-component';
import productService from '../../service/productService';
import axios from '../../service/api';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

const CategoriesCompo = () => {
    const navigate = useNavigate();
    const { slug } = useParams()
    const [category, setcategory] = useState([])
    useEffect(() => {
        getcategories();
    }, []);
    const getcategories = async () => {
        try {
            const response = await productService.getAllcategories();
            const catdata = response?.data?.data;
            setcategory(catdata)
            // console.log("getdata", catdata);
            // setdata(data);
        } catch (error) {
            console.error(error);
        }
    }

    // Edit Category
    const catedit = (editid: any) => {
        // console.log(slugid);
        navigate("edit/" + editid)

    }
    // Delete Category
    const catdelete = async (deletetitle: any) => {
        alert(deletetitle)
        try {
            const response = await axios.delete("http://localhost:5050/api/category/" + deletetitle);
            // const response = await productService.categoryDelete(deleteid);
            getcategories()
            toast.success('Product Delete Successfully')

        } catch (error) {
            console.error(error);
        }

    }

    const addSub = (row:any)=>{
        console.log('row', row);
        

    }

    // console.log(category);
    const columns = [
        {
            name: 'Category Title',
            selector: (row: any) => row.title,
            sortable: true,
        },
        {
            name: 'Slug',
            selector: (row: any) => row.slug,
        },
        {
            name: 'Description',
            selector: (row: any) => row.description,
            // sortable: true,
        },
        {
            name: 'Created',
            selector: (row: any) => row.createdAt,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row: any) => <>
            <button onClick={() => addSub(row)} className='btn btn-success btn_edit'>
            <i className="bi bi-plus"></i>
            </button>
            <button onClick={() => catedit(row.title)} className='btn btn-success btn_edit'>
                <i className="bi bi-pencil-square"></i>
            </button>
            <button onClick={() => catdelete(row.title)} className='btn_edit btn btn-danger'>
                <i className="bi bi-trash"></i>
            </button>
            </>,
        },
    ];

    return (
        <>
            <div className="card p-4">
                <DataTable
                    columns={columns}
                    data={category}
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight='600px'
                    selectableRows
                    selectableRowsHighlight
                    highlightOnHover
                    subHeader
                    defaultSortAsc={true}
                    subHeaderComponent={
                        <input className='searchbar' type='text' placeholder='Search Here' />
                    }
                />
            </div>
        </>
    )
}

export default CategoriesCompo