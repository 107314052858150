import React from 'react'
interface dashboardboxprops {
    title: String,
    value: String,
    desc: String,
    icon: String,

}
const DashboardBox = ({ title, value, desc, icon }: dashboardboxprops) => {
    return (
        <>
            <div className="dash_box">
                <div className="card card-body mb-4">
                    <article className="icontext">
                        <span className="icon icon-sm rounded-circle bg-primary-light"><i className={"bi " + icon}></i></span>
                        <div className="text">
                            <h6 className="mb-1 card-title">{title}</h6>
                            <span>{value}</span>
                            <span className="text-sm"> {desc} </span>
                        </div>
                    </article>
                </div>
            </div>
        </>
    )
}

export default DashboardBox