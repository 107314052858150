import axios from "./api"

const GetAllSettings = () => {
    return axios.get('/globalsetting')
}
const updatelSettings = (payload) => {
    return axios.put('/globalsetting/' + payload.id, payload)
}
const createSetting = (payload) => {
    return axios.post('/globalsetting' + payload)
}


const globalSettingService = {
    GetAllSettings,
    updatelSettings,
    createSetting

}

export default globalSettingService;