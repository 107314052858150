import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { log } from "console";
import toast from 'react-hot-toast';

interface CartItem {
    _id: string;
    title: string;
    quantity: number;
    // Add other properties as needed
}

interface CartState {
    cartItems: CartItem[];
    cartTotalQuantity: number;
    cartTotalAmount: number;
}

const getInitialCartItems = (): CartItem[] => {
    const storedCartItems = localStorage.getItem('cartItems');
    return storedCartItems ? JSON.parse(storedCartItems) : [];
};

const initialState: CartState = {
    cartItems: getInitialCartItems(),
    cartTotalQuantity: 0,
    cartTotalAmount: 0
};

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addToCart(state, action: PayloadAction<CartItem>) {
            const itemIndex = state.cartItems.findIndex(item => item._id === action.payload._id);
            if (itemIndex >= 0) {
                state.cartItems[itemIndex].quantity += 1;
                toast.success('Increased ' + action.payload.title + ' cart quantity');
            } else {
                const tempProduct: CartItem = { ...action.payload, quantity: 1 };
                state.cartItems.push(tempProduct);
                toast.success(action.payload.title + ' added to cart successfully');
            }
            localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
            const {subTotalAmount, subTotalQuantity}=state.cartItems.reduce((cartTotal, cartItem)=>{
                const {cartTotalAmount, quantity}:any=cartItem
                const itemTotalAmount = cartTotalAmount * quantity

                cartTotal.subTotalAmount += itemTotalAmount
                cartTotal.subTotalQuantity += quantity
                return cartTotal

            }, {
                subTotalAmount:0,
                subTotalQuantity:0
            })
            state.cartTotalAmount = subTotalAmount
            state.cartTotalQuantity = subTotalQuantity
        },

        removeCartItem(state, action: PayloadAction<CartItem>) {
            const itemsAfterRemove = state.cartItems.filter((item: any) =>
                item._id !== action.payload
            )
            state.cartItems = itemsAfterRemove
            localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
            toast.error(' item removed successfully from cart');
        },

        decreasequantity(state, action: PayloadAction<CartItem>) {
            const itemIndex = state.cartItems.findIndex(item => item._id === action.payload._id);
            state.cartItems[itemIndex].quantity -= 1;
            localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
            toast.success('product decreased by one quantity');
        },

        increasequantity(state, action: PayloadAction<CartItem>) {
            const itemIndex = state.cartItems.findIndex(item => item._id === action.payload._id);
            state.cartItems[itemIndex].quantity += 1;
            localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
            toast.success('product increased by one quantity');
        },
        getCartTotal(state){
            const {subTotalAmount, subTotalQuantity}=state.cartItems.reduce((cartTotal, cartItem)=>{
                const {cartTotalAmount, quantity}:any=cartItem
                const itemTotalAmount = cartTotalAmount * quantity

                cartTotal.subTotalAmount += itemTotalAmount
                cartTotal.subTotalQuantity += quantity
                return cartTotal

            }, {
                subTotalAmount:0,
                subTotalQuantity:0
            })
            state.cartTotalAmount = subTotalAmount
            state.cartTotalQuantity = subTotalQuantity
        },
        deleteCartItemAfterPayment(state){
            state.cartItems = []
            localStorage.removeItem('cartItems');
            localStorage.removeItem('rzp_device_id ');
            localStorage.removeItem('rzp_checkout_anon_id');
        }

    }
});

export const { addToCart, removeCartItem, decreasequantity, increasequantity, getCartTotal, deleteCartItemAfterPayment} = cartSlice.actions;
export default cartSlice.reducer;
