import React from 'react'
import { Link } from 'react-router-dom'
import Slider from "react-slick";
const ProductSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 2000,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000, 

    };
    return (
        <div className="new__collection__wrapper section-padding">
            <div className="arsah-container-fluid">
                <div className="section__title">
                    New Collection
                </div>
                <div className="row">   
                <Slider {...settings}>
                    <div className="col-md-3">
                        <div className="product__box">
                            <div className="product__image">
                                <Link to={'/frontend'}>
                                    <img src="./assets/images/filter/slider/slider1.jpeg" className='img-fluid' alt="" />
                                </Link>
                            </div>
                            <div className="product__content">

                                <div className="product__title">
                                    Eyes Ring
                                </div>
                                <div className="product__info">
                                    Golden Line
                                </div>
                                <div className="product__action">
                                    <div className="product__price">
                                        <Link to={'/frontend'}>
                                            4,500
                                        </Link>
                                    </div>
                                    <div className="product__cart">
                                        <Link to={'/frontend'}>
                                            Add To Cart
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="product__box">
                            <div className="product__image">
                                <Link to={'/frontend'}>
                                    <img src="./assets/images/filter/slider/slider2.jpeg" className='img-fluid' alt="" />
                                </Link>
                            </div>
                            <div className="product__content">

                                <div className="product__title">
                                    Eyes Ring
                                </div>
                                <div className="product__info">
                                    Golden Line
                                </div>
                                <div className="product__action">
                                    <div className="product__price">
                                        <Link to={'/frontend'}>

                                            4,500
                                        </Link>
                                    </div>
                                    <div className="product__cart">
                                        <Link to={'/frontend'}>
                                            Add To Cart
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="product__box">
                            <div className="product__image">
                                <Link to={'/frontend'}>
                                    <img src="./assets/images/filter/slider/slider3.jpeg" className='img-fluid' alt="" />
                                </Link>
                            </div>
                            <div className="product__content">

                                <div className="product__title">
                                    Eyes Ring
                                </div>
                                <div className="product__info">
                                    Golden Line
                                </div>
                                <div className="product__action">
                                    <div className="product__price">
                                        <Link to={'/frontend'}>

                                            4,500
                                        </Link>
                                    </div>
                                    <div className="product__cart">
                                        <Link to={'/frontend'}>
                                            Add To Cart
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="product__box">
                            <div className="product__image">
                                <Link to={'/frontend'}>
                                    <img src="./assets/images/filter/slider/slider5.jpeg" className='img-fluid' alt="" />
                                </Link>
                            </div>
                            <div className="product__content">

                                <div className="product__title">
                                    Eyes Ring
                                </div>
                                <div className="product__info">
                                    Golden Line
                                </div>
                                <div className="product__action">
                                    <div className="product__price">
                                        <Link to={'/frontend'}>

                                            4,500
                                        </Link>
                                    </div>
                                    <div className="product__cart">
                                        <Link to={'/frontend'}>
                                            Add To Cart
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="product__box">
                            <div className="product__image">
                                <Link to={'/frontend'}>
                                    <img src="./assets/images/filter/slider/slider5.jpeg" className='img-fluid' alt="" />
                                </Link>
                            </div>
                            <div className="product__content">

                                <div className="product__title">
                                    Eyes Ring
                                </div>
                                <div className="product__info">
                                    Golden Line
                                </div>
                                <div className="product__action">
                                    <div className="product__price">
                                        <Link to={'/frontend'}>

                                            4,500
                                        </Link>
                                    </div>
                                    <div className="product__cart">
                                        <Link to={'/frontend'}>
                                            Add To Cart
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
</Slider>
                </div>
            </div>
            
        </div>
        
    )
}

export default ProductSlider