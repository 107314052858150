import axios from "./api"

const GetAvlDiamondType = () => {
    return axios.get('/filter/diamond-type')
}
const GetAvlStoneType = () => {
    return axios.get('/filter/stone-type')
}
const GetAvlMetalType = () => {
    return axios.get('/filter/metal-type')
}
const GetAvlBrand = () => {
    return axios.get('/filter/brand')
}

const filterService = {
    GetAvlDiamondType,
    GetAvlStoneType,
    GetAvlMetalType,
    GetAvlBrand
}

export default filterService;