import React, { useCallback, useEffect, useMemo, useState } from 'react'
import globalSettingService from '../../../service/globalSettingService'
import priceSettingService from '../../../service/priceSettingService';
import { getDiamondTypeList } from '../../../slices/DropdownSlice';
import { useDispatch, useSelector } from 'react-redux' 
import { getDymondPriceById, getStonePriceById } from '../../../utils/utils';

function ProductVarient({ data, onChange }: any) {
    const dispatch = useDispatch()
    const { symbol } = useSelector((state: any) => state.currency)
    const { diamondTypeList, stoneTypeList } = useSelector((state: any) => state.dropdown)

    const [diamond_per_carat_weight, setdiamond_per_carat_weight] = useState(0.2)
    const [item, setitem] = useState<any>()

    useEffect(() => {
        if (data) {
            setitem(data)
        }
    }, [data])

    useEffect(() => {
        const GetGlobalSetting = async () => {
            try {
                const response = await globalSettingService.GetAllSettings()
                setdiamond_per_carat_weight(response.data?.data?.diamond_per_carat_weight)

            } catch (error) {
                console.log(error);
            }
        }
        const GetDiamondPrice = async () => {
            try {
                const response = await priceSettingService.GetAllDiamondPrice()

                dispatch(getDiamondTypeList(response.data.data))
            } catch (error) {
                console.log(error);
            }
        }

        if (dispatch !== undefined) {
            GetGlobalSetting()
            GetDiamondPrice()
        }
    }, [dispatch]);


    const dimondPrice = useCallback((j: any) => {
       const type = j?.diamondType ? j?.diamondType : 0
       const carat = j?.diamondCarat ? j?.diamondCarat : 0
       const qty = j?.diamondQty ? j?.diamondQty : 0
        const num = (getDymondPriceById(diamondTypeList, type) * parseFloat(carat)) * parseFloat(qty)
        
        
        return parseFloat((Math.round(num * 100) / 100).toFixed(2))
    }, [diamondTypeList])

    const dimondWight = useCallback((j: any) => { 
        const carat = j?.diamondCarat ? j?.diamondCarat : 0
        const qty = j?.diamondQty ? j?.diamondQty : 0

        const num = (parseFloat(carat) * diamond_per_carat_weight) * parseFloat(qty)
        return parseFloat((Math.round(num * 100) / 100).toFixed(2))
    }, [diamond_per_carat_weight])

    const stonePrice = useCallback((j: any) => {
        const type = j?.stoneType ? j?.stoneType : 0
        const carat = j?.stoneCarat ? j?.stoneCarat : 0
        const qty = j?.stoneQty ? j?.stoneQty : 0

        const num = (getStonePriceById(stoneTypeList, type) * parseFloat(carat)) * parseFloat(qty)
        return parseFloat((Math.round(num * 100) / 100).toFixed(2))
    }, [stoneTypeList])

    const stoneWight = useCallback((j: any) => {
        const carat = j?.stoneCarat ? j?.stoneCarat : 0
        const qty = j?.stoneQty ? j?.stoneQty : 0

        const num = (parseFloat(carat) * diamond_per_carat_weight) * parseFloat(qty)
        return parseFloat((Math.round(num * 100) / 100).toFixed(2))
    }, [diamond_per_carat_weight])

    const totalPrice = useCallback((currentItem = item) => {

        let mainTotal = 0
        let metalTotal = 0
        let diamondTotal = 0
        let stoneTotal = 0

        if (currentItem?.metalWeight !== undefined) {
            metalTotal = parseFloat(currentItem?.mainprice) * parseFloat(currentItem?.metalWeight)
        }
        if (currentItem?.diamond !== undefined) {
            for (const iterator of currentItem?.diamond) {
                if (iterator?.diamondQty !== undefined) {
                    diamondTotal += dimondPrice(iterator)
                }
            }
        }

        if (currentItem?.stone !== undefined) {
            for (const iterator of currentItem?.stone) {
                if (iterator?.stoneQty !== undefined) {
                    stoneTotal += stonePrice(iterator)
                }
            }
        } 
        
        mainTotal = metalTotal + diamondTotal + stoneTotal

        return parseFloat((Math.round(mainTotal * 100) / 100).toFixed(2))
    }, [dimondPrice, item, stonePrice])

    const totalWight = useCallback((currentItem = item) => {
        let mainTotal = 0
        let metalTotal = 0
        let diamondTotal = 0
        let stoneTotal = 0

        if (currentItem?.metalWeight !== undefined) {
            metalTotal = parseFloat(currentItem?.metalWeight)
        }
        if (currentItem?.diamond !== undefined) {
            for (const iterator of currentItem?.diamond) {
                if (iterator?.diamondQty !== undefined) {
                    diamondTotal = dimondWight(iterator)
                }
            }
        }
        if (currentItem?.stone !== undefined) {
            for (const iterator of currentItem?.stone) {
                if (iterator?.stoneQty !== undefined) {
                    stoneTotal = stoneWight(iterator)
                }
            }
        }

        mainTotal = metalTotal + diamondTotal + stoneTotal
        return parseFloat((Math.round(mainTotal * 100) / 100).toFixed(2))
    }, [dimondWight, item, stoneWight])


    const onHandleItemChange = useCallback((e: any, id: string, index: number | undefined = undefined, type: string | undefined = undefined) => {
        const mainValue = e.target.value ? e.target.value : 0;
        const mainName = e.target.name;  
        const currentItem = {...item, [mainName] : mainValue} 
        console.log('totasl',totalPrice(currentItem));
        
        onChange(e, id, totalPrice(currentItem), totalWight(currentItem), index, type)

    }, [item, onChange, totalPrice, totalWight])

    const metalprice = useCallback(() => {
        if (!item?.metalWeight) {
            return 0
        }
        const p = parseFloat(item?.mainprice ? item?.mainprice : 0) * parseFloat(item?.metalWeight ? item?.metalWeight : 0);
        return p ? p : 0
    }, [item?.mainprice, item?.metalWeight])

    return (
        <>
            <div className="mb-3 border-bottom pb-3" key={item?._id}>
                <div className="row mb-4 align-items-center">
                    <div className="col-lg-4" >
                        <label className="form-label" style={{ fontWeight: "600" }}>{item?.name}</label>
                    </div>
                    <div className="col-lg-8">
                        <label className="form-label">Metal Weight (Net.) (gm)</label>
                        <input type="number" className="form-control create-product" name="metalWeight" value={item?.metalWeight} onChange={(e) => onHandleItemChange(e, item?._id)} />
                        <small>Metal Price : {symbol}{metalprice()}</small>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ProductVarient