import React, { useCallback, useEffect, useState } from 'react'
import FilterByPrice from '../../components/Frontend/Common/FilterByPrice'
import Breadcrumbs from "../../components/Frontend/Common/Breadcrumbs";
import ShortOptions from '../../components/Frontend/Common/ShortOptions';
import ProductCard from '../../components/Frontend/Common/ProductCard';
import productService from '../../service/productService'
import { useLocation } from 'react-router-dom';

const Products = () => {
    const location = useLocation();
    const [products, setProducts] = useState<any>([])
    const [isLoading, setisLoading] = useState<boolean>(false)
    const [page, setPage] = useState<number>(1);
 
    
    

    const getAllProducts = useCallback( async (filter: any, p:number) => {
        try {
            setisLoading(true)
            const response = await productService.getAllproduct(p, filter)
            const data = response?.data?.data || [];
            setProducts(data);
            setisLoading(false)

        } catch (error) {
            setisLoading(false)
            console.error(error);
        }
    }, [])



    useEffect(() => {
        const fullPathAfterProducts = location.pathname + location.search;
        const filter = fullPathAfterProducts.startsWith('/products?')
            ? fullPathAfterProducts.replace('/products?', '')
            : fullPathAfterProducts;
        
        getAllProducts(filter, page)
    }, [page, location, getAllProducts])




    return (
        <>
            <Breadcrumbs />
            <div className="all-products-wrapper">
                <div className='row'>
                    <div className='col col-12 col-md-8 col-lg-9 order-md-2'>
                        {isLoading ?
                            <div className="loader-wrapper">
                                <span className="loader"></span>
                            </div> :
                            <div className="product-list-wrapper">
                                <ShortOptions />
                                <section className='products-wrap'>
                                    <div className='row g-3'>
                                        {
                                            products?.map((item: any) =>
                                                <div key={item?._id} className='col col-12 col-md-6 col-lg-4 col-xl-3'><ProductCard data={item} /></div>
                                            )
                                        }
                                    </div>
                                </section>
                            </div>
                        }
                    </div>
                    <div className="col col-12 col-md-4 col-lg-3 order-md-1" >
                        <div className="product-options-sidebar">
                            <FilterByPrice title="Filters" />
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Products
