import DataTable from 'react-data-table-component';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useNavigate, useParams } from 'react-router-dom';
import transactionService from '../../service/transactionService';
import { useEffect, useState } from 'react';
import orderService from '../../service/orderService';
import Moment from 'react-moment';

const OrderDetails = () => {
    const [transaction, setTransaction] = useState<any>([])
    const[orders, setOrders]=useState<any>([])
    
    let { id } = useParams();
    const navigate = useNavigate()

 
    const columns = [
        {
            name: 'Payment Id',
            selector: (row: any) => row?.razorPay_orderId,
            sortable: true,
        },
        {
            name: 'Customer Name',
            selector: (row: any) => row?.customer_name,
        },
        {
            name: 'Status',
            selector: (row: any) => row?.payment_status,
            sortable: true,
        },
        {
            name: 'Total',
            selector: (row: any) => {
                console.log('row', row);
                
            },
            sortable: true,
        },
        {
            name: 'Creted',
            selector: (row: any) => <Moment format="YYYY/MM/DD">{row?.createdAt}</Moment>,
            sortable: true,
        },
        {
            name: '',
            cell: (row: any) => <div>
                <button onClick={()=>navigate(`/admin/detail/${row._id}`)} className='primary-button edit-btn'>Details</button>
            </div>

        },
        {
            name: 'Action',
            cell: (row: any) =>
                <div className='order-details-btn'>
                    <DropdownButton id="dropdown-basic-button" title={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots" viewBox="0 0 16 16">
                        <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                    </svg>}>
                        <Dropdown.Item onClick={()=>navigate(`/admin/detail/${row._id}`)}>View Details</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Edit Info</Dropdown.Item>
                        <Dropdown.Item style={{ color: "red" }} href="#/action-2">Delete</Dropdown.Item>
                    </DropdownButton>
                </div>

        },
    ];

    const getAllTransactionData = async  ()=>{
        const result = await transactionService.GetAllTransaction()
        setTransaction(result?.data?.data)
        
    }
    const getOrders = async  ()=>{
        const res = await orderService.getOrders()
        setOrders(res?.data?.data)
    }

    useEffect(()=>{
        getAllTransactionData()
        getOrders()
    },[])
    return (
        <div className="card p-4">
            <DataTable
                columns={columns}
                data={orders}
                pagination
                fixedHeader
                fixedHeaderScrollHeight='600px'
                selectableRowsHighlight
                highlightOnHover
                subHeader
                subHeaderComponent={
                    <input className='searchbar' type='text' placeholder='Search Here' />
                }
            />
        </div>
    )
}

export default OrderDetails