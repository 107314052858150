import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'

import {logOut} from '../../slices/AuthSlice'

const AdminHeader = () => {
    const dispatch = useDispatch()
    const handleLogout=()=>{
        dispatch(logOut())
    }
    return (
        <div className='admin-header d-flex align-items-center justify-content-between'>
            <div>
                <Link to="/admin" className='d-flex align-items-center link-style-black header-left-area'>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-display" viewBox="0 0 16 16">
                            <path d="M0 4s0-2 2-2h12s2 0 2 2v6s0 2-2 2h-4c0 .667.083 1.167.25 1.5H11a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1h.75c.167-.333.25-.833.25-1.5H2s-2 0-2-2V4zm1.398-.855a.758.758 0 0 0-.254.302A1.46 1.46 0 0 0 1 4.01V10c0 .325.078.502.145.602.07.105.17.188.302.254a1.464 1.464 0 0 0 .538.143L2.01 11H14c.325 0 .502-.078.602-.145a.758.758 0 0 0 .254-.302 1.464 1.464 0 0 0 .143-.538L15 9.99V4c0-.325-.078-.502-.145-.602a.757.757 0 0 0-.302-.254A1.46 1.46 0 0 0 13.99 3H2c-.325 0-.502.078-.602.145z" />
                        </svg>
                    </div>
                    <div>
                        <p className='m-0 ps-2'>
                        <Link to={'/'}>Visit Store</Link>
                        </p>
                    </div>

                </Link>
            </div>
            <div>
                <div className="dropdown">
                    <DropdownButton id="dropdown-basic-button" title={
                        <div className="link-style-black d-flex align-items-center">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                    <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                </svg>
                            </div>
                            <div>
                                <p className='m-0 ps-2'>Niel</p>
                            </div>
                        </div>
                    }>
                        <Dropdown.Item href="#/action-1">Profile</Dropdown.Item>
                        <Dropdown.Item onClick={()=>handleLogout()}>Log Out</Dropdown.Item>
                    </DropdownButton>
                </div>
            </div>

        </div>
    )
}

export default AdminHeader