
import DashboardBox from '../../../components/common/DashboardBox'

const Dashboard = () => {

  return (
    <div className='container-fluid'>
      <div className="d-flex align-items-center justify-content-between pb-4">
        <div className="product-title">
          <h2 className='main-title'>Dashboard</h2>
          <p className='m-0'>Whole data about your business here</p>
        </div>
        <div className="product-btn-area">
          <button className='primary-button ms-1'>Create Post</button>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3">
          <DashboardBox
            title="Total Sale"
            desc="We are team of "
            value="$154,000"
            icon="bi-currency-exchange"
          />
        </div>
        <div className="col-lg-3">
          <DashboardBox
            title="Total Orders"
            desc="We are team of "
            value="18"
            icon="bi-cart3"
          />
        </div>
        <div className="col-lg-3">
          <DashboardBox
            title="Total Products"
            desc="We are team of "
            value="6"
            icon="bi-basket"
          />
        </div>
        <div className="col-lg-3">
          <DashboardBox
            title="Total Customer"
            desc="We are team of "
            value="6"
            icon="bi-people"

          />
        </div>
      </div>
      {/* <button onClick={() => dispatch(addValue())} className='btn btn-primary'>+</button>
      <input type="text" value={mydata} onChange={() => { }} />
      <button onClick={() => dispatch(minusValue())} className='btn btn-danger'>-</button> */}
    </div>
  )
}

export default Dashboard
