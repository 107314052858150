import axios from "./api"

const creatWishList = (data) => {
    return axios.post('/wishlist', data)
}
const getWishList = (id) => {
    return axios.get('/wishlist/' + id)
}
const deleteWishList = (id) => {
    return axios.delete('/wishlist/' + id)
}


const wishListService = {
    creatWishList,
    getWishList,
    deleteWishList
}

export default wishListService;