import axios from "./api";

// Add Product
const productAdd = (payload) => {
  return axios.post("/product", payload);
};
// Get Products
const getAllproduct = (page, filter) => {
  // console.log('filter', filter);
  
  // let mainparams = ''

  // for (const element of filter) {
  //   if(element?.attributeName){
  //     mainparams = mainparams + '&attributeName=' + element?.attributeName + '&attributeValue=' + element?.attributeValue
  //   }
  // }

  if(filter){
    return axios.get(`/product?page=${page}&filter=${filter}`);
  }else{
    return axios.get(`/product?page=${page}`);
  }
};
// Get Products Updated
const getUpdateProduc = (payload) => {
  return axios.put("/product", payload);
};

const getOneProduct =(path, query = '')=>{
  return axios.get("/product/" + path + query);
}

// Get all categories
const getAllcategories = () => {
  return axios.get("/category");
};
// Add Category
const categoryAdd = (payload) => {
  return axios.post("/category", payload);
};
// put Products Updated
const getUodateCategory = (payload) => {
  return axios.put("/category", payload);
};
// Delete category
const categoryDelete = (payload) => {
  return axios.delete("/category", payload);
};
const productService = {
  productAdd,
  getAllproduct,
  getUpdateProduc,
  getOneProduct,

  
  // categories part
  getAllcategories,
  categoryAdd,
  categoryDelete,
  getUodateCategory,
};

export default productService;
