import React from 'react'
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import tagService from '../../../service/tagService'

const AddTag = () => {
    const { values, handleChange, handleSubmit, setFieldValue } = useFormik({

        initialValues: {
            name: ""
        },
        onSubmit: async (value) => {
            try {

                let response = await tagService.CreateTag(value)
                toast.success(response?.data?.msg)
            } catch (error) {
                toast.error((error as any)?.response?.data?.msg ? (error as any)?.response?.data?.msg : 'error')
            }
        },
    });

  return (
    <>
          <form onSubmit={handleSubmit}>
                <div className="container-fluid">
                    <div className="d-flex align-items-center justify-content-between pb-4">
                        <div className="product-title">
                            <h2 className='main-title'>Add New Tag</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card p-4">
                                <div className="card-top">
                                    <h5 className='m-0'>Basic</h5>
                                </div>
                                <div className="card-body">
                                    <div className="mb-4">
                                        <label htmlFor="name" className="form-label">Tag Name</label>
                                        <input type="text" placeholder="Type Here" className="form-control create-product" name="name" value={values.name} onChange={handleChange} />
                                    </div>
                                    <div className="product-btn-area">
                                        <button className='primary-button ms-1' type='submit'>Create</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
    </>
  )
}

export default AddTag