import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const FilterGallery = () => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <section className='filer__gallery__section section-padding'>
            <div className="container">

                <div className="section__title">
                    jewellery Passion
                </div>
                <div className="gallery__content">
                    <div className="tabs">
                        <span>Show All</span>
                        <span>Gold Rings </span>
                        <span>New Arrival</span>
                        <span>Discounted Products</span>
                        <hr />
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="product__grids">
                                <div className="product__image">
                                    <div className="product__media">
                                        <Link to={'/frontend'} >
                                            <img className="img-fluid" src="/assets/images/filter/filter-image-2.jpeg" alt="" />
                                        </Link>
                                    </div>
                                    <div className="product__media_hover">
                                        <Link to={'/frontend'}>
                                            <img className="img-fluid" src="./assets/images/filter/filter-image-hover-2.jpeg" alt="" />
                                        </Link>
                                    </div>
                                <div className="product__action">
                                    <Link to={'/frontend'}>Add To Cart</Link>
                                </div>
                                </div>
                                <div className="products__content">
                                    <div className="title__holder">
                                        <div className="product__title">
                                            small bracelet
                                        </div>
                                        <div className="products__actions">
                                            <i className="bi bi-eye"></i>
                                            <i className="bi bi-heart"></i>
                                        </div>
                                    </div>
                                    <div className="product__info">
                                        Bracelets/elegant/gold accssesories
                                    </div>
                                    <div className="products__price">
                                        Rs. 34,500/-
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="product__grids">
                                <div className="product__image">
                                    <div className="product__media">
                                        <Link to={'/frontend'} >
                                            <img className="img-fluid" src="/assets/images/filter/filter-image-3.jpeg" alt="" />
                                        </Link>
                                    </div>
                                    <div className="product__media_hover">
                                        <Link to={'/frontend'}>
                                            <img className="img-fluid" src="./assets/images/filter/filter-image-hover3.jpeg" alt="" />
                                        </Link>
                                    </div>
                                <div className="product__action">
                                    <Link to={'/frontend'}>Add To Cart</Link>
                                </div>
                                </div>
                                <div className="products__content">
                                    <div className="title__holder">
                                        <div className="product__title">
                                            small bracelet
                                        </div>
                                        <div className="products__actions">
                                            <i className="bi bi-eye"></i>
                                            <i className="bi bi-heart"></i>
                                        </div>
                                    </div>
                                    <div className="product__info">
                                        Bracelets/elegant/gold accssesories
                                    </div>
                                    <div className="products__price">
                                        Rs. 34,500/-
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="product__grids">
                                <div className="product__image">
                                    <div className="product__media">
                                        <Link to={'/frontend'} >
                                            <img className="img-fluid" src="/assets/images/filter/filter-image-6.jpeg" alt="" />
                                        </Link>
                                    </div>
                                    <div className="product__media_hover">
                                        <Link to={'/frontend'}>
                                            <img className="img-fluid" src="./assets/images/filter/filter-image-hover-2.jpeg" alt="" />
                                        </Link>
                                    </div>
                                <div className="product__action">
                                    <Link to={'/frontend'}>Add To Cart</Link>
                                </div>
                                </div>
                                <div className="products__content">
                                    <div className="title__holder">
                                        <div className="product__title">
                                            small bracelet
                                        </div>
                                        <div className="products__actions">
                                            <i className="bi bi-eye"></i>
                                            <i className="bi bi-heart"></i>
                                        </div>
                                    </div>
                                    <div className="product__info">
                                        Bracelets/elegant/gold accssesories
                                    </div>
                                    <div className="products__price">
                                        Rs. 34,500/-
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="product__grids">
                                <div className="product__image">
                                    <div className="product__media">
                                        <Link to={'/frontend'} >
                                            <img className="img-fluid" src="/assets/images/filter/filter-image-5.jpeg" alt="" />
                                        </Link>
                                    </div>
                                    <div className="product__media_hover">
                                        <Link to={'/frontend'}>
                                            <img className="img-fluid" src="./assets/images/filter/filter-image-hover-5.jpeg" alt="" />
                                        </Link>
                                    </div>
                                <div className="product__action">
                                    <Link to={'/frontend'}>Add To Cart</Link>
                                </div>
                                </div>
                                <div className="products__content">
                                    <div className="title__holder">
                                        <div className="product__title">
                                            small bracelet
                                        </div>
                                        <div className="products__actions">
                                            <i className="bi bi-eye"></i>
                                            <i className="bi bi-heart"></i>
                                        </div>
                                    </div>
                                    <div className="product__info">
                                        Bracelets/elegant/gold accssesories
                                    </div>
                                    <div className="products__price">
                                        Rs. 34,500/-
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="product__grids">
                                <div className="product__image">
                                    <div className="product__media">
                                        <Link to={'/frontend'} >
                                            <img className="img-fluid" src="/assets/images/filter/filter-image-4.jpeg" alt="" />
                                        </Link>
                                    </div>
                                    <div className="product__media_hover">
                                        <Link to={'/frontend'}>
                                            <img className="img-fluid" src="./assets/images/filter/filter-image-hover-4.jpeg" alt="" />
                                        </Link>
                                    </div>
                                <div className="product__action">
                                    <Link to={'/frontend'}>Add To Cart</Link>
                                </div>
                                </div>
                                <div className="products__content">
                                    <div className="title__holder">
                                        <div className="product__title">
                                            small bracelet
                                        </div>
                                        <div className="products__actions">
                                            <i className="bi bi-eye"></i>
                                            <i className="bi bi-heart"></i>
                                        </div>
                                    </div>
                                    <div className="product__info">
                                        Bracelets/elegant/gold accssesories
                                    </div>
                                    <div className="products__price">
                                        Rs. 34,500/-
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="product__grids">
                                <div className="product__image">
                                    <div className="product__media">
                                        <Link to={'/frontend'} >
                                            <img className="img-fluid" src="/assets/images/filter/filter-imge-1.jpeg" alt="" />
                                        </Link>
                                    </div>
                                    <div className="product__media_hover">
                                        <Link to={'/frontend'}>
                                            <img className="img-fluid" src="./assets/images/filter/filter-image-hover-1.jpeg" alt="" />
                                        </Link>
                                    </div>
                                <div className="product__action">
                                    <Link to={'/frontend'}>Add To Cart</Link>
                                </div>
                                </div>
                                <div className="products__content">
                                    <div className="title__holder">
                                        <div className="product__title">
                                            small bracelet
                                        </div>
                                        <div className="products__actions">
                                            <i className="bi bi-eye"></i>
                                            <i className="bi bi-heart"></i>
                                        </div>
                                    </div>
                                    <div className="product__info">
                                        Bracelets/elegant/gold accssesories
                                    </div>
                                    <div className="products__price">
                                        Rs. 34,500/-
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    )
}

export default FilterGallery