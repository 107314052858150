import React from 'react'
import { useEffect, useState } from 'react';
import productService from '../../../service/productService';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import axios from '../../../service/api';



const EditCategory = () => {
    const { slug } = useParams() as any;
    const naviagte = useNavigate()

    useEffect(() => {
        getcategories();
    }, []);
    const getcategories = async () => {
        try {
            
            // const response = await productService.getAllcategories();
            const response = await axios.get("localhost:5050/api/category/" + slug);
            // const catdata = response?.data?.data;
            console.log("getdata", response);
        } catch (error) {
            console.error(error);
        }
    }
    // update Category 
    const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            title: "",
            slug: "",
            description: "",
        },

        onSubmit: async (value, { resetForm }) => {
            try {
                let response = await productService.getUodateCategory(value)
                console.log("response", response);
                resetForm();
                toast.success(response?.data?.msg)
                naviagte("/admin/category")
                // navigate("/admin/products")


            } catch (error) {
                toast.error((error as any)?.response?.data?.msg ? (error as any)?.response?.data?.msg : 'error')
            }
        },

    });
    return (
        <form onSubmit={handleSubmit}>
            <div className="container-fluid">
                <div className="d-flex align-items-center justify-content-between pb-4">
                    <div className="product-title">
                        <h2 className='main-title'>Add New Category</h2>
                    </div>
                    {/* <div className="product-btn-area">
                    <button className='secondary-button ms-1'>Save Draft</button>
                    <button className='primary-button ms-1' type='submit'>Publish</button>
                </div> */}
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="card p-4">
                            <div className="card-top">
                                <h6>Media</h6>
                            </div>
                            <div className="card-body">
                                <div className="input-upload text-center">
                                    <img src="https://wp.alithemes.com/html/nest/backend/assets/imgs/theme/upload.svg" alt="" />
                                    <input className="form-control create-product" type="file" accept=".jpeg, .png, .jpg" name="image" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="card p-4">
                            <div className="card-top">
                                <h5 className='m-0'>Basic</h5>
                            </div>
                            <div className="card-body">
                                <div className="mb-4">
                                    <label htmlFor="title" className="form-label">Category Name</label>
                                    <input type="text" placeholder="Type Here" className="form-control create-product" name="title" value={values.title} onChange={handleChange} />
                                </div>
                                {/* <div className="mb-4">
                            <label className="form-label">Slug</label>
                            <input type="text" placeholder="Type Here" className="form-control create-product" id="slug" value={values.slug} onChange={handleChange} />
                        </div> */}
                                {/* <div className="mb-4">
                            <label className="form-label">Parent</label>
                            <select className="form-select" name="brand">
                                <option value="Ring">Ring</option>
                                <option value="brand2">Brand2</option>
                                <option value="brand3">Brand3</option>
                                <option value="brand4">Brand4</option>
                                <option value="brand5">Brand5</option>
                            </select>
                        </div> */}
                                <div className="mb-4">
                                    <label className="form-label">Full description</label>
                                    <textarea placeholder="Type here" className="form-control create-product" name='description' value={values.description} onChange={handleChange}></textarea>
                                </div>
                                <div className="product-btn-area">
                                    <button className='primary-button ms-1'>Create</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </form>
    )
}

export default EditCategory