import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import filterService from '../../../service/filterService';
interface filter {
    title: String,
}

const FilterByPrice = ({ title, }: filter) => {

    const [searchParams, setSearchParams] = useSearchParams();

    const [diamondList, setDiamondList] = useState<any>();
    const [stoneList, setStoneList] = useState<any>();
    const [brandList, setBrandList] = useState<any>();
    const [metalTypeList, setMetalTypeList] = useState<any>();

    const [selectedGenders, setSelectedGenders] = useState<string[]>([]);
    const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
    const [selectedDiamonds, setSelectedDiamonds] = useState<string[]>([]);
    const [selectedStones, setSelectedStones] = useState<string[]>([]);
    const [selectedMetals, setSelectedMetals] = useState<string[]>([]);


    const updateSearchParams = () => {
        // Create a copy of the current search parameters to modify
        const params = new URLSearchParams(searchParams);
        // Clear out any existing values for these keys to avoid duplicates
        params.delete('gender');
        params.delete('brand');
        params.delete('diamond');
        params.delete('stoneType');
        params.delete('metal');

        selectedGenders.forEach((gender) => params.append('gender', gender));
        selectedBrands.forEach((brand) => params.append('brand', brand));
        selectedDiamonds.forEach((diamond) => params.append('diamond', diamond));
        selectedStones.forEach((stoneType) => params.append('stoneType', stoneType));
        selectedMetals.forEach((metal) => params.append('metal', metal));


        setSearchParams(params);

    };





    useEffect(() => {
        const fetchFilters = async () => {
            const diamondRes = await filterService.GetAvlDiamondType();
            const stoneRes = await filterService.GetAvlStoneType();
            const brandRes = await filterService.GetAvlBrand();
            const metalRes = await filterService.GetAvlMetalType();

            setDiamondList(diamondRes?.data?.data || []);
            setStoneList(stoneRes?.data?.data || []);
            setBrandList(brandRes?.data?.data || []);
            setMetalTypeList(metalRes?.data?.data || []);
        };
        fetchFilters();
    }, []);

    const handleCheckboxChange = (
        value: string,
        setter: React.Dispatch<React.SetStateAction<string[]>>
    ) => {
        setter((prev) =>
            prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
        );
    };
    useEffect(() => {
        updateSearchParams();
    }, [selectedGenders, selectedBrands, selectedDiamonds, selectedStones, selectedMetals]);

    return (
        <>
            <div className="filter-wrap">
                <div className="title">
                    <span>{title}</span>
                    <a href="">Clear All</a>
                </div>
                <div className="filterRange-picker">
                    <h6>Price</h6>
                    <div className="price-input">
                        <div className="input-group wrapper-price">
                            <span className="input-group-text">$</span>
                            <input type="text" className="form-control" placeholder="1000" aria-label="1000" />
                        </div>
                        <div className="input-group wrapper-price">
                            <span className="input-group-text">$</span>
                            <input type="text" className="form-control" placeholder="1000" aria-label="1000" />
                        </div>
                    </div>
                    <div className="range-picker">
                        <input type="range" className="form-range" id="disabledRange" />
                    </div>
                </div>

                <div className="filter-gender">
                    <h6>Gender</h6>
                    <div className="gender-list">
                        {['male', 'female'].map((gender) => (
                            <div className="form-check" key={gender}>
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={gender}
                                    value={gender}
                                    checked={selectedGenders.includes(gender)}
                                    onChange={() => handleCheckboxChange(gender, setSelectedGenders)}
                                />
                                <label className="form-check-label" htmlFor={gender}>
                                    {gender.charAt(0).toUpperCase() + gender.slice(1)}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="filter-gender">
                    <h6>Metal Purity</h6>
                    <div className="gender-list">
                        {metalTypeList?.map((metal: string, index: number) => (
                            <div className="form-check" key={index}>
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={metal}
                                    value={metal}
                                    checked={selectedMetals.includes(metal)}
                                    onChange={() => handleCheckboxChange(metal, setSelectedMetals)}
                                />
                                <label className="form-check-label" htmlFor={metal}>
                                    {metal}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="filter-gender">
                    <h6>Brand</h6>
                    <div className="gender-list">
                        {brandList?.map((brand: any, index: number) => (
                            <div className="form-check" key={index}>
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={brand._id}
                                    value={brand._id}
                                    checked={selectedBrands.includes(brand._id)}
                                    onChange={() => handleCheckboxChange(brand._id, setSelectedBrands)}
                                />
                                <label className="form-check-label" htmlFor={brand.title}>
                                    {brand.title}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="filter-gender">
                    <h6>Diamond Type</h6>
                    <div className="gender-list">
                        {diamondList?.map((diamond: any, index: number) => (
                            <div className="form-check" key={index}>
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={diamond._id}
                                    value={diamond._id}
                                    checked={selectedDiamonds.includes(diamond._id)}
                                    onChange={() => handleCheckboxChange(diamond._id, setSelectedDiamonds)}
                                />
                                <label className="form-check-label" htmlFor={diamond.name}>
                                    {diamond.name}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="filter-gender">
                    <h6>Stone</h6>
                    <div className="gender-list">
                        {stoneList?.map((stone: string, index: number) => (
                            <div className="form-check" key={index}>
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={stone}
                                    value={stone}
                                    checked={selectedStones.includes(stone)}
                                    onChange={() => handleCheckboxChange(stone, setSelectedStones)}
                                />
                                <label className="form-check-label" htmlFor={stone}>
                                    {stone}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default FilterByPrice
