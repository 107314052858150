import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ProductSummary from '../../components/Frontend/ProductSummary'
import addressService from '../../service/addressService'
import { useDispatch, useSelector } from 'react-redux'
import orderService from '../../service/orderService'
import useRazorpay from "react-razorpay";
import  {deleteCartItemAfterPayment}  from '../../slices/CartSlice';
const key = "rzp_test_fz1dHFTpYmRLpE";

const Checkout = () => {
    const [address, setAddress] = useState<any>([])
    const [selectedAddress, setSelectedAddress] = useState<any>({})
    const [isLoading, setisLoading] = useState<boolean>(false)

    const cart = useSelector((state: any) => state.cart);
    const user = useSelector((state: any) => state.user.userdata);

    const [Razorpay] = useRazorpay();
    const navigate = useNavigate()
    const dispatch = useDispatch()
  

    const getAllAddress = async () => {
        let result = await addressService.getAddressById(user?._id)
        setAddress(result?.data?.data);
    }

    useEffect(() => {
        getAllAddress()
    }, [])

    const selecteAddress = (address: any) => {
        setSelectedAddress(address)

    }

    const order = async () => {
        setisLoading(true)
        const productOrderObject = {
            customer_name: user?.email,
            customer_phone: user?.phone,
            customer_email: user?.email,
            user_id:user?._id,
            address: {
                name: selectedAddress?.name,
                dist: selectedAddress?.dist,
                street_name: selectedAddress?.street_name,
                locality: selectedAddress?.locality,
                pincode: selectedAddress?.pincode,
                city: selectedAddress?.city,
            },
            product: cart?.cartItems,
            price: cart?.cartTotalAmount
        }
        const { data }: any = await orderService.createOrders(productOrderObject)
        setisLoading(false)
        const options = {
            key: key,
            amount: data?.data?.payment_amount,
            currency: data?.data?.currency,
            name: 'Your Product',
            description: 'Test Transaction',
            order_id: data?.data?.razorPay_orderId,
            handler: async function (response: any) {
                const res = await orderService.validatePayment(response)
                await orderService.updateOrders(data?.data?._id, { payment_status: res.data.msg })
                dispatch(deleteCartItemAfterPayment())
                navigate('/account/order')
            },
            prefill: {
                name: selectedAddress?.name,
                email: user?.email,
                contact: user?.phone
            },
            notes: {
                address: selectedAddress?.street_name + selectedAddress?.locality + selectedAddress?.pincode
            },
        };
 
        var razor = new Razorpay(options)
        razor.open()

    }
    return (
        <>
            <div className="checkout-wrap">
                <div className="container">
                    <div className="steps-wrap">
                        <ul className="step-tabs">
                            <li className="step-tab">
                                <Link to="/cart" className="step-tab-link">
                                    <span className="step-tab-text">
                                        SHOPPING CART
                                        <span className="bg-text">01</span>
                                    </span>
                                </Link>
                            </li>
                            <li className="step-tab active">
                                <span className="step-tab-text">
                                    CHECKOUT
                                    <span className="bg-text">02</span>
                                </span>
                            </li>
                            <li className="step-tab">
                                <span className="step-tab-text">
                                    ORDER COMPLETE
                                    <span className="bg-text">03</span></span>
                            </li>
                        </ul>
                    </div>
                    <div className="checkout">

                        <div className="checkout-left">
                            <div className="billing-details">
                                <h4>Billing Details</h4>
                            </div>
                            <div className="saved-address">
                                {
                                    address ? address.map((i: any) =>
                                        <React.Fragment key={i._id}>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onClick={() => selecteAddress(i)} />
                                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                    <span>{i?.name}</span>
                                                    <span>{i?.street_name}</span>
                                                    <span>{i?.locality}</span>
                                                    <span>{i?.city}, {i?.dist} {i?.pincode}</span>
                                                    <span>India</span>
                                                </label>
                                            </div>
                                        </React.Fragment>
                                    ) : ""
                                }

                            </div>
                            <div className="add-new-address-wrap">
                                <button type="button" className="btn btn-add-new-address" onClick={() => navigate('/account/address')}>
                                    + ADD NEW ADDRESS
                                </button>
                            </div>

                            <div className="shipping-details">
                                <div className="form-group ship-to-different-address-label">
                                    <div className="form-check">
                                        <input type="checkbox" name="ship_to_a_different_address" id="ship-to-different-address" />
                                        <label htmlFor="ship-to-different-address" className="form-check-label">
                                            Ship to a different address
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group order-note-wrap">
                                <label htmlFor="order-note">
                                    Order Note
                                </label>
                                <textarea name="order_note" id="order-note" placeholder="Special note for delivery" className="form-control"></textarea>
                            </div>
                        </div>
                        <ProductSummary onClick={() => {
                            order()
                        }} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Checkout
