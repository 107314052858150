import React, { useEffect, useState } from 'react'
import attributeService from "../../service/attributeService"
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';

interface Row {
    name: string;
    attributeSetId: {
        name: string;
    };
    _id:String;
    createdAt: string;
}

const AttributeList = () => {
    const [attribute, setAttribute] = useState<any>([])
    useEffect(() => {
        atrributeList()
    }, [])
    const atrributeList = async () => {
        try {
            const response = await attributeService.GetAttribute()
            setAttribute(response?.data?.data)
        }
        catch {

        }
    }
    const columns = [
        {
            name: 'name',
            selector: (row: any) => row.name,
            sortable: true,
            cell: (row: Row) => (
                <Link
                    to={`/admin/attributes/edit/`+row._id}
                >
                    <span>{row.name}</span>
                </Link>
            )
        },
        {
            name: 'Atrribute Set',
            selector: (row: any) => row.attributeSetId.name,
        },
        {
            name: 'Created',
            selector: (row: any) => row.createdAt,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row: any) => <><button className='btn btn-success btn_edit'><i className="bi bi-pencil-square"></i></button>
                <button className='btn_edit btn btn-danger'><i className="bi bi-trash"></i></button>
            </>,
        },
    ];

    return (
        <>
            <div className="card p-4">
                <DataTable
                    columns={columns}
                    data={attribute}
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight='600px'
                    selectableRows
                    selectableRowsHighlight
                    highlightOnHover
                    subHeader
                    defaultSortAsc={true}
                    subHeaderComponent={
                        <input className='searchbar' type='text' placeholder='Search Here' />
                    }
                />
            </div>
        </>
    )
}

export default AttributeList
