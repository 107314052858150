import React, { useEffect, useMemo, useState } from 'react'
import ReactImageMagnify from 'react-image-magnify';
import SectionTitle from '../../components/common/SectionTitle';
import Accordion from 'react-bootstrap/Accordion';
import productService from '../../service/productService';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../slices/CartSlice';
import { getAmount, getOfferAmount, discountType } from "../../utils/utils"
import NavbarOtherPage from '../../layouts/Frontend/Header/NavbarOtherPage';
import DataTable from 'react-data-table-component';
import wishListService from '../../service/wishlistService';
import priceSettingService from '../../service/priceSettingService';


const SingleProduct = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const [mainImage, setmainImage] = useState<any>('')
    const [allimage, setallimage] = useState<any>([])

    const [selectedVarient, setselectedVarient] = useState<any>(undefined)
    const [selectedCustomization, setselectedCustomization] = useState<any>({})
    const user = useSelector((state: any) => state.user.userdata);

    const [isLoading, setisLoading] = useState<boolean>(false)
    const [product, setProduct] = useState<any>(undefined)
    const [wishlist, setWishlist] = useState<any>(undefined)

    const { currency, symbol } = useSelector((item: any) => item.currency)

    const [diamondTypeList, setDiamondTypeList] = useState<any>([])

    const [selecteDiamond, setselecteDiamond] = useState<any>('')

    // get specific data
    useEffect(() => {
        getOneProduct();
    }, []);


    const getOneProduct = async () => {
        try {
            setisLoading(true)
            const response = await productService.getOneProduct(id, '?user_id=' + user?._id)
            const data = response?.data?.data?.product;
            setProduct(data)
            setWishlist(response?.data?.data?.wishlist)
            setisLoading(false)
            setallimage([data?.feature_image, ...data.addtionalImage])
            setmainImage(data?.feature_image)

            setselectedVarient({ diamond: data?.diamond?.[0], metal: data?.metal?.[0] })
            setselecteDiamond(data?.diamond?.[0]?.diamondType?._id)
            console.log('diamond', data);
            

        } catch (error) {
            setisLoading(false)
            console.error(error);
        }
    }

    useEffect(() => {
        if (product) {
            //Set Product atribute
            if (product?.attributes) {
                const defaultAttr: any = {}
                for (const i of product?.attributes) {
                    defaultAttr[i.attributeName] = i?.values?.[0]
                }
                setselectedCustomization(defaultAttr)
            }
        }
    }, [product])


    const getStonePrice = useMemo(() => {
        let total = 0
        if (product?.stone) {
            for (let stone of product?.stone) {
                total += stone.stonePrice
            }
        }
        return total;
    }, [product?.stone])


    const cartClick = () => {
        dispatch(addToCart({ ...product, ...selectedVarient, selectedCustomization, cartTotalAmount: Number(getStonePrice) + Number(selectedVarient?.metal?.price) + Number(selectedVarient?.diamond?.diamondPrice) }))
    }

    const diamondColumns = [
        {
            name: 'Type',
            selector: (row: any) => <h6 className='m-0 product-name'>{row.diamondType?.name}</h6>,

        },
        {
            name: 'Qty',
            cell: (row: any) => row.diamondQty
        },
        {
            name: 'Kt',
            selector: (row: any) => row.diamondCarat,

        },
        {
            name: 'Shape',
            selector: (row: any) => row.diamondShape,

        }
    ];
    const stoneColumns = [
        {
            name: 'Type',
            selector: (row: any) => <h6 className='m-0 product-name'>{row.stoneType}</h6>,

        },
        {
            name: 'Qty',
            cell: (row: any) => row.stoneQty
        },
        {
            name: 'Kt',
            selector: (row: any) => row.stoneCarat,

        },
        {
            name: 'Shape',
            selector: (row: any) => row.stoneShape,

        },
        {
            name: 'Color',
            selector: (row: any) => row.stoneColor,

        }
    ];

    const handleChange = (e: any, item: any) => {
        setselectedCustomization((prev: any) => {
            const newData = { ...prev }
            newData[item.attributeName] = e?.target?.value
            return newData
        })

    }

    const handleWishlist = async () => {
        if (!wishlist) {
            let payload = {
                products_Id: product._id,
                slug: product.slug,
                user_id: user._id
            }
            const data = await wishListService.creatWishList(payload)
            console.log(data?.data?.data);
            setWishlist(data?.data?.data)
            return
        }
        let data = await wishListService.deleteWishList(wishlist._id)
        if (data?.data?.deleted) {
            setWishlist(undefined)
        }

    }

    const GetDiamondPrice = async () => {
        try {
            const response = await priceSettingService.GetAllDiamondPrice()
            setDiamondTypeList(response.data.data)
        } catch (error) {
            console.log(error);
        }
    }

    const changeProduct = (e: any) => {
        const selectedDiamond = diamondTypeList.filter((i: any) => i?._id === e)
        setProduct((oldProduct: any) => {
            const newDiamondArray = []
            const newProduct = { ...oldProduct, }
            if (oldProduct?.diamond) {
                for (const iterator of oldProduct?.diamond) {
                    newDiamondArray.push({
                        ...iterator,
                        diamondType: selectedDiamond?.[0]
                    })
                }
            }
            setselectedVarient({ diamond: newDiamondArray?.[0], metal: product?.metal?.[0] })
            return { ...newProduct, diamond: newDiamondArray }
        })
    }

    const sumOf = useMemo(() => {
        let total = {
            qty: 0,
            kt: 0,
        }
        for (let i in product?.diamond) {
            total.qty += Number(product?.diamond[i].diamondQty)
            total.kt += Number(product?.diamond[i].diamondCarat)
        }

        return total
    }, [product?.diamond])
    const sumOfStone = useMemo(() => {
        let total = {
            qty: 0,
            kt: 0,
        }
        for (let i in product?.diamond) {
            total.qty += Number(product?.stone[i]?.stoneQty)
            total.kt += Number(product?.stone[i]?.stoneCarat)
        }

        return total
    }, [product?.diamond])

    useEffect(() => {
        GetDiamondPrice()
    }, [])

    const totalPrice = useMemo(() => {
        return (Number(getStonePrice) + Number(selectedVarient?.metal?.price) + (Number((selectedVarient?.diamond?.diamondType?.price)) * sumOf?.kt))
    }, [getStonePrice, selectedVarient?.diamond?.diamondType?.price, selectedVarient?.metal?.price, sumOf?.kt])

    console.log('setselectedVarient', selectedVarient);

    return (
        isLoading ?
            <div className="loader-wrapper">
                <span className="loader"></span>
            </div> : <>
                <NavbarOtherPage />
                <section className="product-wrap">
                    <div className="arsa-container-fluid">
                        <div className="row">
                            <div className="col col-md-4">
                                <div className="single-product-image">
                                    <div className="base-image">
                                        <ReactImageMagnify {...{
                                            smallImage: {
                                                isFluidWidth: true,
                                                src: mainImage,
                                                // sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px'x`
                                            },
                                            largeImage: {
                                                src: mainImage,
                                                width: 1200,
                                                height: 1200
                                            },
                                            enlargedImageContainerDimensions: {
                                                width: '120%',
                                                height: '100%'
                                            },
                                            shouldUsePositiveSpaceLens: true,
                                            enlargedImageContainerClassName: "enlargedImage",
                                        }} />

                                    </div>
                                    <div className="image-gallery-wrap">
                                        {allimage?.map((i: string, index: number) => <div key={index} className="addtional-image">

                                            <img src={i} alt="" onClick={() => setmainImage(i)} />

                                        </div>)}
                                    </div>
                                </div>
                                <div className="certification">
                                    <span>Certified By</span>
                                    <img src="/assets/images/certification.png" alt="" />
                                </div>
                            </div>
                            <div className="col col-md-8">
                                <div className="product-details">
                                    <div className="product-title">
                                        <h2>{product?.title}</h2>
                                        <span>Style No : <strong>{product?.styleNo}</strong></span><span>
                                            <a href="http://127.0.0.1:8000/products/praveen#details"> View Details</a>
                                        </span>
                                        <br />
                                        <button onClick={() => handleWishlist()} className="btn" ><i className={`bi ${wishlist ? 'bi-heart-fill' : 'bi-heart'}`}></i>
                                            Wishlist
                                        </button>
                                        <button className="btn " ><i className="bi bi-shuffle"></i>
                                            Compare
                                        </button>
                                        <p>In Stock</p>
                                    </div>

                                    {
                                        product?.offerId ?
                                            <>
                                                <div className="price-section"><del>{symbol}{getAmount(currency, selectedVarient?.price)}</del> <strong>{discountType(product.offerId)}</strong> </div>
                                                <div className="price-section"><span>{symbol}{getOfferAmount(currency, selectedVarient?.price, product.offerId)}</span></div>
                                            </>
                                            :
                                            <div className="price-section"><span>{symbol}{
                                                getAmount(currency, totalPrice)
                                            }</span></div>

                                    }
                                    <span className='tax'>MRP Incl. of all taxes</span>


                                    <div className="arsapromise">
                                        <ul>
                                            <li className="border-right">
                                                <div className="money-back">
                                                    <img src="/assets/images/return.png" alt="" />
                                                </div>
                                                <span> 30 -Day Money Back</span>
                                            </li>
                                            <li className="border-right">
                                                <div className="exchange">
                                                    <img src="/assets/images/certified.png" alt="" />
                                                </div>
                                                <span>Lifetime Exchange &amp; Buy-Back</span>
                                            </li>
                                            <li>
                                                <div className="certified">
                                                    <img src="/assets/images/batch.png" alt="" />
                                                </div>
                                                <span>Certified Jewellery</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="add-product-cart">
                                        <button type="button" onClick={() => cartClick()} className="btn arsa-btn custom-btn-dark"><i className="bi bi-cart3"></i>
                                            ADD TO CART
                                        </button>
                                    </div>
                                    {<div className="product-customization">
                                        <p>Customization</p>
                                        {product?.attributes?.map((item: any, index: any) =>

                                            <div className="customization-details" key={item._id + index}>
                                                <div className="customization-title"><span>{item.attributeName}</span></div>
                                                <div className="customization-option">
                                                    <div className="btn-group customization-btn" role="group" aria-label="Basic radio toggle button group">

                                                        {
                                                            item?.values?.map((i: string, index2: number) => {
                                                                return <React.Fragment key={index2}>
                                                                    <input
                                                                        checked={selectedCustomization?.[item?.attributeName] === i}
                                                                        type="radio"
                                                                        className="btn-check"
                                                                        id={i}
                                                                        name={item?.attributeName}
                                                                        value={i}
                                                                        onChange={(e) => handleChange(e, item)}
                                                                    />
                                                                    <label className="btn btn-outline-primary" htmlFor={i}>{i}</label>
                                                                </React.Fragment>
                                                            }
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <p>Metal Options</p>
                                        <div className="metalCaratAvailibility mb-4">
                                            <div className="btn-group customization-btn" role="group" aria-label="Basic radio toggle button group">

                                                {
                                                    product?.metal?.map((item: any, index: number) =>
                                                        <React.Fragment key={index}>
                                                            <input
                                                                type="radio"
                                                                onChange={() => setselectedVarient((prev: any) => ({ ...prev, metal: item }))}
                                                                className="btn-check" name={item._id} id={item.name}
                                                                checked={item._id === selectedVarient?.metal?._id}
                                                            />
                                                            <label className="btn btn-outline-primary" htmlFor={item.name}>{item.name}</label>
                                                        </React.Fragment>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <p>Diamond Options</p>
                                        <div className="metalCaratAvailibility">
                                            <div className="btn-group customization-btn" role="group" aria-label="Basic radio toggle button group">

                                                {/* {
                                                    product?.diamond?.map((item: any, index: number) =>
                                                        <React.Fragment key={index}>
                                                            <input
                                                                type="radio"
                                                                onChange={() => setselectedVarient((prev: any) => ({ ...prev, diamond: item }))}
                                                                className="btn-check" name={item._id} id={item?.diamondType?.name}
                                                                checked={item._id === selectedVarient?.diamond?._id}
                                                            />
                                                            <label className="btn btn-outline-primary" htmlFor={item?.diamondType?.name}>{item?.diamondType?.name}</label>
                                                        </React.Fragment>
                                                    )
                                                } */}
                                                <select value={selecteDiamond} className="form-select" name="diamondType" onChange={(e) => { setselecteDiamond(e?.target?.value); changeProduct(e?.target?.value) }}>
                                                    <option value="">No Diamond</option>
                                                    {
                                                        diamondTypeList ? diamondTypeList.map((i: any, index: any) =>
                                                            <option key={index} value={i._id} defaultValue={product?.diamond[0].diamondType.name}
                                                        >{i.name}</option>
                                                        ) : null
                                                    } 
                                                </select>
                                                
                                            </div> 
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <SectionTitle title="Product Information" />
                            </div>
                            <div className="col-md-3">
                                <div className="product-summary-wrapper"><div className="product-summry"><p>Product Summary</p> <div className="summary-list"><span>Style No.</span> <span>{product?.styleNo}</span></div> <div className="summary-list"><span>Width</span> <span>2.085 cm (20.85 mm)</span></div>
                                    <div className="summary-list"><span>Gross Weight</span> <span>{selectedVarient?.totalWight}</span></div>
                                    <hr />
                                    <div className="summary-list"><span>Metal Price</span> <span>{symbol}{getAmount(currency, selectedVarient?.metal?.price)}</span></div>

                                    <div className="summary-list"><span>Diamond Price</span> <span>{symbol}{getAmount(currency, Number((selectedVarient?.diamond?.diamondType?.price)) * sumOf?.kt)}</span></div>
                                    <div className="summary-list"><span>Stone Price</span> <span>{symbol}{getAmount(currency, Number(getStonePrice))}</span></div>


                                    <hr />
                                    <div className="summary-list"><span>Total Price </span> <span>{symbol}{getAmount(currency, totalPrice)}</span></div>
                                </div>
                                    <div className="notice-area"><i className="bi bi-info-circle-fill"></i> <p>Difference in gold weight may occur &amp; will apply on final price.</p></div></div>
                            </div>
                            <div className="col">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Metal Details</Accordion.Header>
                                        <Accordion.Body>
                                        <div className="card-body">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <td>Metal Name</td>
                                                        <td>{selectedVarient?.metal?.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Purity</td>
                                                        <td>{selectedVarient?.metal?.metalWeight}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Metal Weight</td>
                                                        <td>{selectedVarient?.metal?.metalWeight}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header> Diamond Details </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="card-body">
                                                <DataTable
                                                    columns={diamondColumns}
                                                    data={product?.diamond}
                                                    fixedHeader
                                                    fixedHeaderScrollHeight='600px'
                                                    defaultSortAsc={true}
                                                />
                                                <table className="table">
                                                    <tbody className='total-product-details-table'>
                                                        <tr>
                                                            <th>Total</th>
                                                            <th>{sumOf?.qty}</th>
                                                            <th>{sumOf?.kt.toFixed(1)}</th>
                                                            <th>00</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header> Gem Stone </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="card-body">
                                                <DataTable
                                                    columns={stoneColumns}
                                                    data={product?.stone}
                                                    fixedHeader
                                                    fixedHeaderScrollHeight='600px'
                                                    defaultSortAsc={true}
                                                />
                                                 <table className="table">
                                                    <tbody className='total-product-details-table'>
                                                        <tr>
                                                            <th>Total</th>
                                                            <th>{sumOfStone?.qty}</th>
                                                            <th>{sumOfStone?.kt.toFixed(1)}</th>
                                                            <th>00</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </section>
            </>
    )
}

export default SingleProduct
