import React from 'react'
import Newsletter from '../Newsletter'
import MainFooter from "../MainFooter";
import CopyRight from "../CopyRight";

const Footer = () => {
    return (
        <>
            <footer className='front-footer-wrap'>
                <Newsletter />
                <MainFooter />
                <CopyRight />
            </footer>
        </>
    )
}

export default Footer
