import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux"

export default function LoginLayout() {
    const location = useLocation();
    const token = useSelector((state: any) => state.user.token)

    const userdata = useSelector((state: any) => state.user.userdata)
    return (
        !token ?
            <div>
                <Outlet />
            </div>
            :
            userdata?.is_admin ? <Navigate to="/admin" state={{ from: location }} replace /> : <Navigate to="/" state={{ from: location }} replace />
    );
}