import { useNavigate } from 'react-router-dom'
import ProductsCompo from '../../../components/common/Products'
const Products = () => {
    const navigate = useNavigate()
    const OnAddproduct = () => {
        navigate("/admin/products/add")
    }
    return (
        <>
            <div className="container-fluid">
                <div className="d-flex align-items-center justify-content-between pb-4">
                    <div className="product-title">
                        <h2 className='main-title'>Products List</h2>
                        {/* <p className='m-0'>Lorem ipsum dolor sit amet.</p> */}
                    </div>
                    <div className="product-btn-area">
                        <button onClick={OnAddproduct} className='primary-button ms-1'>Create New</button>
                    </div>
                </div>
                <ProductsCompo />
            </div>
        </>
    )
}

export default Products