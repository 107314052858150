import axios from "./api"

const GetAllOffers = () => {
    return axios.get('/offers')
}
const CreateOffer = (payload) => {
    return axios.post('/offers', payload)
}

const offerService = {
    GetAllOffers,
    CreateOffer
}

export default offerService;