import React from 'react'
import HeaderLayout2 from './Frontend/Header/HeaderMain'
import FooterBottom from './MainFooter'
import CopyRight from './CopyRight'
import { Link } from 'react-router-dom'

const ErrorPage = () => {
  return (
    <>
        {/* <HeaderLayout2/> */}
        <div className="center">
  <div className="error">
    <div className="number">4</div>
    <div className="illustration">
      <div className="circle"></div>
      <div className="clip">
        <div className="paper">
          <div className="face">
            <div className="eyes">
              <div className="eye eye-left"></div>
              <div className="eye eye-right"></div>
            </div>
            <div className="rosyCheeks rosyCheeks-left"></div>
            <div className="rosyCheeks rosyCheeks-right"></div>
            <div className="mouth"></div>
          </div>
        </div>
      </div>
    </div>
    <div className="number">4</div>
  </div>

  <div className="text">Oops. The page you're looking for doesn't exist.</div>
  <Link to="/frontend" className='button'>Go Home</Link>
</div>
        <FooterBottom/>
        <CopyRight/>
    </>
  )
}

export default ErrorPage