import React, { useEffect, useState, ReactFragment } from 'react'
import ReactImageMagnify from 'react-image-magnify';
import SectionTitle from '../components/common/SectionTitle';
import Accordion from 'react-bootstrap/Accordion';
import NavbarOtherPage from './Frontend/Header/NavbarOtherPage';
import FooterBottom from './MainFooter';
import CopyRight from './CopyRight';
import Footer from './Newsletter';

const SinglePageProduct = () => {
    return (
        <>
            <NavbarOtherPage />
            <section className="product-wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="single-product-image">
                                <div className="base-image">
                                    <ReactImageMagnify {...{
                                        smallImage: {
                                            isFluidWidth: true,
                                            src: "./assets/images/ring1.webp",
                                            // sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px'x`
                                        },
                                        largeImage: {
                                            src: "./assets/images/ring1.webp",
                                            width: 1200,
                                            height: 1200
                                        },
                                        enlargedImageContainerDimensions: {
                                            width: '120%',
                                            height: '100%'
                                        },
                                        shouldUsePositiveSpaceLens: true,
                                        enlargedImageContainerClassName: "enlargedImage",
                                    }} />

                                </div>
                                <div className="image-gallery-wrap">

                                    <div className="addtional-image">
                                        <img src="./assets/images/ring1.webp" alt="" />
                                    </div>
                                    <div className="addtional-image">
                                        <img src="./assets/images/ring2.webp" alt="" />
                                    </div>
                                    <div className="addtional-image">
                                        <img src="./assets/images/ring3.webp" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="certification">
                                <span>Certified By</span>
                                <img src="/assets/images/certification.png" alt="" />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="product-details">
                                <div className="product-title">
                                    <h2></h2>
                                    <span>Style No : <strong></strong></span><span>
                                        <a href="http://127.0.0.1:8000/products/praveen#details"> View Details</a>
                                    </span>
                                    <br />
                                    <button className="btn" ><i className="bi bi-heart"></i>
                                        Wishlist
                                    </button>
                                    <button className="btn " ><i className="bi bi-shuffle"></i>
                                        Compare
                                    </button>
                                    <p>In Stock</p>
                                </div>



                                <div className="price-section"><del></del> <strong></strong> </div>
                                <div className="price-section"><span></span></div>


                                <div className="price-section"><span></span></div>

                                <span className='tax'>MRP Incl. of all taxes</span>
                                <div className="toggle-button-cover">
                                    <div className="button-cover">
                                        <div id="button-10" className="button b2">
                                            <input type="checkbox" className="checkbox" />
                                            <div className="knobs"><i className="bi bi-currency-rupee"></i></div>
                                            <div className="layer"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="arsapromise">
                                    <ul>
                                        <li className="border-right">
                                            <div className="money-back">
                                                <img src="/assets/images/return.png" alt="" />
                                            </div>
                                            <span> 30 -Day Money Back</span>
                                        </li>
                                        <li className="border-right">
                                            <div className="exchange">
                                                <img src="/assets/images/certified.png" alt="" />
                                            </div>
                                            <span>Lifetime Exchange &amp; Buy-Back</span>
                                        </li>
                                        <li>
                                            <div className="certified">
                                                <img src="/assets/images/batch.png" alt="" />
                                            </div>
                                            <span>Certified Jewellery</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="add-product-cart">
                                    {/* <div className="number-picker">
                                        <label htmlFor="qty">Quantity</label>
                                        <div className="input-group-quantity"><input type="text" min="1" max="" id="qty" className="form-control input-number input-quantity" /> <span className="btn-wrapper"><button type="button" data-type="plus" className="btn btn-number btn-plus"> + </button> <button type="button" data-type="minus" className="btn btn-number btn-minus"> - </button></span></div>
                                    </div> */}

                                    <button type="button" className="btn arsa-btn custom-btn-dark"><i className="bi bi-cart3"></i>
                                        ADD TO CART
                                    </button>
                                </div>


                                <div className="product-customization">
                                    <p>Customization</p>

                                    <div className="customization-details">
                                        <div className="customization-title"><span></span></div>
                                        <div className="customization-option">
                                            <div className="btn-group customization-btn" role="group" aria-label="Basic radio toggle button group">


                                                <input type="radio" className="btn-check btnradio" checked />
                                                <label className="btn btn-outline-primary"></label>

                                            </div>
                                        </div>
                                    </div>


                                    <p>Available Options</p>


                                    <div className="metalCaratAvailibility">
                                        <div className="btn-group customization-btn" role="group" aria-label="Basic radio toggle button group">


                                            <input type="radio" className="btn-check" name="varientbtnradio" />
                                            <label className="btn btn-outline-primary"></label>

                                        </div>
                                    </div>

                                </div>


                            </div>



                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <SectionTitle title="Product Information" />
                        </div>
                        <div className="col-md-3">
                            <div className="product-summary-wrapper"><div className="product-summry"><p>Product Summary</p> <div className="summary-list"><span>Style No.</span> <span></span></div> <div className="summary-list"><span>Width</span> <span>2.085 cm (20.85 mm)</span></div>
                                <div className="summary-list"><span>Gross Weight</span> <span></span></div>
                                <hr />
                                <div className="summary-list"><span>Stone Price</span> <span></span></div>
                                <div className="summary-list"><span>Diamond Price</span> <span></span></div>

                                <hr />
                                <div className="summary-list"><span>Total Price </span> <span></span></div>

                            </div>

                                <div className="notice-area"><i className="bi bi-info-circle-fill"></i> <p>Difference in gold weight may occur &amp; will apply on final price.</p></div></div>
                        </div>

                        <div className="col">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Metal Details</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="card-body">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <td>Metal Name</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Purity</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Metal Weight</td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header> Diamond Details </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="card-body">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <td>Total Weight</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Clarity</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Color</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total No. of Diamonds</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Shape</td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header> Gem Stone </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="card-body">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <td>Total Weight</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Clarity</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Color</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total No. of Diamonds</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Shape</td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>

                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
            <FooterBottom />
            <CopyRight />
        </>
    )
}

export default SinglePageProduct