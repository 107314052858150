
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import MetalTab from './priceSettingTabs/metalTab';
import DiamondTab from './priceSettingTabs/DiamondTab';
import StoneTab from './priceSettingTabs/StoneTab';


const PriceSetting = () => {

    return (
        <>

            <div className="container-fluid">
                <div className="d-flex align-items-center justify-content-between pb-4">
                    <div className="product-title">
                        <h2 className='main-title'>Price Setting</h2>
                    </div>
                </div>


                <div className="card p-4">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="metal">
                        <div className="row">
                            <div className="col-md-3">
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="metal">Metal</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="diamond">Diamond</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className="col-md-9">
                                <Tab.Content>
                                    <Tab.Pane eventKey="metal">
                                        <MetalTab />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="diamond">
                                        <DiamondTab />
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container>
                </div>
            </div>
        </>
    )
}

export default PriceSetting