import React, { useEffect, useState } from 'react'
import atrributeSetService from "../../../service/attributeService"
import productService from "../../../service/productService"
import { useFormik } from 'formik';
import toast from 'react-hot-toast';

const AddAtrribute = () => {

  const [attributeset, setAttributeSet] = useState<any>([])
  const [categories, setCategories] = useState<any>([])

  const [attrList, setAttrList] = useState<any>([''])

  const { values, handleChange, handleSubmit, setFieldValue } = useFormik({

    initialValues: {
      name: "",
      attributeSetId: "",
      categoryId: "",
      values: []
    },
    onSubmit: async (value) => {
      try {
        let response = await atrributeSetService.CreateAttribute(value)
        toast.success(response?.data?.msg)

      } catch (error) {
        toast.error((error as any)?.response?.data?.msg ? (error as any)?.response?.data?.msg : 'error')
      }
    },




  });

  const getAttributeSet = async () => {
    try {
      const response = await atrributeSetService.GetAttributeSet()
      setAttributeSet(response?.data?.data)

    } catch (err) {
      console.log(err);

    }
  }

  const getCategory = async () => {
    const response = await productService.getAllcategories()
    setCategories(response?.data?.data)
  }

  useEffect(() => {
    getAttributeSet()
    getCategory()
  }, [])

  const handleAddValue = () => {
    setAttrList([...attrList, attrList])

  }

  const handleRemove = (index: any) => {
    let list = [...attrList]
    list.splice(index, 1)
    setAttrList(list)

  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between pb-4">
            <div className="product-title">
              <h2 className='main-title'>Add New Attribute</h2>
            </div>
            <div className="product-btn-area">
              <button className='primary-button ms-1' type='submit'>Publish</button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="card p-4">
                <div className="card-top">
                  <h5 className='m-0'>Basic</h5>
                </div>
                <div className="card-body">
                  <div className="mb-4">
                    <label htmlFor="name" className="form-label">Atrribute Name</label>
                    <input type="text" placeholder="Type Here" className="form-control create-product" name="name" value={values.name} onChange={handleChange} />
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Attribute Set</label>
                    <select className="form-select" name="attributeSetId" value={values.attributeSetId} onChange={handleChange}>
                      {
                        attributeset ? attributeset.map((el: any) => (
                          <option value={el._id}>{el.name}</option>
                        )) : ""
                      }
                    </select>
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Category</label>
                    <select className="form-select" name="categoryId" value={values.categoryId} onChange={handleChange}>
                      {
                        categories ? categories.map((el: any) => (
                          <option value={el._id}>{el.title}</option>
                        )) : ""
                      }
                    </select>
                  </div>
                  <div className="mb-4">
                    <div className="value-title">
                      <label className="form-label">Values</label>
                      <button className='secondary-button' type="button" onClick={() => handleAddValue()}>Add Value</button>

                    </div>
                    {attrList.map((i: any, index: any) => (
                      <div className="value-sec" key={index}>
                        <input type="text" className="form-control" name={`values.${index}`} onChange={handleChange} />
                        <div className="delete-icon" onClick={() => handleRemove(index)}>
                          <i className="bi bi-trash"></i>
                        </div>
                      </div>
                    ))}
                    {/* <AttributeValue /> */}
                  </div>



                  <button  className='primary-button ms-1' type='submit'>Create</button>
                </div>
              </div>


            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default AddAtrribute