import React, { useEffect, useState, useCallback } from 'react'
import { useFormik } from 'formik';
import productService from "../../../service/productService"
import brandService from '../../../service/brandService';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { Multiselect } from 'multiselect-react-dropdown';
import { useDispatch, useSelector } from 'react-redux'
import { getDiamondTypeList, getMetalTypeList, getStoneTypeList } from '../../../slices/DropdownSlice';
import priceSettingService from '../../../service/priceSettingService';
import ProductVarient from './ProductVarient';
import DiamondVarient from './DiamondVarient';
import StoneVarient from './StoneVarient';


const UpdateProduct = () => {
    const [brand, setBrand] = useState<any>([])
    const [categories, setCategories] = useState<any>([])
    const [productData, setProductData] = useState<any>([])
    const metalTypeList = useSelector((state: any) => state.dropdown.metalTypeList);
    const [selectedMetel, setSelectedMetel] = useState<any>([])
    const [diamond, setDiamond] = useState<any>([])
    const [stone, setStone] = useState<any>([])
    const [baseImage, setBaseImage] = useState<any>()
    const [baseImageList, setBaseImageList] = useState<any>([])
    const [size, setSize] = useState<any>(["5", "6", "7", "8", "9", "10"])
    const [tag, setTag] = useState<any>([])
    const [isRingBracelet, setIsRingBracelet] = useState<any>(undefined)
    const { slug } = useParams() as any;


    const dispatch = useDispatch()
    useEffect(() => {
        getUser();
        getBrands();
        getCategory();
    }, []);

    const GetAllMetalPrice = async () => {
        try {
            const response = await priceSettingService.GetAllMetalPrice()
            dispatch(getMetalTypeList(response.data.data.map((i: any) => ({
                metalWeight: "",
                diamond: [{
                    diamondCarat: "",
                    diamondQty: "",
                    diamondType: "",
                    diamondClarity: "",
                    diamondShape: "",
                    diamondColor: "",
                }],
                stone: [{
                    stoneCarat: "",
                    stoneQty: "",
                    stoneType: "",
                    stoneShape: "",
                    stoneColor: "",
                }],
                mainprice: i.price,
                ...i
            }))))
        } catch (error) {
            console.log(error);
        }
    }

    const getUser = async () => {
        try {
            const response = await productService.getOneProduct(slug);
            const data = response?.data?.data?.product;
            setProductData(data)
            setFieldValue('brandId', data.brandId._id)
            setFieldValue('categoryId', data.categoryId._id)
            setFieldValue('description', data.description)
            setFieldValue('title', data.title)
            setFieldValue('feature_image', data.feature_image)
            setFieldValue('styleNo', data.styleNo)
            setFieldValue('price', data.price)
            setFieldValue('regulerPrice', data.regulerPrice)
            setBaseImage(data?.feature_image)
        } catch (error) {
            console.error(error);
        }
    }

    const getBrands = async () => {
        try {
            const response = await brandService.GetAllBrand()
            setBrand(response?.data?.data)

        } catch (err) {

        }
    }

    const getCategory = async () => {
        const response = await productService.getAllcategories()
        setCategories(response?.data?.data)
    }

    const { values, errors, handleChange, handleSubmit, touched, setFieldValue } = useFormik({
        initialValues: {
            title: "",
            description: "",
            image: "",
            regulerPrice: "",
            price: "",
            categoryId: "",
            brandId: "",
            feature_image: "",
            other_images: [],
            slug: "",
            updatedAt: "",
            styleNo: "",
            __v: "",
            _id: ""
        },
        onSubmit: async (value) => {
            try {
                const response = await productService.getUpdateProduc({ ...value, metal: selectedMetel, diamond: diamond, stone: stone })
                toast.success(response?.data?.msg)

            } catch (error) {
                toast.error((error as any)?.response?.data?.msg ? (error as any)?.response?.data?.msg : 'error')
            }
        },

    });

    const imageToBase64 = (element: any) => {
        let file = element.target.files[0];
        let reader = new FileReader();
        reader.onloadend = function () {
            const base64 = reader.result
            setFieldValue('feature_image', base64)
        }
        reader.readAsDataURL(file);
    }

    const imageToBase642 = (el: any) => {
        let files = el.target.files;
        let baseFiles: any = [];
        let newf: any = [];
        if (files && files.length > 0) {
            for (const file of files) {
                let reader = new FileReader();
                reader.onloadend = function () {
                    const base64 = reader.result
                    baseFiles.push(base64)
                }
                reader.readAsDataURL(file);
                newf.push(file)
            }
            setFieldValue('addtionalImage', baseFiles)
        }
        setBaseImageList(newf)
    }

    const handleJewelSelect = (selectedValue: any) => {
        console.log('data, data', selectedValue);

        setSelectedMetel((prev: any) => {
            let prevIds = prev.map((i: any) => i?._id)
            let newData = {}
            for (const i of selectedValue) {
                if (!prevIds.includes(i?._id)) {
                    newData = i
                }
            }
            return [...prev, newData]

        })
    }

    const onHandleDiamond = (e: any) => {
        setDiamond(e)
    }
    const onHandleStone = (e: any) => {
        setStone(e)
    }
    const handleJewelremove = (selectedValue: any) => {
        // let ids = selectedValue.map((i: any) => i?._id)
        // setSelectedMetel((prev: any) => {
        //     return prev.filter((i: any) => !ids.includes(i?._id))
        // })
        // setSelectedMetelId(ids)
    }
    const handleTagSelect = (selectedValue: any) => {
        setFieldValue('tag_ids', selectedValue.map((el: any) => el._id))
    }
    const handleCategorySelect = (data: any) => {
        setIsRingBracelet(data)
        setFieldValue('sizes', isRingBracelet)

    }

    const onHandleItemChange = useCallback((e: any, id: any, totalPrice: number, totalWight: number, index: undefined | number, type: string) => {
        const mainValue = e.target.value ? e.target.value : 0;
        const mainName = e.target.name;
        setSelectedMetel((prev: any) => {
            return prev.map((i: any) => {
                let newItrator = { ...i }
                if (id === newItrator?._id) {
                    if (index !== undefined) {
                        let dynamicType = [...newItrator[type]]
                        let editedObj = { ...dynamicType[index], [mainName]: mainValue }
                        dynamicType[index] = editedObj
                        newItrator = { ...newItrator, [type]: dynamicType }
                    } else {
                        newItrator[mainName] = mainValue
                    }

                    return { ...newItrator, price: totalPrice, totalWight }
                } else {
                    return newItrator
                }
            })
        })
    }, [])

    useEffect(() => {
        GetAllMetalPrice()
    }, [])

    useEffect(() => {
        setSelectedMetel(productData?.metal)
    }, [productData])
 

    return (
        <form onSubmit={handleSubmit}>
            <div className="container-fluid">
                <div className="d-flex align-items-center justify-content-between pb-4">
                    <div className="product-title">
                        <h2 className='main-title'>Update Product</h2>
                    </div>
                    <div className="product-btn-area">
                        <button className='secondary-button ms-1'>Save Draft</button>
                        <button className='primary-button ms-1' type='submit'>Publish</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <div className="card p-4">
                            <div className="card-top">
                                <h5 className='m-0'>Basic</h5>
                            </div>
                            <div className="card-body">
                                <div className="mb-4">
                                    <label htmlFor="title" className="form-label">Product title</label>
                                    <input type="text" placeholder="Type here" className="form-control create-product" name="title" value={values.title} onChange={handleChange} />
                                </div>
                                <div className="mb-4">
                                    <label className="form-label">Full description</label>
                                    <textarea placeholder="Type here" className="form-control create-product" name='description' value={values.description} onChange={handleChange}></textarea>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="mb-4">
                                            <label className="form-label">Category</label>
                                            <select className="form-select" name="categoryId" value={values.categoryId} onChange={handleChange}>
                                                {
                                                    categories ? categories.map((el: any) => (
                                                        <option value={el._id}>{el.title}</option>
                                                    )) : ""
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-4">
                                            <label className="form-label">Brand</label>
                                            <select className="form-select" name="brandId" value={values.brandId} onChange={handleChange}>
                                                {
                                                    brand ? brand.map((el: any) => (
                                                        <option value={el._id}>{el.title}</option>
                                                    )) : ""
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                        <div className="col-lg-6">
                                            <div className="mb-4">
                                                <label className="form-label">Tag</label>

                                                <Multiselect
                                                    options={tag}
                                                    isObject={true}
                                                    displayValue="name"
                                                    onSelect={handleTagSelect}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-4">
                                                <label className="form-label">Tax Class</label>
                                                <input placeholder="$" type="text" className="form-control create-product" name="taxclass" onChange={handleChange} />
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-4">
                                            <div className="mb-4">
                                                <label className="form-label">Weight (Gross)</label>
                                                <input type="number" disabled className="form-control create-product" name="weight_gross" value={values.weight_gross} onChange={handleChange} />
                                            </div>
                                        </div> */}
                                    </div>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="mb-4">
                                            <label className="form-label">Style No</label>
                                            <input type="text" className="form-control create-product" name="styleNo" value={values.styleNo} onChange={handleChange} />
                                        </div>
                                        {
                                            productData.categoryId === "652cc9fe62027781e1991b69" ?
                                                <div className="col-lg-6">
                                                    <div className="mb-4">
                                                        <label className="form-label">Sizes</label>

                                                        <Multiselect
                                                            options={size}
                                                            isObject={false}
                                                            onSelect={handleCategorySelect}
                                                        />
                                                    </div>
                                                </div> : undefined
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card p-4">
                            <div className="card p-4" style={{ overflow: "visible" }}>
                                <div className="card-top">
                                    <h5 className='m-0'>Product Details</h5>
                                </div>
                                <div className="pt-3">
                                    <div className="pb-3">
                                        <Multiselect
                                            options={metalTypeList}
                                            isObject={true}
                                            displayValue="name"
                                            onSelect={(e) => handleJewelSelect(e)}
                                            onRemove={handleJewelremove}
                                            selectedValues={productData?.metal}
                                        />
                                    </div>

                                    {
                                        selectedMetel?.map((i: any, index: any) =>
                                            <ProductVarient key={i?._id} data={i} onChange={onHandleItemChange} />
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="card p-4">
                            <DiamondVarient onHandleDiamond={onHandleDiamond} data={productData} />
                        </div>
                        <div className="card p-4">
                            <StoneVarient onHandleStone={onHandleStone} data={productData} />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card p-4">
                            <div className="card-top">
                                <h6>Media</h6>
                            </div>
                            <div className="card-body">
                                <div className="input-upload text-center">
                                    <div className="image-upload-icon">
                                        {
                                            baseImage ? <img src={` ${baseImage}`} alt="logo" /> : null
                                        }
                                    </div>
                                    <input className="form-control create-product" type="file" name="feature_image" onChange={imageToBase64} />
                                </div>
                            </div>
                        </div>

                        <div className="card p-4">
                            <div className="card-top">
                                <h6>Additional Images</h6>
                            </div>
                            <div className="card-body">
                                <div className="input-upload text-center ">

                                   <div className="d-flex flex-wrap">
                                   {
                                        productData?.addtionalImage?.map((i: any) =>

                                            <React.Fragment key={i}>{i ?
                                                <div className="image-upload-icon">
                                                    {/* <i className="bi bi-images"></i> */}
                                                    <img src={` ${i}`} alt="logo" />
                                                </div> : null}</React.Fragment>

                                        )
                                    }

                                   </div>
                                    <input className="form-control create-product" type="file" name="addtionalImage" multiple onChange={imageToBase642} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default UpdateProduct