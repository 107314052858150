import axios from "./api"

const GetAllTransaction = () => {
    return axios.get('/transaction')
}
const CreateTransaction = (payload) => {
    return axios.post('/transaction', payload)
}

const transactionService = {
    GetAllTransaction,
    CreateTransaction
}

export default transactionService;