import React from 'react'
import NavbarOtherPage from './Frontend/Header/NavbarOtherPage'
import Products from '../pages/Frontend/Products'

const Productsv2 = () => {
  return (
    <>
    {/* <NavbarOtherPage/> */}
    <Products/>
    </>
  )
}

export default Productsv2