import React, { useEffect, useState } from 'react'
import priceSettingService from '../../../service/priceSettingService';
import { getDiamondTypeList } from '../../../slices/DropdownSlice';
import { useDispatch, useSelector } from 'react-redux'
import DiamontInput from './DiamontInput';

function StoneVarient({ onHandleDiamond, data }: any) {
    const dispatch = useDispatch()
    const { symbol } = useSelector((state: any) => state.currency)
    const [diamonditem, setDiamonditem] = useState<any>([])



    useEffect(() => {
        const GetDiamondPrice = async () => {
            try {
                const response = await priceSettingService.GetAllDiamondPrice()

                dispatch(getDiamondTypeList(response.data.data))
            } catch (error) {
                console.log(error);
            }
        }

        if (dispatch !== undefined) {
            GetDiamondPrice()
        }


    }, [dispatch]);


    useEffect(() => {
        if (diamonditem && onHandleDiamond) {
            onHandleDiamond(diamonditem)
        }
    }, [diamonditem, onHandleDiamond])

    useEffect(() => {
        const updatedData = data?.diamond?.map((i: any) => ({ ...i, diamondType: i?.diamondType?._id ? i?.diamondType?._id : i?.diamondType }))
        setDiamonditem(updatedData)
    }, [data])

    const total = diamonditem?.reduce((sum:any, item:any) => sum + Number(item.diamondPrice), 0);
    const totalWeight = diamonditem?.reduce((sum:any, item:any) => sum + Number(item.diamondCarat), 0);

    return (
        <>
            <div className="mb-3 border-bottom pb-3">

                <div className="row mb-4">
                    <div className="col-12 mb-2">
                        <div className="d-flex justify-content-between align-items-center">
                            <h6>Diamond Details</h6>
                            <button type='button' onClick={() =>
                                setDiamonditem((prev: any) => {
                                    let newprev:any = []
                                    if (prev) {
                                        newprev = [...prev]
                                    }
                                    newprev.push({
                                        diamondPrice: 0,
                                        diamondCarat: 0,
                                        diamondQty: 0,
                                        diamondType: "",
                                        diamondShape: "",
                                    })
                                    return newprev
                                })} className="btn btn-primary primary-button">add more</button>
                        </div>
                    </div>

                    {
                        diamonditem?.map((j: any, index: any) => (
                            <div className='mb-3 row' key={index}>
                                <DiamontInput item={j} onHandleItemChange={(e: any) => {
                                    setDiamonditem((prev: any[]) => {
                                        let i = 0
                                        const newData = []
                                        for (const iterator of prev) {
                                            if (i === index) {
                                                newData.push(e)
                                            } else {
                                                newData.push(iterator)
                                            }
                                            i++
                                        }

                                        return newData
                                    }
                                    )
                                }} />
                                <div className="col-12 mt-2">
                                    <small>Total Price : {symbol}{j.diamondPrice}</small>
                                </div>
                            </div>
                        ))
                    }

                </div>

                Total: {symbol}{total} <br />
                Total CT: {parseFloat((Math.round(totalWeight * 100) / 100).toFixed(2))} 
            </div>

        </>
    )
}

export default StoneVarient