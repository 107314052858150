import React from 'react'
import { Outlet } from 'react-router' 
import NavbarOtherPage from './Header/NavbarOtherPage'
import Footer from './Footer'

const OtherpageLayout = () => {
  
  return (
    <>
      <main>
        <NavbarOtherPage />
        <Outlet />
      <Footer />
      </main>
    </>
  )
}

export default OtherpageLayout
