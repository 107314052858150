import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import NavbarToggler from "../../../components/Frontend/Navbar/NavbarToggler";
import { useSelector } from 'react-redux';
import NavbarMenu from './NavbarMenu';

const HeaderMain = () => {
  const { userdata, token } = useSelector((state: any) => state.user)
  const [scrolled, setScrolled] = useState(false)




  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 130) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  return (
    <header className="header">
      <div className={`logo-wrapper ${scrolled ? 'dissapear-logo ' : 'logo-wrapper'}`}>
        <Link to="/" >
          <img src={process.env.PUBLIC_URL + "/assets/images/gems.png"} alt="" />
        </Link>
      </div>
      <nav className={`arsa-container-fluid ${scrolled ? 'sticky ' : 'arsa-nav'}`}>
        <NavbarToggler />
        <div className="nav-left-content">
          {
            scrolled ?
              <img src="./assets/images/gems.png" alt="" /> :
              <div className="search">
                <div className="search-box">
                  <button className="btn-search"><i className="bi bi-search"></i></button>
                  <input type="text" className="input-search" placeholder="Type to Search..." />
                </div>
              </div>
          }
        </div>
        <div className="nav-middle-content">
          <NavbarMenu />
        </div>
        <div className="nav-right-content">
          <div className="res-search">
            <Link to={'/frontend'}>
              <i className="bi bi-search"></i>
            </Link>
          </div>
          {!token && <div className="user__account">
            <Link to={'/login'}>
              <i className="bi bi-person"></i>
            </Link>
          </div>}
          {token && <div className="user__account pr-2">
            <Link to={'/account'} >
              <i className="bi bi-person"></i>
            </Link>
          </div>}
          <div className="wishlist">
            <Link to={'/frontend'}>
              <i className="bi bi-suit-heart"></i>
            </Link>
          </div>
          <div className="cart">
            <Link to={'/cart'}>
              <span><i className="bi bi-basket3"></i> </span>
              <span>0</span>
            </Link>
          </div>

        </div>
      </nav>
    </header>
  )
}

export default HeaderMain