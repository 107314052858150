import React, {useEffect} from 'react'
import ProductSummary from '../../components/Frontend/ProductSummary'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { removeCartItem, decreasequantity, increasequantity, getCartTotal } from '../../slices/CartSlice'
import { getAmount,  } from "../../utils/utils"
import { useNavigate } from "react-router-dom"


const Cart = () => {
    const cart = useSelector((state: any) => state.cart)
    const { currency, symbol } = useSelector((item: any) => item.currency)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleCartItemRemove = (data: any) => {
        dispatch(removeCartItem(data))  
    }

    const handlequantityIncrease=(data :any)=>{
        dispatch(increasequantity(data))
    }
    const handlequantityDecrease=(data :any)=>{
        dispatch(decreasequantity(data))
    }

    const naigated=()=>{
        navigate('/checkout')
    }

    useEffect(() => {
        dispatch(getCartTotal())
    }, [cart, dispatch])

    return (
        <>
            <div className="cart-wrap">
                <div className="container-frontend">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="cart-title">
                                <h3>Cart</h3>
                            </div>
                            {cart.cartItems.length === 0 ? (
                                <>
                                    <h3>your cart is empty</h3>
                                    <div className="start-shopping">
                                        <label htmlFor="goback"><i className="bi bi-arrow-right"></i></label>
                                        <Link to="/" id='goback'>Start Shopping</Link>
                                    </div>
                                </>
                            ) : (
                                <div className="cart-item">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <th>Image</th>
                                                <th>Product Name</th>
                                                <th>UNIT PRICE	</th>
                                                <th>QUANTITY	</th>
                                                <th>LINE TOTAL</th>
                                            </tr>
                                            {cart.cartItems?.map((cartItem: any) =>

                                                <tr>
                                                    <td><img src={cartItem.feature_image} alt="" /></td>
                                                    <td>
                                                        <span onClick={()=> navigate('/products/' +  cartItem.slug)}> {cartItem.title}</span>
                                                        <span onClick={() => handleCartItemRemove(cartItem._id)}> remove</span>
                                                    </td>
                                                    <td>{symbol}{getAmount(currency, cartItem?.cartTotalAmount)}</td>
                                                    <td>
                                                        <div className="number-picker">
                                                            <div className="input-group-quantity">
                                                                <button type="button" data-type="minus" className="btn btn-number btn-minus" disabled={cartItem.quantity == 1} onClick={()=>handlequantityDecrease(cartItem)}>
                                                                    <i className="bi bi-chevron-left"></i>
                                                                </button>
                                                                <input type="text" min="1" max="" className="form-control input-number input-quantity" value={cartItem.quantity} />
                                                                <button type="button" data-type="plus" className="btn btn-number btn-plus"  onClick={()=>handlequantityIncrease(cartItem)}>
                                                                    <i className="bi bi-chevron-right"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{symbol}{getAmount(currency, cartItem?.cartTotalAmount * cartItem?.quantity)}</td>
                                                
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}

                        </div>
                        <div className="col-md-12">
                            <ProductSummary onClick={()=>naigated()}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cart
