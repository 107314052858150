import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import StoneInput from './StoneInput';

function StoneVarient({ onHandleStone, data }: any) {
    const { symbol } = useSelector((state: any) => state.currency)
    const [stoneitem, setStoneitem] = useState<any>([]) 

    useEffect(() => {
        if (stoneitem && onHandleStone) {
            onHandleStone(stoneitem)
        }
    }, [stoneitem, onHandleStone])

    useEffect(() => {
        const updatedData = data?.stone?.map((i: any) => ({ ...i, stoneType: i?.stoneType?._id ? i?.stoneType?._id : i?.stoneType }))
        setStoneitem(updatedData)
    }, [data])

    const total = stoneitem?.reduce((sum:any, item:any) => sum + Number(item.stonePrice), 0);
    const totalWeight = stoneitem?.reduce((sum:any, item:any) => sum + Number(item.stoneCarat), 0);

    return (
        <>
            <div className="mb-3 border-bottom pb-3">
                <div className="row mb-4">
                    <div className="col-12 mb-2">
                        <div className="d-flex justify-content-between align-items-center">
                            <h6>Stone Details</h6>
                            <button type='button' onClick={() =>
                                setStoneitem((prev: any) => {
                                    let newprev:any = []
                                    if (prev) {
                                        newprev = [...prev]
                                    }
                                    newprev.push({
                                        stonePrice: 0,
                                        stoneType: "",
                                        perCaratePrice: 0,
                                        stoneCarat: 0,
                                        stoneQty: 0,
                                        stoneShape: "",
                                        stoneColor: "",
                                    })
                                    return newprev
                                })} className="btn primary-button btn-primary">Add Stone</button>
                        </div>
                    </div>
                    {
                        stoneitem?.map((j: any, index: any) => (
                            <div className='mb-3 row' key={index}>
                                <StoneInput item={j} onHandleItemChange={(e: any) => {
                                    setStoneitem((prev: any[]) => {
                                        let i = 0
                                        const newData = []
                                        for (const iterator of prev) {
                                            if (i === index) {
                                                newData.push(e)
                                            } else {
                                                newData.push(iterator)
                                            }
                                            i++
                                        }
                                        return newData
                                    })
                                }} />
                                <div className="col-12 mt-2">
                                    <small>Stones Price : {symbol}{j.stonePrice}</small>
                                </div>

                            </div>
                        ))
                    }

                </div>
                    Total: {symbol}{total} <br />
                    Total CT: {parseFloat((Math.round(totalWeight * 100) / 100).toFixed(2))} 
            </div>

        </>
    )
}

export default StoneVarient