import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useNavigate } from 'react-router-dom'
import { Breadcrumb } from 'react-bootstrap';
import { logOut } from '../../../slices/AuthSlice';
const Account = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const userdata = useSelector((state: any) => state.user?.userdata)
  const navigated = (route: any) => {
    navigate(route)
  }
  const handleLogout =()=>{
    dispatch(logOut())
        navigate('/')
  }


  return (
    <>
      <div className="account-header">
        <div className='arsa-container-fluid d-flex justify-content-flex-end'>
          <Breadcrumb>
            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
              Ring
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Size</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className="accounnt_body">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-4 col-md-4 col-12">
              <nav className="navbar navbar-expand-md mb-4 mb-lg-0 sidenav">
                <a className="d-xl-none d-lg-none d-md-none text-inherit fw-bold" href="/">Sidebar Menu</a>
                <button className="navbar-toggler d-md-none rounded bg-primary text-light" type="button">
                  <span className="ti-menu"></span>
                </button>
                <div className="collapse navbar-collapse " id="sidenav">
                  <div className="navbar-nav flex-column">
                    <div className="m-4">
                      <div className="row no-gutters align-items-center">
                        <div className="col-auto">
                          <div className="avater">PT</div>
                        </div>
                        <div className="col-auto">
                          <h6 className="d-block font-weight-bold mb-0">{userdata?.firstName} {userdata?.lastName}</h6>
                          <small className="text-muted">{userdata?.email}</small>
                        </div>
                      </div>
                    </div>
                    <ul className="list-unstyled mb-0">
                      <li className="nav-item" onClick={() => navigated('/account')}>
                        <Link to="" className="nav-link">
                          <i className="bi bi-person-circle"></i>
                          My Account
                        </Link>
                      </li>
                      <li className="nav-item" onClick={() => navigated('address')}>
                        <Link to="" className="nav-link">
                          <i className="bi bi-book"></i>Address</Link>
                      </li>
                      <li className="nav-item" onClick={() => navigated('order')}>
                        <Link className="nav-link" to="" >
                          <i className="bi bi-bag"></i>
                          Order
                        </Link>
                      </li>
                      <li className="nav-item " onClick={() => navigated('wishlist')}>
                        <Link className="nav-link" to="" >
                          <i className="bi bi-heart"></i>Wishlist
                        </Link>
                      </li>
                      <li className="nav-item" onClick={() => handleLogout()}>
                        <Link className="nav-link" to="" ><i className="bi bi-box-arrow-right"></i> Logout
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
            <div className="col-lg-8 col-md-8 col-12">
              <div className="ml-0 ml-md-4">
                <div className="d-none d-md-block">
                  <div className="row mb-md-5">
                    <div className="col">
                      <h5 className="mb-1 ">Account Details</h5>
                      <p className="mb-0  small">You have full control to manage your own Account.</p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <Outlet />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Account
function dispatch(arg0: void) {
  throw new Error('Function not implemented.');
}

