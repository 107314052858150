import { createSlice } from '@reduxjs/toolkit'
const DropdownSlice = createSlice({
    name: 'dropdown',
    initialState: {
        metalTypeList:[],
        diamondTypeList:[],
        stoneTypeList:[]
    },
    reducers: {
        getMetalTypeList(state, actions) {
            state.metalTypeList = actions.payload
        },
        getDiamondTypeList(state, actions) {
            state.diamondTypeList = actions.payload
        },
        getStoneTypeList(state, actions) {
            state.stoneTypeList = actions.payload
        },
       
    }
})

export const { getMetalTypeList, getDiamondTypeList, getStoneTypeList } = DropdownSlice.actions;
export default DropdownSlice;
