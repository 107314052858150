import React from 'react';
import SliderMain from '../../components/Frontend/SliderMain';
import ProductSlider from '../../layouts/ProductSlider';
import FilterGallery from '../../layouts/FilterGallery';
import Masonary from '../../layouts/Masonary';

const Home = () => {
  return (
    <>
        <SliderMain/>
        <ProductSlider/>
        <FilterGallery/>
        <Masonary/>
       
    </>
  )
}

export default Home
