import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import PriceSwitcher from '../../../components/common/PriceSwitcher'
import { changeCurrency } from '../../../slices/currencyChangerSlice';
import NavbarMenu from './NavbarMenu';


const NavbarOtherPage = () => {
    const { userdata, token } = useSelector((state: any) => state.user)
    const cart = useSelector((state: any) => state.cart)
    const dispatch = useDispatch()
    return (
        <>
            <section className='navbar-other-page'>
                <div className="arsa-container-fluid">
                    <nav>
                        <Link to="/" className="navbar-left">
                            <img src={process.env.PUBLIC_URL + "/assets/images/gems.png"} alt="" />
                        </Link>
                        <div className="nav-middle-content">
                            <NavbarMenu/>
                        </div>
                        <div className="navbar-right">
                            <div className="price-switcher">
                                <select className=" currency-changer" onChange={(el) => dispatch(changeCurrency({ currency: el.target.value }))}>
                                    <option value="USD">$ USD</option>
                                    <option value="INR" selected>₹ INR</option>
                                </select>
                            </div>
                            <div className="search">
                                <i className="bi bi-search"></i>
                            </div>
                            {!token ?
                                <div className="user__account">
                                    <Link to={'/login'}>
                                        <i className="bi bi-person"></i>
                                    </Link>
                                </div> :
                                <div className="user__account">
                                    <Link to={'/account'}>
                                        <i className="bi bi-person"></i>
                                    </Link>
                                </div>}
                            <div className="wishlist">
                                <Link to={'/frontend'}>
                                    <i className="bi bi-suit-heart"></i>
                                </Link>
                            </div>
                            <div className="cart">
                                <Link to={'/cart'}>
                                    <span>CART</span>
                                    <span><i className="bi bi-basket3"></i> </span>
                                    <span>{cart?.cartItems?.length}</span>
                                </Link>
                            </div>
                        </div>
                    </nav>
                </div>
            </section>


        </>
    )
}

export default NavbarOtherPage