import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import priceSettingService from '../../../../service/priceSettingService';

const MetalTab = () => {
    const [metalPrice, setMetalPrice] = useState<any>()
    const [selectedId, setSelectedId] = useState<any>()

    useEffect(() => {
        getPriceSetting()
    }, [])

    const { values, handleChange, handleSubmit, setFieldValue } = useFormik({

        initialValues: {
            name: "",
            price: ""
        },
        onSubmit: async (value) => {
            try {
                let response = await priceSettingService.CreateMetalPriceSetting(value)
                toast.success(response?.data?.msg)
                setFieldValue('name', "")
                setFieldValue('price', "")
                getPriceSetting()

            } catch (error) {
                toast.error((error as any)?.response?.data?.msg ? (error as any)?.response?.data?.msg : 'error')
            }
        },

    });

    const getPriceSetting = async () => {
        try {
            const response = await priceSettingService.GetAllMetalPrice()
            setMetalPrice(response?.data?.data)

        } catch (err) {
            console.log(err);

        }
    }
    const handleDisable = async (id: any) => {
        setSelectedId(id)

    }

    const saveSetting = async (e:any) => {
        console.log(e);
        
        // const res = await priceSettingService.UpdateMetalPriceSetting()
        // setSelectedId('')

    }

    const handleRemove = async (id:any)=>{
        const res = await priceSettingService.DeleteMetalPriceSetting(id)
        console.log(res);
        
        toast.success(res?.data?.msg)
        getPriceSetting()
    }


    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="card-body">
                    {
                        metalPrice ? metalPrice.map((i: any) =>
                            <div className="mb-2" key={i._id}>
                                <div className="price-setting-row">

                                    <input type="text" placeholder={i.name} className="form-control create-product"  name={i.name}  disabled={i._id !== selectedId ? true : false} onChange={handleChange} />
                                    <input type="text" placeholder={i.price} className="form-control create-product"  name={i.price}  disabled={i._id !== selectedId ? true : false} onChange={handleChange}/>

                                    {
                                        i._id === selectedId ? <i className="bi bi-cloud-download-fill" onClick={()=>saveSetting(i._id)}></i> :
                                        <i className="bi bi-pencil" onClick={() => handleDisable(i._id)}></i>
                                    }
                                    <i className="bi bi-trash3-fill" onClick={() =>handleRemove(i._id)}></i>
                                </div>
                            </div>
                        ) : ""
                    }

                    <div className="card-top">
                        <h5 className='mt-0'>Add New</h5>
                    </div>
                    <div className="mb-4">
                        <div className="price-setting-row">
                            <input type="text" placeholder="name" className="form-control create-product" name="name" value={values.name} onChange={handleChange} />
                            <input type="text" placeholder="price" className="form-control create-product" name="price" value={values.price} onChange={handleChange} />
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="product-btn-area">
                            <button className='primary-button ms-1' type='submit'>Create</button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default MetalTab