import React, { useState, useEffect } from 'react'
import offerService from '../../../service/offerService'
import { useNavigate } from 'react-router-dom'
import DataTable from 'react-data-table-component';


const Offers = () => {

  const [getdata, setdata] = useState<any>()
  const navigate = useNavigate()

  useEffect(() => {
    getOffers()
  }, [])

  const getOffers = async () => {
    let response = await offerService.GetAllOffers()
    let data = response?.data?.data
    console.log('offer', data);
    setdata(data);
  }

  const offerCreate = () => {
    navigate('add')
  }

  const columns = [
    {
        name: 'Offer Name',
        selector: (row: any) => <h6 className='m-0 product-name'>{row.name}</h6>,
        sortable: true,
    },
    {
        name: 'Offer Type',
        selector: (row: any) => row.offer_type,
        sortable: true,
    },
    {
        name: 'Amount / Percentage',
        selector: (row: any) => row.amount,
        sortable: true,
    },
];

  return (
    <div className="container-fluid">
      <div className="d-flex align-items-center justify-content-between pb-4">
        <div className="product-title">
          <h2 className='main-title'>Offer List</h2>
          {/* <p className='m-0'>Lorem ipsum dolor sit amet.</p> */}
        </div>
        <div className="product-btn-area">
          <button onClick={offerCreate} className='primary-button ms-1'>Create Offers</button>
        </div>
      </div>
      <div className="card p-4">
                <DataTable
                    columns={columns}
                    data={getdata}
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight='600px'
                    // selectableRows
                    selectableRowsHighlight
                    highlightOnHover
                    subHeader
                    defaultSortAsc={true}
                />
            </div>
    </div>
  )
}

export default Offers