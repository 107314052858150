import axios from "./api"

const GetAllMetalPrice = () => {
    return axios.get('/metalpricesetting')
}
const CreateMetalPriceSetting = (payload) => {
    return axios.post('/metalpricesetting', payload)
}
const UpdateMetalPriceSetting = (payload) => {
    return axios.put('/metalpricesetting/'+payload._id, payload)
}
const DeleteMetalPriceSetting = (id) => {
    return axios.delete('/metalpricesetting/'+ id,)
}



const GetAllDiamondPrice = () => {
    return axios.get('/diamondpricesetting')
}
const CreateDiamondPriceSetting = (payload) => {
    return axios.post('/diamondpricesetting', payload)
}
const UpdateDiamondPriceSetting = (payload) => {
    return axios.put('/diamondpricesetting/'+payload._id, payload)
}
const DeleteDiamondPriceSetting = (id) => {
    return axios.delete('/diamondpricesetting/'+ id,)
}


const GetAllStonePrice = () => {
    return axios.get('/stonepricesetting')
}
const CreateStonePriceSetting = (payload) => {
    return axios.post('/stonepricesetting', payload)
}

const priceSettingService = {
    GetAllMetalPrice,
    CreateMetalPriceSetting,
    UpdateMetalPriceSetting,
    DeleteMetalPriceSetting,
    GetAllDiamondPrice,
    CreateDiamondPriceSetting,
    UpdateDiamondPriceSetting,
    DeleteDiamondPriceSetting,
    GetAllStonePrice,
    CreateStonePriceSetting

}

export default priceSettingService;