import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import authService from '../../service/authService'
import { useDispatch } from "react-redux"
import { logIn, logOut } from '../../slices/AuthSlice'
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const Login2 = () => {
    const [active, setActive] = useState<Boolean>(false)
    const dispatch = useDispatch();
    const { values, errors, handleChange, handleSubmit, touched } = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: async (values, e) => {
            console.log('log', values, e);
            
                try {
                    let response = await authService.login(values)
                    dispatch(logIn(response?.data?.data))
                    toast.success(response?.data?.msg)

                } catch (error) {
                    dispatch(logOut())
                    toast.error((error as any)?.response?.data?.msg ? (error as any)?.response?.data?.msg : 'error')
                }
        },
    });
    const signUp = () => {
        setActive(true)

    }
    const signIn = () => {
        setActive(false)


    }
    return (
        <>
            <div className="auth-register">
                <div className={`auth-wrapper ${active ? 'right-panel-active' : ''}`}>
                    <div className="auth-container sign-up-container">
                        <form action="#">
                            <h2>Create Account</h2>
                            <div className="social-container">
                                <a href="#" className="social"><i className="bi bi-facebook"></i></a>
                                <a href="#" className="social"><i className="bi bi-google"></i></a>
                                <a href="#" className="social"><i className="bi bi-linkedin"></i></a>
                            </div>
                            <span>or</span>
                            <input type="text" placeholder="Name" />
                            <input type="email" placeholder="Email" />
                            <input type="password" placeholder="Password" />
                            <button className='primary-button mt-2' ><Link to="/register">Sign up</Link></button>
                        </form>
                    </div>
                    <div className="auth-container sign-in-container">
                        <form onSubmit={handleSubmit}>
                            <h2>Sign in</h2>
                            <div className="social-container">
                                <a href="#" className="social"><i className="bi bi-facebook"></i></a>
                                <a href="#" className="social"><i className="bi bi-google"></i></a>
                                <a href="#" className="social"><i className="bi bi-linkedin"></i></a>
                            </div>
                            <span>or</span>
                                <input type="email" placeholder="Email" name="email" className="form-control" onChange={handleChange}
                                    value={values.email} />
                                {errors.email && touched.email && <div>{errors.email}</div>}
                                <input type="password" placeholder="Password" name="password" className="form-control" onChange={handleChange}
                                    value={values.password} />
                                {errors.password && touched.password && <div>{errors.password}</div>}
                            <a href="/" className='forgot-password'>Forgot your password?</a>
                            <button className='primary-button'>Sign In</button>
                        </form>
                    </div>
                    <div className="auth-overlay-container">
                        <div className="auth-overlay">
                            <div className="auth-overlay-panel auth-overlay-left">
                                <h1>Arsa Gems</h1>
                                <p>Let Your Stones Talk About You</p>
                                <button className="secondary-button" onClick={() => signIn()}>Sign In</button>
                            </div>
                            <div className="auth-overlay-panel auth-overlay-right">
                                <h1>Arsa Gems</h1>
                                <p>Let Your Stones Talk About You</p>
                                <button className="secondary-button" onClick={() => signUp()}>Sign Up</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login2