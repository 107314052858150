import React, { useCallback, useEffect, useState } from 'react'
import { useFormik } from 'formik';
import productService from "../../../service/productService"
import brandService from '../../../service/brandService';
import toast from 'react-hot-toast';
import AtrributeRow from '../../../components/common/AtrributeRow';
import { Multiselect } from 'multiselect-react-dropdown';
import tagService from '../../../service/tagService';
import priceSettingService from '../../../service/priceSettingService';
import { useDispatch, useSelector } from 'react-redux'
import { getDiamondTypeList, getMetalTypeList, getStoneTypeList } from '../../../slices/DropdownSlice';
import ProductVarient from './ProductVarient';
import DiamondVarient from './DiamondVarient';
import StoneVarient from './StoneVarient';
import { useNavigate } from 'react-router-dom';
import { Tree } from 'primereact/tree';

const AddProduct = () => {
    const dispatch = useDispatch()
    //Get Global state data
    const metalTypeList = useSelector((state: any) => state.dropdown.metalTypeList);
    //Local state
    const [brand, setBrand] = useState<any>([])
    const [categories, setCategories] = useState<any>([])
    const [tag, setTag] = useState<any>([])
    const [size, setSize] = useState<any>(["5", "6", "7", "8", "9", "10"])
    const [isRingBracelet, setIsRingBracelet] = useState<any>(undefined)

    const [selectedAttr, setselectedAttr] = useState<any>([])
    const [selectedMetel, setSelectedMetel] = useState<any>([])
    const [diamond, setDiamond] = useState<any>([])
    const [stone, setStone] = useState<any>([])
    //for display data
    const [baseImage, setBaseImage] = useState<any>()
    const [baseImageList, setBaseImageList] = useState<any>([])

    // State for selected keys
    const [selectedKeys, setSelectedKeys] = useState({});
    
   
  


    const navigate = useNavigate()
    const GetAllMetalPrice = async () => {
        try {
            const response = await priceSettingService.GetAllMetalPrice()
            dispatch(getMetalTypeList(response.data.data.map((i: any) => ({
                metalWeight: "",
                diamond: [{
                    diamondCarat: "",
                    diamondQty: "",
                    diamondType: "",
                    diamondClarity: "",
                    diamondShape: "",
                    diamondColor: "",
                }],
                stone: [{
                    stoneCarat: "",
                    stoneQty: "",
                    stoneType: "",
                    stoneShape: "",
                    stoneColor: "",
                }],
                mainprice: i.price,
                ...i
            }))))
        } catch (error) {
            console.log(error);
        }
    }
    const GetDiamondPrice = async () => {
        try {
            const response = await priceSettingService.GetAllDiamondPrice()
            dispatch(getDiamondTypeList(response.data.data))
        } catch (error) {
            console.log(error);
        }
    }
    const GetStonePrice = async () => {
        try {
            const response = await priceSettingService.GetAllStonePrice()
            dispatch(getStoneTypeList(response.data.data))
        } catch (error) {
            console.log(error);
        }
    }
    const getBrands = async () => {
        try {
            const response = await brandService.GetAllBrand()
            setBrand(response?.data?.data)

        } catch (err) {

        }
    }

    const getTag = async () => {
        try {
            const response = await tagService.GetAllTag()
            setTag(response.data?.data)
        } catch (err) {
            console.log(err);

        }
    }

    const handleSelectionChange = (e:any) => {
        setSelectedKeys(e.value); 
    };

    const getSelectedIds = () => {
        return Object.keys(selectedKeys);
    };
    
    useEffect(() => {
        GetAllMetalPrice()
        GetDiamondPrice()
        GetStonePrice()
        getBrands();

        getTag();
    }, []);

    const { values, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik({
        initialValues: {
            title: "",
            description: "",
            feature_image: "",
            addtionalImage: [],
            categoryId: "",
            brandId: "",
            attributes: [],
            tag_ids: [],
            sizes: [],
            styleNo: "",
            gender: ""
        },
        onSubmit: async (value) => {
            try {

                const selectedIds = getSelectedIds();
                console.log({ ...value, metal: selectedMetel, diamond: diamond, stone: stone, categoryId: selectedIds });
                let response = await productService.productAdd({ ...value, metal: selectedMetel, diamond: diamond, stone: stone, categoryId:selectedIds })
                toast.success(response?.data?.msg)
                resetForm()
                setBaseImage(undefined)
                setBaseImageList([])
                setSelectedMetel([])
                setDiamond([])
                setStone([])
                setselectedAttr([])
                navigate("/admin/products")


            } catch (error) {
                toast.error((error as any)?.response?.data?.msg ? (error as any)?.response?.data?.msg : 'error')
            }
        },
    });

    const handleTagSelect = (selectedValue: any) => {
        setFieldValue('tag_ids', selectedValue.map((el: any) => el._id))
    }

    const handleJewelSelect = (selectedValue: any) => {
        setSelectedMetel((prev: any) => {
            let prevIds = prev.map((i: any) => i?._id)
            let newData = {}
            for (const i of selectedValue) {
                if (!prevIds.includes(i?._id)) {
                    newData = i
                }
            }
            return [...prev, newData]

        })
    }
    const handleJewelremove = (selectedValue: any) => {
        // let ids = selectedValue.map((i: any) => i?._id)
        // setSelectedMetel((prev: any) => {
        //     return prev.filter((i: any) => !ids.includes(i?._id))
        // })
        // setSelectedMetelId(ids)
    }

    const imageToBase64 = (element: any) => {
        let file = element?.target?.files?.[0];
        if (file) {
            let reader = new FileReader();
            reader.onloadend = function () {
                const base64 = reader.result
                setFieldValue('feature_image', base64)
            }
            reader.readAsDataURL(file);

            setBaseImage(file)
        }
    }

    const imageToBase642 = (el: any) => {
        let files = el.target.files;
        let baseFiles: any = [];
        let newf: any = [];
        if (files && files.length > 0) {
            for (const file of files) {
                let reader = new FileReader();
                reader.onloadend = function () {
                    const base64 = reader.result
                    baseFiles.push(base64)
                }
                reader.readAsDataURL(file);
                newf.push(file)
            }
            setFieldValue('addtionalImage', baseFiles)
        }
        setBaseImageList(newf)
    }



    const onHandleItemChange = useCallback((e: any, id: any, totalPrice: number, totalWight: number, index: undefined | number, type: string) => {
        const mainValue = e.target.value ? e.target.value : 0;
        const mainName = e.target.name;
        setSelectedMetel((prev: any) => {
            return prev.map((i: any) => {
                let newItrator = { ...i }
                if (id === newItrator?._id) {
                    if (index !== undefined) {
                        let dynamicType = [...newItrator[type]]
                        let editedObj = { ...dynamicType[index], [mainName]: mainValue }
                        dynamicType[index] = editedObj
                        newItrator = { ...newItrator, [type]: dynamicType }
                    } else {
                        newItrator[mainName] = mainValue
                    }

                    return { ...newItrator, price: totalPrice, totalWight }
                } else {
                    return newItrator
                }
            })
        })
    }, [])


    const onHandleDiamond = (e: any) => {
        setDiamond(e)
    }
    const onHandleStone = (e: any) => {
        setStone(e)
    }



    const handleCategorySelect = (data: any) => {
        setIsRingBracelet(data)
        setFieldValue('sizes', isRingBracelet)

    }
    useEffect(() => {
        setFieldValue('attributes', selectedAttr)
    }, [selectedAttr])


    function buildHierarchy(data: any) {
        // Create a map of items by their _id
        const itemMap = new Map(data.map((item: any) => [item._id, { ...item, children: [] }]));

        // Root of the hierarchy
        const hierarchy: any = [];

        data.forEach((item: any) => {
            if (item.parent) {
                // If the item has a parent, add it to its parent's children array
                const parent: any = itemMap.get(item.parent);
                if (parent) {
                    parent.children.push(itemMap.get(item._id));
                }
            } else {
                // If no parent, it's a root item, add to the hierarchy
                hierarchy.push(itemMap.get(item._id));
            }
        });

        return hierarchy;
    }

    const getAllCategory = async () => {
        const response = await productService.getAllcategories()
        const maindata = buildHierarchy(response?.data?.data) || []
        setCategories(maindata)
    }

    // Transform categories to match Tree component structure
    const transformCategoriesToNodes = (categories: any) => {
        return categories.map((category: any) => ({
            key: category._id,
            label: category.title,
            children: category.children ? transformCategoriesToNodes(category.children) : []
        }));
    };

    // Usage example
    const nodes = transformCategoriesToNodes(categories);

    useEffect(() => {
        getAllCategory()
    }, [])

  
 

    

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="container-fluid">
                    <div className="d-flex align-items-center justify-content-between pb-4">
                        <div className="product-title">
                            <h2 className='main-title'>Add New Product</h2>
                        </div>
                        <div className="product-btn-area">
                            <button className='primary-button ms-1' type='submit'>Publish</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="card p-4">
                                <div className="card-top">
                                    <h5 className='m-0'>General</h5>
                                </div>
                                <div className="card-body">
                                    <div className="mb-4">
                                        <label htmlFor="title" className="form-label">Product title</label>
                                        <input type="text" placeholder="Type here" className="form-control create-product" name="title" value={values.title} onChange={handleChange} />
                                    </div>
                                    <div className="mb-4">
                                        <label className="form-label">Full description</label>
                                        <textarea placeholder="Type here" className="form-control create-product" name='description' value={values.description} onChange={handleChange}></textarea>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-lg-4">
                                            <div className="mb-4">
                                                <label className="form-label">Regular price</label>
                                                <div className="row gx-2">
                                                    <input placeholder="$" type="number" className="form-control create-product" name="regulerPrice" value={values.regulerPrice} onChange={handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="mb-4">
                                                <label className="form-label">Promotional price</label>
                                                <input placeholder="$" type="number" className="form-control create-product" name="price" value={values.price} onChange={handleChange} />
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="mb-4">
                                                <label className="form-label">Tag</label>

                                                <Multiselect
                                                    options={tag}
                                                    isObject={true}
                                                    displayValue="name"
                                                    onSelect={handleTagSelect}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-4">
                                                <label className="form-label">Tax Class</label>
                                                <input placeholder="$" type="text" className="form-control create-product" name="taxclass" onChange={handleChange} />
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-4">
                                            <div className="mb-4">
                                                <label className="form-label">Weight (Gross)</label>
                                                <input type="number" disabled className="form-control create-product" name="weight_gross" value={values.weight_gross} onChange={handleChange} />
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6"> 
                                        <label className="form-label">Category</label>
                                            <div className="card flex justify-content-center">
                                                <Tree
                                                    value={nodes}
                                                    selectionMode="checkbox"
                                                    selectionKeys={selectedKeys}
                                                    onSelectionChange={handleSelectionChange}
                                                    className="w-full md:w-30rem"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-4">
                                                <label className="form-label">Brand</label>
                                                <select className="form-select" name="brandId" value={values.brandId} onChange={handleChange}>
                                                    <option value="">Select Brand</option>
                                                    {
                                                        brand ? brand?.map((el: any) => (
                                                            <option key={el._id} value={el._id}>{el.title}</option>
                                                        )) : ""
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="mb-4">
                                                <label className="form-label">Style No</label>
                                                <input type="text" className="form-control create-product" name="styleNo" value={values.styleNo} onChange={handleChange} />
                                            </div>
                                        </div>
                                        {/* {
                                            values.categoryId === "652cc9fe62027781e1991b69" ?
                                                <div className="col-lg-6">
                                                    <div className="mb-4">
                                                        <label className="form-label">Sizes</label>

                                                        <Multiselect
                                                            options={size}
                                                            isObject={false}
                                                            onSelect={handleCategorySelect}
                                                        />
                                                    </div>
                                                </div> : undefined
                                        } */}

                                        <div className="col-lg-4">
                                            <div className="mb-4">
                                                <label className="form-label">Gender</label>
                                                <select className="form-select" name="gender" value={values.gender} onChange={handleChange}>
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                    <option value="unisex">Unisex</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card p-4" style={{ overflow: "visible" }}>
                                <div className="card-top">
                                    <h5 className='m-0'>Product Details</h5>
                                </div>
                                <div className="pt-3">
                                    <div className="pb-3">
                                        <Multiselect
                                            options={metalTypeList}
                                            isObject={true}
                                            displayValue="name"
                                            onSelect={(e) => handleJewelSelect(e)}
                                            onRemove={handleJewelremove}
                                        />
                                    </div>

                                    {
                                        selectedMetel?.map((i: any, index: any) =>
                                            <ProductVarient key={i?._id} data={i} onChange={onHandleItemChange} />
                                        )
                                    }


                                </div>
                            </div>
                            <div className="card p-4">
                                <DiamondVarient onHandleDiamond={onHandleDiamond} />
                            </div>
                            <div className="card p-4">
                                <StoneVarient onHandleStone={onHandleStone} />
                            </div>
                            <div className="card p-4">
                                <div className="card-top">
                                    <h5 className='m-0'>Product Attributes</h5>
                                </div>
                                <div className="card-body">
                                    {
                                        selectedAttr?.map((i: any, index: number) =>
                                            <AtrributeRow key={index} item={i} onChange={(citem: any) => setselectedAttr((prev: any) => {
                                                return prev.map((j: any, ji: number) => ji === index ? citem : j)
                                            })} />
                                        )
                                    }
                                    <button className='secondary-button' type="button" onClick={() => setselectedAttr((prev: any) => [...prev, {}])}>Add Value</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card p-4">
                                <div className="card-top">
                                    <h6>Base Image</h6>
                                </div>
                                <div className="card-body">
                                    <div className="input-upload text-center">
                                        <div className="image-upload-icon">
                                            {
                                                baseImage ? <img src={URL.createObjectURL(baseImage)} alt="logo" /> : null
                                            }

                                        </div>
                                        <input className="form-control create-product" type="file" name="feature_image" onChange={imageToBase64} />
                                    </div>
                                </div>
                            </div>
                            <div className="card p-4">
                                <div className="card-top">
                                    <h6>Additional Images</h6>
                                </div>
                                <div className="card-body">
                                    <div className="input-upload text-center ">

                                        <div className="d-flex flex-wrap">
                                            {
                                                baseImageList?.map((i: any) =>

                                                    <React.Fragment key={i}>{i ?

                                                        <div className="image-upload-icon">
                                                            <img src={URL.createObjectURL(i)} alt="logo" />

                                                        </div> : null}</React.Fragment>

                                                )
                                            }
                                        </div>
                                        <input className="form-control create-product" type="file" name="addtionalImage" multiple onChange={imageToBase642} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default AddProduct