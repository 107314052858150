import React, { useEffect, useState } from 'react'
import ContactOption from '../../../components/common/ContactOption'
import { useParams } from 'react-router-dom';
import orderService from '../../../service/orderService';


const OrderDetails = () => {

    const [data, setData]=useState<any>([])

    let { id } = useParams();
    const getOrderDetails = async  ()=>{
        const res = await orderService.getOrdersByOrderId(id)
        setData(res?.data?.data)
    }

    useEffect(()=>{
        getOrderDetails()
    },[])
    
    return (
        <div className="container-fluid">
            <div className="d-flex align-items-center justify-content-between pb-4">
                <div className="product-title">
                    <h2 className='main-title'>Order Detail</h2>
                    <p className='m-0'>Details for Order ID: {data._id}</p>
                </div>
            </div>
            <div className="card p-4">
                <div className="card-body">
                    <div className="row mb-50 mt-20">
                        <div className="col-md-4">
                            <ContactOption
                                title={data?.customer_name}
                                desc={data}
                                icon="bi-person-fill"
                                address=""
                                buttonurl="https://facebook.com"
                                buttontext="View profile"
                            />
                        </div>
                        <div className="col-md-4">
                            <ContactOption
                                title="Order info"
                                desc="John Alexander <br>
                                alex@example.com <br>
                                +998 99 22123456"
                                icon="bi-person-fill"
                                address=""
                                buttonurl="https://facebook.com"
                                buttontext="Download info"
                            />
                        </div>
                        <div className="col-md-4">
                            <ContactOption
                                title="Deliver to"
                                desc=""
                                address={data?.address}
                                icon="bi-person-fill"
                                buttonurl=""
                                buttontext="View profile"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderDetails