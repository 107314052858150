import axios from '../../../service/api';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import productService from '../../../service/productService';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const AddCategory = () => {
    const naviagte = useNavigate()
    const [categories, setCategories] = useState<any>([])
    const [baseImage, setBaseImage] = useState<any>()

    // Add Category 
    const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            title: "",
            slug: "",
            description: "",
            parent:"",
            image:""
        },

        onSubmit: async (value, { resetForm }) => {
            try {
                let response = await productService.categoryAdd(value)
                resetForm();
                toast.success(response?.data?.msg)
                naviagte("/admin/category")
                // navigate("/admin/products")


            } catch (error) {
                toast.error((error as any)?.response?.data?.msg ? (error as any)?.response?.data?.msg : 'error')
            }
        },

    });
    const getCategory = async () => {
        const response = await productService.getAllcategories()
        setCategories(response?.data?.data)
    }

    useEffect(() => {
        getCategory()
    }, [])

    const imageToBase64 = (element: any) => {
        let file = element?.target?.files?.[0];
        if (file) {
            let reader = new FileReader();
            reader.onloadend = function () {
                const base64 = reader.result
                setFieldValue('image', base64)
            }
            reader.readAsDataURL(file);

            setBaseImage(file)
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="container-fluid">
                    <div className="d-flex align-items-center justify-content-between pb-4">
                        <div className="product-title">
                            <h2 className='main-title'>Add New Category</h2>
                        </div>
                        {/* <div className="product-btn-area">
                            <button className='secondary-button ms-1'>Save Draft</button>
                            <button className='primary-button ms-1' type='submit'>Publish</button>
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card p-4">
                                <div className="card-top">
                                    <h6>Media</h6>
                                </div>
                                <div className="card-body">
                                    <div className="input-upload text-center">
                                        <div className="image-upload-icon">
                                            {
                                                baseImage ? <img src={URL.createObjectURL(baseImage)} alt="logo" /> : null
                                            }

                                        </div>
                                        <input className="form-control create-product" type="file" name="image" onChange={imageToBase64} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="card p-4">
                                <div className="card-top">
                                    <h5 className='m-0'>Basic</h5>
                                </div>
                                <div className="card-body">
                                    <div className="mb-4">
                                        <label htmlFor="title" className="form-label">Category Name</label>
                                        <input type="text" placeholder="Type Here" className="form-control create-product" name="title" value={values.title} onChange={handleChange} />
                                    </div>
                                    <div className="mb-4">
                                        <label className="form-label">Select Parent</label>
                                        <select className="form-select" name="parent" value={values.parent}
                                            onChange={handleChange}>
                                            <option value="">Select Cattegory</option>
                                            {
                                                categories ? categories?.map((el: any) => (
                                                    <option key={el._id} value={el._id}>{el.title}</option>
                                                )) : ""
                                            }
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label className="form-label">Full description</label>
                                        <textarea placeholder="Type here" className="form-control create-product" name='description' value={values.description} onChange={handleChange}></textarea>
                                    </div>
                                    <div className="product-btn-area">
                                        <button className='primary-button ms-1'>Create</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </>
    )
}

export default AddCategory
