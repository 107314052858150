import React, { useEffect, useState } from 'react'
import globalSettingService from '../service/globalSettingService'
import { Link } from 'react-router-dom'


const Newsletter = () => {
    const [sitedata, setSitedata] = useState<any>()

    const getSiteDetail = async () => {
        const res = await globalSettingService.GetAllSettings()
        setSitedata(res?.data?.data)

    }
    const WhatsAppLink = (phoneNumber:any) => {
          const whatsappUrl = `https://wa.me/${phoneNumber}`;
          window.open(whatsappUrl, '_blank');
    }
      
    useEffect(() => {
        getSiteDetail()
    }, [])
    return (
        <section className="footer-wraper">
            <div className="arsa-container-fluid">
                <div className="row g-0" >
                    <div className="col-md-3">
                        <div className="contact-area-wraper">
                            <span className='footer-title'>Contact Us</span>
                            <p>+91 {sitedata?.contactNumber}</p>
                            <p>{sitedata?.email}</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="newsletter-area">
                            <span className='footer-title'>LET'S GET IN TOUCH!</span>
                            <p>What' s inside? Exclusive sales, new arrivals & much more.</p>
                            <form>
                                <input type="email" placeholder="Your email address" />
                                <input type="submit" value="Subscribe" />
                            </form>
                            <span>* I accept the privacy and cookies policy to receive</span>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="social-network-area">
                            <span className='footer-title'>Social Networks</span>
                            <ul>
                                <li>
                                    <a href={sitedata?.facebook} target="_blank" rel="noopener noreferrer">
                                        <i className="bi bi-facebook"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href={sitedata?.instagram} target="_blank" rel="noopener noreferrer">
                                        <i className="bi bi-instagram"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href={sitedata?.youtube} target="_blank" rel="noopener noreferrer">
                                        <i className="bi bi-youtube"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href={sitedata?.twitter} target="_blank" rel="noopener noreferrer">
                                        <i className="bi bi-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={()=>WhatsAppLink(sitedata?.contactNumber)} href=''>
                                        <i className="bi bi-whatsapp"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Newsletter