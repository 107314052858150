import React from 'react'
import { Link } from 'react-router-dom'
import { useFormik, ErrorMessage } from 'formik';
import authService from '../../service/authService';
import { useDispatch } from "react-redux"
import { logIn, logOut } from '../../slices/AuthSlice'
import toast from 'react-hot-toast';
import { signUpSchema } from "../../schemas/index";
import { useNavigate } from "react-router-dom";
const initialValues = {
    firstName: "Account",
    lastName: "",
    email: "",
    password: "",
    co_password: "",
}

const Register = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            validationSchema: signUpSchema,
            onSubmit: async (values) => {
                console.log("guyguyj", values);

                try {
                    let response = await authService.signup(values)
                    toast.success(response?.data?.msg)
                    navigate("/login")

                } catch (error) {
                    dispatch(logOut())
                    toast.error((error as any)?.response?.data?.msg ? (error as any)?.response?.data?.msg : 'error')
                }
            },
        });

    console.log('errors', errors);

    return (
        <div className="form-bg">
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <div className="form-container">
                            <div className="left-content">
                                <h3 className="title">ArsaGems</h3>
                                <h4 className="sub-title">Let your Stones talks about you</h4>
                            </div>
                            <div className="right-content">
                                <form className="form-horizontal" onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label>First Name</label>
                                        <input type="text" name="firstName" className="form-control" value={values.firstName} onChange={handleChange}
                                            onBlur={handleBlur} />
                                    </div>
                                    <div className="form-group">
                                        <label>Last Name</label>
                                        <input type="text" name="lastName" className="form-control" value={values.lastName} onChange={handleChange}
                                            onBlur={handleBlur} />
                                    </div>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="email" name="email" className="form-control" value={values.email} onChange={handleChange}
                                            onBlur={handleBlur} />
                                    </div>
                                    <div className="form-group">
                                        <label>password</label>
                                        <input type="password" name="password" className="form-control"
                                            value={values.password} onChange={handleChange}
                                            onBlur={handleBlur} />
                                    </div>
                                    <div className="form-group">
                                        <label>Confirm password</label>
                                        <input type="password" name="co_password" className="form-control"
                                            value={values.co_password} onChange={handleChange}
                                            onBlur={handleBlur} />
                                    </div>
                                    <button type="submit" className="btn signin">Sign Up</button>
                                    <p className='pt-2 pb-2 text-center'>Already have an account? <Link to="/login">Login Now</Link></p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register