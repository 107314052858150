import React, {useEffect} from 'react'
import { useFormik } from 'formik';
import addressService from '../../../service/addressService'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AddressForm = () => {
    const navigate = useNavigate();
    const userdata = useSelector((state: any) => state.user?.userdata)

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm} =
        useFormik({
            initialValues: {
                name:"",
                street_name: "",
                locality:"",
                landmark:"",
                dist:"",
                pincode:"",
                city:"",
                user_id:userdata?._id
            },
            onSubmit: async (values: any) => {
                try {
                    let response = await addressService.addAddress(values)
                    toast.success(response?.data?.msg)
                    navigate("/account/address")
                    resetForm()

                } catch (error) {
                    toast.error((error as any)?.response?.data?.msg ? (error as any)?.response?.data?.msg : 'error')
                }
            },
        });

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <form className="form-horizontal" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>Name</label>
                            <input type="text" name="name" className="form-control" value={values.name} onChange={handleChange}
                                onBlur={handleBlur} />
                        </div>
                        <div className="form-group">
                            <label>Street Name</label>
                            <input type="text" name="street_name" className="form-control" value={values.street_name} onChange={handleChange}
                                onBlur={handleBlur} />
                        </div>
                        <div className="form-group">
                            <label>Locality</label>
                            <input type="text" name="locality" className="form-control" value={values.locality} onChange={handleChange}
                                onBlur={handleBlur} />
                        </div>
                        <div className="form-group">
                            <label>Landmark</label>
                            <input type="text" name="landmark" className="form-control" value={values.landmark} onChange={handleChange}
                                onBlur={handleBlur} />
                        </div>
                        <div className="form-group">
                            <label>District</label>
                            <input type="text" name="dist" className="form-control"
                                value={values.dist} onChange={handleChange}
                                onBlur={handleBlur} />
                        </div>
                        <div className="form-group">
                            <label>PinCode</label>
                            <input type="number" name="pincode" className="form-control"
                                value={values.pincode} onChange={handleChange}
                                onBlur={handleBlur} />
                        </div>
                        <div className="form-group">
                            <label>City</label>
                            <input type="text" name="city" className="form-control"
                                value={values.city} onChange={handleChange}
                                onBlur={handleBlur} />
                        </div>
                        <button type="submit" className="btn primary-button  mt-2">Save</button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddressForm