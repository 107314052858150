import React, { useEffect, useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import attributeService from '../../../service/attributeService';
import { useParams } from 'react-router-dom';
import productService from '../../../service/productService';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


const MyVerticallyCenteredModal = ({ setAttrValue, ...rest }: any) => {
    const [newName, setNewName] = useState<any>("")

    const handleChange = (e: any) => {
        setNewName(e.target.value)

    }
    const handleSubmit = () => {
        if (newName) {
            setAttrValue((prev: any) => [...prev, newName])
            rest.onHide()
        } else {
            toast.error("error")
        }
    }

    return (
        <Modal
            {...rest}
            dialogClassName="modal-90w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="card-body">
                    <label htmlFor="name" className="form-label"> Title</label>
                    <input className="form-control create-product" type="text" name="name" onChange={(e) => handleChange(e)} />
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={rest.onHide}>Close</Button>
                <Button onClick={() => handleSubmit()}>Add</Button>
            </Modal.Footer>
        </Modal>
    );
}

const EditAttribute = () => {
    const [loader, setLoader] = useState<any>(false)
    const [attribute, setAtrribute] = useState<any>({})
    const [categories, setCategories] = useState<any>([])
    const [attrValue, setAttrValue] = useState<any>([])
    const [modalShow, setModalShow] = React.useState(false)

    const { id } = useParams() as any;

    const getAttribute = async () => {
        setLoader(true)
        try {
            const response = await attributeService.GetOneAttribute(id)
            setAtrribute(response.data.data)
            setAttrValue(response.data?.data?.values)
            setLoader(false)
        }
        catch (err) {
            setLoader(false)
        }
    }

    const getCategory = async () => {
        try {
            const response = await productService.getAllcategories()
            setCategories(response?.data?.data)
        }
        catch (err) {

        }
    }

    useEffect(() => {
        getAttribute()
        getCategory()
    }, [])

    const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            name: "",
            categoryId: "",
        },
        onSubmit: async (value: any) => {

            let response = await attributeService.UpdateAttribute(id, { ...value, values: attrValue })
            toast.success(response?.data?.msg)
        },
    });

    useEffect(() => {
        if (attribute) {
            setFieldValue('name', attribute?.name)
            setFieldValue('categoryId', attribute?.categoryId?._id)
        }

    }, [attribute, setFieldValue])


    const handleValueRemove = (item: string) => {
        setAttrValue((data: any) => {
            return data.filter((i: string) => i !== item)
        })
    }

    return (
        loader ? <p>Loading...</p> :
            <>
                <form onSubmit={handleSubmit}>
                    <div className="container-fluid">
                        <div className="d-flex align-items-center justify-content-between pb-4">
                            <div className="product-title">
                                <h2 className='main-title'>Edit Attribute</h2>
                                <p className='m-0'>Lorem ipsum dolor sit amet.</p>
                            </div>
                        </div>
                        <div className="card p-4">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <div className='row'>
                                    <div className="col-md-3">
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link disabled>Attribute Information</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">General</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">Values</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                    <div className="col-md-9">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="first">
                                                <div className="card-top">
                                                    <h5 className='m-0'>General</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="mb-4">
                                                        <label htmlFor="name" className="form-label" >Attribute Name</label>
                                                        <input type="text" placeholder="Type Here" className="form-control create-product" name="name" value={values.name} onChange={handleChange} />
                                                    </div>
                                                    <div className="mb-4">
                                                        <label htmlFor="title" className="form-label" >Categories</label>
                                                        <select className="form-select" name="categoryId" value={values.categoryId} onChange={handleChange}>
                                                            {
                                                                categories ? categories.map((el: any) => (
                                                                    <option value={el._id}>{el.title}</option>
                                                                )) : ""
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="product-btn-area">
                                                    <button className='primary-button ms-1'>Save</button>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                <div className="card-top d-flex justify-content-between align-items-center">
                                                    <h5 className='m-0'>Values</h5>
                                                    <button className='secondary-button ms-1' onClick={() => setModalShow(true)}>Add Value</button>
                                                </div>
                                                <div className="card-body">
                                                    <div className="mb-4">
                                                        {attrValue?.map((item: any, index: any) => (
                                                            <div className="attribute-values">
                                                                <div className="attribute-title">
                                                                    <span>{item}</span>
                                                                </div>
                                                                <div className="attribute-action" onClick={() => handleValueRemove(item)}>
                                                                    <i className="bi bi-trash"></i>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="product-btn-area">
                                                    <button className='primary-button ms-1' type='submit'>Save</button>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </div>
                            </Tab.Container>
                        </div>
                    </div>
                </form>
                <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    setAttrValue={setAttrValue}
                />
            </>
    )
}

export default EditAttribute