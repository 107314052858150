import React, { useState, useEffect } from 'react'
import { Multiselect } from 'multiselect-react-dropdown';
import attributeService from '../../service/attributeService';
const AtrributeRow = ({ item, onChange }: any) => {

    const [attributes, setAtrributes] = useState<any>([])
    const [attributeValue, setAttributeValue] = useState<any>([])
    const [selectedValue, setSelectedValue] = useState<any>({})
    const [attributeId, setAttributeId] = useState<any>()



    const getAttribute = async () => {
        try {
            const response = await attributeService.GetAttribute()
            setAtrributes(response.data.data)
        }
        catch (err) {
        }
    }

    const handleChange = async (e: any) => {
        const id = e.target.value
        setAttributeId(id)
        const response = await attributeService.GetOneAttribute(id)
        setAttributeValue(response?.data?.data?.values)
        console.log('response', response);
        
    }

    useEffect(() => {
        getAttribute()
    }, [])

    useEffect(() => {
        console.log(selectedValue);
        onChange(selectedValue)
    }, [selectedValue])

    const handleSelect = (value: any) => {
        setSelectedValue(() => ({
            attributeId,
            attributeName: attributes.filter((i:any)=> i?._id === attributeId)?.[0]?.name,
            values:value
        }));
    }


    return (
        <>
            <div className="col-lg-12">
                <div className="mb-4">
                    <div className="attribute-row">
                        <select className="form-select" name="attribute" onChange={(e) => handleChange(e)}>
                            <option value="">Select Attribute</option>
                            {
                                attributes ? attributes.map((el: any) => (
                                    <option key={el._id} value={el._id}>{el.name}</option>
                                )) : ""
                            }
                        </select>
                        <div className="div" style={{ width: "100%" }}>
                            <Multiselect
                                options={attributeValue}
                                onSelect={handleSelect}
                                isObject={false}
                            />
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default AtrributeRow