import React from 'react'

const AdminFooter = () => {
    return (
        <div className='admin-footer'>

            <footer className="main-footer font-xs container">
                <div className="row pb-30 pt-15">
                    <div className="col-sm-6">2023
                        © Arsagems all rights reserved .
                    </div>
                    <div className="col-sm-6">
                        <div className="text-sm-end">All rights reserved</div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default AdminFooter