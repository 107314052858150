import React from 'react'
import TransactionCompo from '../../../components/common/TransactionsCom'

const Transactions = () => {
    return (
        <div className="container-fluid">
            <div className="pb-4">
                <div className="product-title">
                    <h2 className='main-title'>Transactions</h2>
                    {/* <p className='m-0'>Lorem ipsum dolor sit amet.</p> */}
                </div>
            </div>
            <TransactionCompo />
        </div>
    )
}

export default Transactions