import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import globalSettingService from '../../../service/globalSettingService';
import toast from 'react-hot-toast';

const Setting = () => {

    const [baseImage, setBaseImage] = useState<any>("")
    const [fetchedImage, setfetchedImage] = useState<any>("")


    useEffect(() => {
        GetGlobalSetting()
    }, [])

    // const GetGlobalSetting = async () => {
    //     try {
    //         const response = await globalSettingService.GetAllSettings()
    //         setFieldValue('diamond_per_carat_weight', response.data?.data?.diamond_per_carat_weight)
    //         setFieldValue('id', response.data?.data?._id)

    //     } catch (error) {
    //         console.log(error);
    //     }
    // }
    const GetGlobalSetting = async () => {
        try {
            const response = await globalSettingService.GetAllSettings()
            setFieldValue('diamond_per_carat_weight', response.data?.data?.diamond_per_carat_weight)
            setFieldValue('id', response.data?.data?._id)
            setFieldValue('facebook', response.data?.data?.facebook)
            setFieldValue('instagram', response.data?.data?.instagram)
            setFieldValue('twitter', response.data?.data?.twitter)
            setFieldValue('youtube', response.data?.data?.youtube)
            setFieldValue('email', response.data?.data?.email)
            setFieldValue('contactNumber', response.data?.data?.contactNumber)
            setfetchedImage(response.data?.data?.siteLogo)
        
        } catch (error) {
            console.log(error);
        }
    }

    const imageToBase64 = (element: any) => {
        let file = element?.target?.files?.[0];
        if (file) {
            let reader = new FileReader();
            reader.onloadend = function () {
                const base64 = reader.result
                setFieldValue('siteLogo', base64)
            }
            reader.readAsDataURL(file);
            setBaseImage(file)
        }
    }

    const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            diamond_per_carat_weight: "",
            facebook: "",
            whatsapp: "",
            instagram: "",
            twitter: "",
            youtube: "",
            email: "",
            siteLogo: "",
            contactNumber: "",
            id: ""
        },

        onSubmit: async (value, { resetForm }) => {
            try {
                const response = await globalSettingService.updatelSettings(value)
                toast.success(response?.data?.msg)

            } catch (error) {
                toast.error((error as any)?.response?.data?.msg ? (error as any)?.response?.data?.msg : 'error')
            }
        },

    });
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="container-fluid">
                    <div className="d-flex align-items-center justify-content-between pb-4">
                        <div className="product-title">
                            <h2 className='main-title'>Setting</h2>
                            {/* <p className='m-0'>Lorem ipsum dolor sit amet.</p> */}
                        </div>
                    </div>
                    <div className="card p-4">
                        <div className="col-md-12">
                            <div className="card p-4">
                                <div className="card-top">
                                    <h5 className='m-0'>Basic</h5>
                                </div>
                                <div className="card-body">
                                    <div className="mb-4">
                                        <label htmlFor="title" className="form-label">Diamond per carat weight (Rates)</label>
                                        <input type="text" placeholder="Type Here" className="form-control create-product" name="diamond_per_carat_weight" value={values.diamond_per_carat_weight} onChange={handleChange} />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="facebook" className="form-label">Facebook</label>
                                        <input type="text" placeholder="facebook link" className="form-control create-product" name="facebook" value={values.facebook} onChange={handleChange} />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="instagram" className="form-label">Instagram</label>
                                        <input type="text" placeholder="instagram link" className="form-control create-product" name="instagram" value={values.instagram} onChange={handleChange} />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="twitter" className="form-label">Twitter</label>
                                        <input type="text" placeholder="twitter link" className="form-control create-product" name="twitter" value={values.twitter} onChange={handleChange} />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="youtube" className="form-label">Youtube</label>
                                        <input type="text" placeholder="youtube link" className="form-control create-product" name="youtube" value={values.youtube} onChange={handleChange} />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input type="text" placeholder="email link" className="form-control create-product" name="email" value={values.email} onChange={handleChange} />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="contactNumber" className="form-label">Contact Number</label>
                                        <input type="text" placeholder="Contact Number" className="form-control create-product" name="contactNumber" value={values.contactNumber} onChange={handleChange} />
                                    </div>

                                    <div className="card p-4">
                                        <div className="card-top">
                                            <h6>Base Image</h6>
                                        </div>
                                        <div className="card-body">
                                            <div className="input-upload text-center">
                                                <div className="image-upload-icon">
                                                    {
                                                        baseImage ? <img src={URL.createObjectURL(baseImage)} alt="logo" /> : <img src={fetchedImage} alt="logo" />
                                                    }


                                                </div>
                                                <input className="form-control create-product" type="file" name="siteLogo" onChange={imageToBase64} />
                                            </div>
                                        </div>
                                    </div>



                                    <div className="product-btn-area">
                                        <button className='primary-button ms-1'>Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default Setting