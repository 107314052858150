import DataTable from 'react-data-table-component';

const TransactionCompo = () => {
    const columns = [
        {
            name: 'Order ID',
            selector: (row: any) => row.id,
            sortable: true,
        },
        {
            name: 'Transaction ID',
            selector: (row: any) => row.transid,
            sortable: true,
        },
        {
            name: 'Payment Method',
            selector: (row: any) => row.paymeyhods,
            sortable: true,
        },
        {
            name: 'Creted',
            selector: (row: any) => row.creted,
            sortable: true,
        },
    ];
    const data = [
        {
            id: 1,
            transid: '25478',
            paymeyhods: "Amex",
            creted: '2022',
        },
        {
            id: 2,
            transid: '87457',
            paymeyhods: "Master Card",
            creted: '2020',
        },
        {
            id: 3,
            transid: '78985',
            paymeyhods: "Paypal",
            creted: '2014',
        },
    ]
    return (
        <div className="card p-4">
            <DataTable
                columns={columns}
                data={data}
                pagination
                fixedHeader
                fixedHeaderScrollHeight='600px'
                // selectableRows
                selectableRowsHighlight
                highlightOnHover
                subHeader
                subHeaderComponent={
                    <input className='searchbar' type='text' placeholder='Search Here' />
                }
            />
        </div>
    )
}

export default TransactionCompo