import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import addressService from '../../../service/addressService';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

const Address = () => {
    const [address, setAddress] = useState([])
    const [isLoading, setisLoading] = useState<boolean>(false)
    const user = useSelector((state: any) => state.user.userdata);

    
    const navigate = useNavigate();
    const navigated = (route: any) => {
        navigate(route)
    }

    const getAllAddress = async () => {
        setisLoading(true)
        let result = await addressService.getAddressById(user._id)
        setAddress(result?.data?.data)
        setisLoading(false)

    }

    const deleteAddress = async (id:any)=>{
        let result =  await addressService.deleteAddress(id)
        toast.success(result?.data?.msg)
        getAllAddress()
        
    }

    useEffect(() => {
        getAllAddress()
    }, [])

    return (
        isLoading ? 
        <div className="loader-wrapper">
            <span className="loader"></span>
        </div> :
        <>
            <div className="row">
                {
                    address ? address.map((i: any) =>
                        <React.Fragment key={i._id}>
                            <div className="col-lg-6">
                                <div className="address-block bg-light rounded p-3">
                                    <a className="edit_address" data-toggle="modal" data-dismiss="modal" data-target="#address_model">
                                        <i className="bi bi-pencil-square"></i>
                                    </a>
                                    <a className="delete_address" onClick={()=>{deleteAddress(i._id)}}>
                                        <i className="bi bi-trash-fill"></i>
                                    </a>
                                    <h6>{i?.street_name}</h6>
                                    <p className="text-muted">{i?.pincode}</p>
                                    <span className="text-muted">{i?.street_name}, {i?.locality}, {i?.siliguri}, {i?.landmark} West Bengal - {i?.pincode}</span>
                                </div>
                            </div>
                        </React.Fragment>
                    ) : ""
                }

            </div>
            <div className="row">

                <div className="col-auto ">
                    <button onClick={() => navigated('/account/add-address')} className="primary-button" type="submit">Add Address</button>

                </div>
            </div>
        </>
    )
}

export default Address