import React from 'react'
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import authService from '../../../service/authService';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserData } from '../../../slices/AuthSlice';

const BasicDetails = () => {
    const { userdata } = useSelector((state: any) => state.user)
    const dispatch = useDispatch()
    const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm } =
        useFormik({
            initialValues: {
                firstname: userdata?.firstname || '',
                lastname: userdata?.lastname || '',
                phone: userdata?.phone || '',
                _id: userdata?._id
            },
            onSubmit: async (values: any) => {
                try {
                    
                    let response = await authService.userUpdate(values)
                    dispatch(updateUserData(response?.data?.data))
                    toast.success(response?.data?.msg)

                } catch (error) {
                    toast.error((error as any)?.response?.data?.msg ? (error as any)?.response?.data?.msg : 'error')
                }
            },
        });
    return (
        <>
            <form className="row align-items-end" onSubmit={handleSubmit}>
                <div className="mb-3 col-12 col-md-6">
                    <label className="form-label" htmlFor="firstname">First Name</label>
                    <input type="text" className="form-control" name="firstname" value={values?.firstname} onChange={handleChange}
                        onBlur={handleBlur} />
                </div>
                <div className="mb-3 col-12 col-md-6">
                    <label className="form-label" htmlFor="lastname">Last Name</label>
                    <input type="text" className="form-control" name="lastname" value={values?.lastname} onChange={handleChange}
                        onBlur={handleBlur} />
                </div>
                <div className="mb-3 col-12 col-md-6">
                    <label className="form-label" htmlFor="phone">Phone</label>
                    <input type="text" className="form-control" name="phone" value={values?.phone} onChange={handleChange}
                        onBlur={handleBlur} />
                </div>
                <div className="mb-3 col-12 col-md-6">
                    <label className="form-label" htmlFor="email">Email</label>
                    <input disabled className="form-control" name="email" value={userdata?.email} />
                </div>
                <div className="col-12 mb-3 text-lg-right">
                    <button className="primary-button" type="submit">Edit Details</button>
                </div>
            </form>
        </>
    )
}

export default BasicDetails