import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const NavbarToggler = () => {
  const [active, setActive] = useState('res-nav-menu')
  const [isOpen, setIsOpen] = useState(false);
  const navToggle = () => {
    active === "res-nav-menu" ? setActive('res-nav-menu res-nav-menu-active') : setActive('res-nav-menu');
  }
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
 



  return (
    <>
      {/* <div className="overlay"></div> */}
      <div className="nav-toggler" onClick={navToggle}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
      <div className={active}>
        <ul>
          <li>
            <Link to="/" className='nav__list' data-toggle="collapse"> 
              watch jwellery
              <i className="bi bi-chevron-right"></i>
            </Link>
                 <div className="dropdown__container">


                <div className="dropdown__list">
                  <ul className="collapse list-unstyled">
                    <li>populer ring types</li>
                    <li>By Price Range</li>
                    <li>By Metal and Stones</li>
                  </ul>
                </div>
                 </div>
          </li>
          <li>
            <Link to="/" className='nav__list'> <span>rings</span> </Link>
          </li>
          <li>
            <Link to="/" className='nav__list'> <span>earrings</span>
              <i className="bi bi-chevron-right"></i>

            </Link>
          </li>
          <li>
            <Link to="/" className='nav__list'> <span>pendants </span></Link>
          </li>
          <li>
            <Link to="/" className='nav__list'> <span>solitaire jwellery </span></Link>
          </li>
          <li>
            <Link to="/" className='nav__list'> <span>gifts</span> </Link>
          </li>
          <li>
            <Link to="/" className='nav__list'> <span>Shop Instagram</span> </Link>
          </li>
        </ul>
      </div>

    </>
  )
}

export default NavbarToggler