import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './scss/style.scss'
import store from './store';
import { Provider } from "react-redux"
import { getCartTotal } from './slices/CartSlice';
import "primereact/resources/themes/lara-light-cyan/theme.css";

import { PrimeReactProvider } from 'primereact/api';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
store.dispatch(getCartTotal())
root.render(
  <Provider store={store}>
    <PrimeReactProvider>
      <App />
    </PrimeReactProvider>
  </Provider>
);
