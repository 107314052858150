import React from 'react'
import { Outlet } from 'react-router'
import HeaderMain from './Header/HeaderMain' 
import Footer from './Footer'

const FrontendLayout = () => {
 
  return (
    <>
      <main>
      <HeaderMain />
        <Outlet />
      <Footer />
      </main>
    </>
  )
}

export default FrontendLayout
