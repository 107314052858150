import React from 'react'
import { Form } from 'react-bootstrap'

const ShortOptions = () => {
    return (
        <>
            <div className="sort-options">
                <div className="category-title">
                    <span>Ring</span>
                </div>
                <div className="sort-selector">
                    <div className="sort-title">

                    <span>Sort By</span>
                    </div>
                    <select className="form-select" aria-label="Default select example">
                        <option selected>Featured</option>
                        <option value="1">Latest</option>
                        <option value="2">Discounted</option>
                        <option value="3">Price: Low to High</option>
                    </select>
                </div>
            </div>
        </>
    )
}

export default ShortOptions