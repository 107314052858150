import axios from "./api"

const createOrders = (data) => {
    return axios.post('/orders', data)
}
const updateOrders = (id, data) => {
    return axios.put('/orders/' + id, data)
}
const getOrdersById = (id) => {
    return axios.get('/orders/' + id)
}
const getOrdersByOrderId = (id) => {
    return axios.get('/orders/get-order/' + id)
}
const getOrders = () => {
    return axios.get('/orders/get-all')
}
const validatePayment = (data) => {
    return axios.post('/orders/payment', data)
}

const orderService = {
    createOrders,
    updateOrders,
    validatePayment,
    getOrdersById,
    getOrdersByOrderId,
    getOrders
}

export default orderService;