import React from 'react'

const CopyRight = () => {
  return (
    <section className="copy-right">
    <div className="arsa-container-fluid">
      <div className="copyright-area">
        <img src={process.env.PUBLIC_URL+"/assets/images/payment-methods.png"} className='payment-methods-image img-fluid' alt="" />
      <span>Copyright © Arsa Gems 2023. All rights reserved.</span>
      </div>
    </div>
  </section>
  )
}

export default CopyRight