import axios from "./api"

const GetAllBrand = () => {
    return axios.get('/brand')
}
const CreateBrand = (payload) => {
    return axios.post('/brand', payload)
}

const brandService = {
    GetAllBrand,
    CreateBrand
}

export default brandService;