import DataTable from 'react-data-table-component';
import axios from '../../service/api';
import { useEffect, useState } from 'react';
import productService from '../../service/productService';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const ProductsCompo = () => {
    const [getdata, setdata] = useState<any>()
    const navigate = useNavigate()

    useEffect(() => {
        getUser();
    }, []);

    const getUser = async () => {
        try {
            const response = await productService.getAllproduct(1, '');
            const data = response?.data?.data;
            console.log("getdata", data);
            setdata(data);
        } catch (error) {
            console.error(error);
        }
    }
    // Edit Products
    const ForEdit = (slugid: any) => {
        navigate("edit/" + slugid)
    }
    // for delete
    const forDelete = async (id: any) => {
        try {
            const response = await axios.delete("http://localhost:5050/api/product/" + id);
            getUser()
            toast.success('Product Delete Successfully')

        } catch (error) {
            console.error(error);
        }
    };

    const columns = [
        {
            name: 'Product Name',
            selector: (row: any) => <h6 className='m-0 product-name'>{row.title}</h6>,
            sortable: true,
        },
        {
            name: 'Thumbnail',
            cell: (row: any) => <img src={row.feature_image} width="35" height="35" />
        },
        {
            name: 'Price',
            selector: (row: any) => row._id,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row: any) => row.status,
            sortable: true,
        },
        {
            name: 'Created',
            selector: (row: any) => row.updatedAt,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row: any) => <><button onClick={() => ForEdit(row.slug)} className='btn btn-success btn_edit'><i className="bi bi-pencil-square"></i></button>
                <button onClick={() => forDelete(row._id)} className='btn btn-danger btn_edit'><i className="bi bi-trash"></i></button>
            </>,
        },
        // {
        //     cell: (row: any) => <><button onClick={() => forDelete(row._id)} className=' edit-btn btn btn-danger'>Delete</button>
        //     </>,
        // },
    ];
    return (
        <>
            <div className="card p-4">
                <DataTable
                    columns={columns}
                    data={getdata}
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight='600px'
                    // selectableRows
                    selectableRowsHighlight
                    highlightOnHover
                    subHeader
                    subHeaderComponent={
                        <input className='searchbar' type='text' placeholder='Search Here' />
                    }
                    defaultSortAsc={true}
                />
            </div>
        </>
    )
}

export default ProductsCompo