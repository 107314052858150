import axios from "./api"

const addAddress = (data) => {
    return axios.post('/address', data)
}
const getAddressById = (id) => {
    return axios.get('/address/' + id)
}
const deleteAddress = (id) => {
    return axios.delete('/address/' + id)
}


const addressService = {
    addAddress,
    getAddressById,
    deleteAddress

}

export default addressService;