import React from 'react'

const MainFooter = () => {
  return (
    <>
    <section className="main-footer">
      <div className="arsa-container-fluid">
        <div className="row">
          <div className="col-md-6">
            <img src={process.env.PUBLIC_URL+"/assets/images/gems.png"} className='footer-image img-fluid' alt="" />
          </div>
          <div className="col-md-2">
            <span>About Us</span>
            <ul>
              <li>
                Who we are?
              </li>
              <li>Management Team
              </li>
              <li>Careers
              </li>
              <li>Franchise Enquiry</li>
            </ul>
          </div>
          <div className="col-md-2">
            <span>POLICIES</span>
            <ul>
              <li>
                30-Day Returns
              </li>
              <li>Lifetime Exchange & Buy back
              </li>
              <li>Privacy Policy
              </li>
              <li>Terms & Conditions</li>
              <li>Fraud Warning Disclaimer</li>
            </ul>
          </div>
          <div className="col-md-2">

            <span>JEWELLERY GUIDE</span>
            <ul>

              <li>Buying and Price Guide</li>

              <li>Certification Guide</li>
              <li>Diamond and Solitaire Guide</li>
              <li>Gemstone Guide</li>
              <li>Gifting Guide</li>
              <li>Jewellery Care Guide</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default MainFooter