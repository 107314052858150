import { configureStore } from '@reduxjs/toolkit'
import CounterSlice from '../slices/IncriDecre'
import AuthSlice from '../slices/AuthSlice';
import CartSlice from '../slices/CartSlice';
import currencyChangerSlice from '../slices/currencyChangerSlice';
import DropdownSlice from '../slices/DropdownSlice';

const store = configureStore({
    reducer: {
        counter: CounterSlice.reducer,
        user: AuthSlice.reducer,
        cart: CartSlice,
        currency: currencyChangerSlice.reducer,
        dropdown: DropdownSlice.reducer,
    }

})
export default store;
