import React, { useEffect, useState } from 'react'
import wishListService from '../../../service/wishlistService'
import { useSelector } from 'react-redux';
import { getAmount } from '../../../utils/utils';
import { Navigate, useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';

const WishList = () => {
  const [isLoading, setisLoading] = useState<boolean>(false)
  const [wishList, setWishList] = useState<any>([])
  const [show, setShow] = useState<any>(false);
  const user = useSelector((state: any) => state.user.userdata);
  const { currency, symbol } = useSelector((item: any) => item.currency)
  const Navigate = useNavigate()
  const getAllWishList = async () => {
    setisLoading(true)
    const res = await wishListService.getWishList(user?._id)
    setWishList(res?.data?.data)
    setisLoading(false)
  }

  useEffect(() => {
    getAllWishList()
  }, [])
  useEffect(() => {
    console.log('jk', wishList);
    
  }, [wishList])


  const handleshow = () => {
    setShow(true)
  }
  const handleRemove = async (id: any) => {
    await wishListService.deleteWishList(id)
    getAllWishList()
    setShow(false)


  }


  return (
    isLoading ?
      <div className="loader-wrapper">
        <span className="loader"></span>
      </div> :
      <>
        {
          wishList?.length ? wishList?.map((item: any) =>

            <div className="wishlist-wrapper">

              <div className="wishlist-image">
                <img className="img-fluid" src={item.products_Id?.feature_image} alt="" />
              </div>
              <div className="wishlist-title">
                <div className="remove-wish-product" onClick={() => setShow(true)}>
                  <i className="bi bi-trash-fill"></i>
                </div>

                <h6 onClick={() => Navigate('/products/' + item.products_Id.slug)}>{item.products_Id?.title}</h6>
                <div className="review-wrapper">
                  <div className="rivew-rating">
                    <span>

                      4.1
                      <i className="bi bi-star-fill"></i>
                    </span>
                  </div>
                  <span className='reviwed-user-count'>
                    ( 1,884 )
                  </span>
                </div>
                <div className="product-price">
                </div>
              </div>
              <Modal
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Modal title</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  are you sure you want to delete this product from wish list
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShow(false)}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={() => handleRemove(item._id)}>Delete</Button>
                </Modal.Footer>
              </Modal>
            </div>

          ) :
            <span>No wishlist available</span>

        }


      </>
  )
}

export default WishList