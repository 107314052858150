import React, { useRef, useEffect, useState } from 'react'
import { gsap } from 'gsap'
import { useGSAP } from '@gsap/react';
import { useNavigate } from 'react-router-dom';

const mainImages = [
    {
        image: `${require("../../assets/images/slider/slider1.jpeg")}`,
    }

];
const rightImages = [
    {
        image: `${require("../../assets/images/slider/slider1.1.jpeg")}`,
    },
    {
        image: `${require("../../assets/images/slider/slider1.1.jpeg")}`,
    },
    {
        image: `${require("../../assets/images/slider/slider1.1.jpeg")}`,
    }

];


function SliderMain() {
    const imageWidth = 620;
    const leftContentWidth = 600;
    const rightImageWidth = 258;
    const [state, setState] = useState<any>({
        isActive1: true,
        isActive2: false,
        isActive3: false
    });
    let imageList = useRef<any>(null)
    let sliderContent = useRef<any>(null)
    let rightContent = useRef<any>(null)
    const Navigate = useNavigate()
    useGSAP(
        () => {
            gsap.to(imageList.current.children[0], {
                x: -0,
                ease: 'power3.in',
                duration: 1,
                delay: 1
            });
            gsap.to(sliderContent.current.children[0], {
                x: -0,
                ease: 'power3.in',
                duration: 1,
                delay: 1.2
            });
            gsap.to(rightContent.current.children[0], {
                x: -0,
                ease: 'power3.in',
                duration: 1,
                delay: 1.3
            });
        })

    const slideLeft = (index: any, duration: any, delay: any, multiplied = 1,) => {
        gsap.to(imageList.current.children[index], {
            x: -imageWidth * multiplied,
            ease: 'power3.in',
            duration: duration,
            delay: delay
        });
        gsap.to(sliderContent.current.children[index], {
            x: -leftContentWidth * multiplied,
            ease: 'power3.in',
            duration: duration,
            delay: delay
        });
        gsap.to(rightContent.current.children[index], {
            x: -rightImageWidth * multiplied,
            ease: 'power3.in',
            duration: duration,
            delay: delay
        });
    };

    const nextSlide = () => {
        if (imageList.current.children[0].classList.contains("active")) {
            setState({ isActive1: false, isActive2: true });
            slideLeft(0, 1, 0);
            slideLeft(1, 1, 1.2);
            slideLeft(2, 1, 1.2);
            slideLeft(2, 0, 1.2);
        }
        else if (imageList.current.children[1].classList.contains("active")) {
            setState({ isActive2: false, isActive3: true });
            slideLeft(1, 1, 0, 2);
            slideLeft(2, 1, 1.2, 2);
        }

    }

    return (
        <section className='main-slider-wrapper'>
            <div className="arsa-container-fluid">
                <div className="main-slider-svg-line">
                    <img src="/assets/images/slider/slider-line1.png" alt="svg line" />
                </div>
                <div className="slider">
                    <div className="hero-img" ref={imageList}>
                        <img src={mainImages[0].image} alt="sliderimage" className={state.isActive1 ? "active" : ""} />
                        <img src={mainImages[0].image} alt="sliderimage" className={state.isActive2 ? "active" : ""} />
                        <img src={mainImages[0].image} alt="sliderimage" className={state.isActive3 ? "active" : ""} />
                    </div>
                    <div className="slider-left-content" ref={sliderContent}>
                        <div className={`left-content-container ${state.isActive1 ? "active" : ""}`} >
                            <span >diamonds</span>
                            <h4>handcrafted <br /> Jewellery</h4>
                            <button className='arsa-button' onClick={() => Navigate('/products')}>shop now</button>
                        </div>
                        <div className={`left-content-container ${state.isActive2 ? "active" : ""}`} >
                            <span >diamonds</span>
                            <h4>handcrafted <br /> Jewellery</h4>
                            <button className='arsa-button' onClick={() => Navigate('/products')}>shop now</button>
                        </div>
                        <div className={`left-content-container ${state.isActive3 ? "active" : ""}`} >
                            <span >diamonds</span>
                            <h4>handcrafted <br /> Jewellery</h4>
                            <button className='arsa-button' onClick={() => Navigate('/products')}>shop now</button>
                        </div>
                    </div>
                    <div className="slider-right-content">
                        <div className="right-content-wrapper" >
                            <div className="right-content ">
                                <div className="images" ref={rightContent}>
                                    <img src={rightImages[0].image} alt="smallsliderimage" className={state.isActive1 ? "active" : ""} />
                                    <img src={rightImages[1]?.image} alt="smallsliderimage" className={state.isActive2 ? "active" : ""} />
                                    <img src={rightImages[2]?.image} alt="smallsliderimage" className={state.isActive3 ? "active" : ""} />
                                </div>
                                <h5>curve bold gold ring</h5>
                                <h6>by rebekka notkin</h6>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="slider-arrow">
                    <div className="right-arrow" >
                        <i className="bi bi-chevron-left"></i>
                    </div>
                    <div className="left-arrow" onClick={nextSlide}>
                        <i className="bi bi-chevron-right"></i>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SliderMain