import axios from "./api"

const GetAllTag = () => {
    return axios.get('/tag')
}
const CreateTag = (payload) => {
    return axios.post('/tag', payload)
}

const tagService = {
    GetAllTag,
    CreateTag
}

export default tagService;