import * as Yup from "yup";

export const signUpSchema = Yup.object({
    firstName: Yup.string().min(2).max(25).required("Please enter your First Name"),
    lastName: Yup.string().min(2).max(25).required("Please enter your Last Name"),
    email: Yup.string().email().required("Please enter your email"),
    password: Yup.string().min(3).required("Please enter your password"),
    co_password: Yup.string()
        .required()
        .oneOf([Yup.ref("password"), ''], "Password must match"),
});
