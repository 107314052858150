import React from 'react'
interface SectionTitleProps  {
    title: String,
    desc?: String
}
const SectionTitle = ({title, desc}:SectionTitleProps) => {
  return (
    <>
     <div className="section-title">
        <h2>{title}</h2>
        <p>{desc}</p>
        <hr />
     </div> 
    </>
  )
}

export default SectionTitle
