import AdminHeader from './AdminHeader'
import AdminSidebar from './AdminSidebar'
import AdminFooter from './AdminFooter'
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux"

const DashboardLayout = () => {
  const location = useLocation(); 
  const token = useSelector((state:any) => state.user.token)
  const userdata = useSelector((state:any) => state.user.userdata)


  return (
    token && userdata?.is_admin ?
    <>
      <div className="admin-layout">
        <div className="admin-sidebar open">
          <AdminSidebar />
        </div>
        <div className='admin-layout-body sidebar-open'>
          <AdminHeader />
          <main className='admin-body-main'>
            <Outlet />
          </main>
          <AdminFooter />
        </div>
      </div>
    </>
     :
     <Navigate to="/login" state={{ from: location }} replace />
  )
}

export default DashboardLayout
