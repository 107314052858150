import React from 'react'
import OrderDetails from '../../../components/common/OrderDetails'
const Order = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="d-flex align-items-center justify-content-between pb-4">
                    <div className="product-title">
                        <h2 className='main-title'>Order List</h2>
                        {/* <p className='m-0'>Lorem ipsum dolor sit amet.</p> */}
                    </div>
                </div>
                <OrderDetails />
            </div>
        </>
    )
}

export default Order