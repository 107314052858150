import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import productService from '../../../service/productService';

const NavbarMenu = () => {
    const [categories, setCategories] = useState<any>([])
    function buildHierarchy(data: any) {
        // Create a map of items by their _id
        const itemMap = new Map(data.map((item: any) => [item._id, { ...item, children: [] }]));
    
        // Root of the hierarchy
        const hierarchy: any = [];
    
        data.forEach((item: any) => {
          if (item.parent) {
            // If the item has a parent, add it to its parent's children array
            const parent: any = itemMap.get(item.parent);
            if (parent) {
              parent.children.push(itemMap.get(item._id));
            }
          } else {
            // If no parent, it's a root item, add to the hierarchy
            hierarchy.push(itemMap.get(item._id));
          }
        });
    
        return hierarchy;
      }
    
      const getAllCategory = async () => {
        const response = await productService.getAllcategories()
        const maindata = buildHierarchy(response?.data?.data) || []
        setCategories(maindata)
      }

  useEffect(() => {
    getAllCategory()
  }, [])
  return (
    <ul>
    {
      categories ? categories?.map((el: any) => (
        <li key={el?._id}>
          <span  className='nav__list'>
            <span><Link to={'/products?ss=ss&main_category=' + el?._id} >{el.title}</Link></span>
            {el?.children && el?.children.length > 0 ? <>
              <i className="bi bi-chevron-down"></i>
              <div className="dropdown__container">
                {el?.children?.map((elChild: any) => <div className="dropdown__group" key={elChild?._id}>

                  <span className='dropdown__title'> <Link to={'/products?ss=ss&main_category=' + elChild?._id} >{elChild?.title} </Link></span>
                  {elChild?.children && elChild?.children.length > 0 ? <>
                    <hr />
                    <ul className="dropdown__list">
                      {elChild?.children?.map((elChild2: any) => <li>
                        <Link className="dropdown__link" to={'/products?ss=ss&main_category=' +elChild2?._id+ '&child_category=' + elChild2?._id}>{elChild2?.title} </Link>
                      </li>)}
                    </ul>
                  </> : null}
                </div>)}
              </div> </> : null}
          </span>
        </li>
      )) : ""
    }
  </ul>
  )
}

export default NavbarMenu