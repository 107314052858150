import React from 'react'
import { useNavigate } from 'react-router-dom'
import TagList from '../../../components/common/TagList'

const Tag = () => {
    const navigate = useNavigate()
    const OnCategoeypage = () => {
        navigate("/admin/tag/add")
    }
    return (
        <div className="container-fluid">
            <div className="d-flex align-items-center justify-content-between pb-4">
                <div className="product-title">
                    <h2 className='main-title'>Tag List</h2>
                    <p className='m-0'>Lorem ipsum dolor sit amet.</p>
                </div>
                <div className="product-btn-area">
                    <button onClick={OnCategoeypage} className='primary-button ms-1'>Create New</button>
                </div>
            </div>
            <TagList/>
        </div>
    )
}

export default Tag