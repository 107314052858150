import React from 'react'
import { useNavigate } from 'react-router-dom'
import CategoriesCompo from '../../../components/common/CategoriesCOmpo'
const Category = () => {

  const navigate = useNavigate()
  const OnCategoeypage = () => {
    navigate("/admin/category/addcategory")
  }
  return (
    <>
      <div className="container-fluid">
        <div className="d-flex align-items-center justify-content-between pb-4">
          <div className="product-title">
            <h2 className='main-title'>Category List</h2>
            <p className='m-0'>Lorem ipsum dolor sit amet.</p>
          </div>
          <input className='searchbar bg-white' type='text' placeholder='Search Here' />
          <div className="product-btn-area">
            <button onClick={OnCategoeypage} className='primary-button ms-1'>Create New</button>
          </div>

        </div>
        <CategoriesCompo />
      </div>
    </>
  )
}

export default Category
