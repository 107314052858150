import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { getDymondPriceById } from '../../../utils/utils';

function DiamontInput({ item, onHandleItemChange }: any) {
    const [fromData, setFromData] = useState(item)

    const diamondTypeList = useSelector((state: any) => state.dropdown.diamondTypeList);
 

    const onChange = (e: any) => {
        const { name, value } = e.target
        setFromData((prev:any) => {
            let diamondPrice = prev?.diamondPrice 
            if(name === 'diamondCarat'){ 
                diamondPrice = getDymondPriceById(diamondTypeList, prev?.diamondType) * parseFloat(value)
            }else if(name === 'diamondType'){
                diamondPrice = getDymondPriceById(diamondTypeList, value) * parseFloat(prev?.diamondCarat)
            }

            const newData = { ...prev, [name]: value, diamondPrice } 

            onHandleItemChange(newData)
            return newData
        }) 
    } 

    return (
        <>
            <div className="col-lg-4">
                <label className="form-label">Diamond Clarity</label>
                <select onBlur={onChange} className="form-select" name="diamondType" value={fromData.diamondType} onChange={onChange}>
                    <option value="">No Diamond</option>
                    {
                        diamondTypeList ? diamondTypeList.map((i: any, index: any) =>
                            <option key={index} value={i._id}>{i.name}</option>
                        ) : null
                    }
                </select>
            </div>
            <div className="col-lg-4 pt-2">
                <label className="form-label ">Diamond Carat (wt)</label>
                <input type="number" className="form-control create-product" placeholder="Diamond Carat (wt)" onBlur={onChange} name="diamondCarat" value={fromData.diamondCarat} onChange={onChange} />
            </div>
            <div className="col-lg-4 pt-2">
                <label className="form-label">Diamond Qty</label>
                <input type="number" className="form-control create-product" placeholder='Diamond Qty' onBlur={onChange} name="diamondQty" value={fromData.diamondQty} onChange={onChange} />
            </div>
            <div className="col-lg-4 pt-2">
                <label className="form-label">Diamond Shape</label>
                <input type="text" className="form-control create-product" name="diamondShape" onBlur={onChange} value={fromData.diamondShape} onChange={onChange} />
            </div>
        </>
    )
}

export default DiamontInput