import React, { useEffect, useMemo } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { getAmount } from '../../../utils/utils';
import { useSelector } from 'react-redux';

const ProductCard = ({data}:any) => {
    const { currency, symbol } = useSelector((item: any) => item.currency)
    const location = useLocation();

const getIntialAmount = useMemo(() =>{
    let stoneTotal = 0
    let productTotal = 0
        for (let stone of data?.stone){
            stoneTotal += stone.stonePrice
        }
         productTotal = stoneTotal + Number(data?.metal[0]?.price) +  Number(data?.diamond[0]?.diamondPrice) 
    return productTotal;
    
},[data])


useEffect(() => {
    const fullPathAfterProducts = location.pathname.replace('/products', '') + location.search;
    console.log("gho", fullPathAfterProducts);
}, [location]);

const Navigate = useNavigate()

    return (
        <>
            <div className="product-card-wrapper" >
                <div className="product__image">
                    <div className="product__media">
                        <Link to={'/products/' + data.slug} >
                            <img className="img-fluid" src={data.feature_image} alt="" />
                        </Link>
                    </div>
                    <div className="product__media_hover">
                        <Link to={'/products/' + data.slug}>
                            <img className="img-fluid" src="./assets/images/filter/filter-image-hover-2.jpeg" alt="" />
                        </Link>
                    </div>
                    {/* <div className="product__action">
                        <Link to={'/products/id'}>Add To Cart</Link>
                    </div> */}
                </div>
                <div className="products__content">
                    <div className="title__holder" onClick={()=> Navigate('/products/' + data.slug)}>
                        <div className="product__title">
                            {data?.title}
                        </div>
                        {/* <div className="products__actions">
                            <i className="bi bi-eye"></i>
                            <i className="bi bi-heart"></i>
                        </div> */}
                    </div>
                    <div className="product__info">
                        Bracelets/elegant/gold accssesories
                    </div>
                    <div className="products__price">
                    {symbol}{getAmount(currency, getIntialAmount)}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductCard