import React from "react";
import { Link, Path } from "react-router-dom";
interface buttoncompo {
    buttonurl: any,
    buttontext: String,
}
const ButtonCompo = ({ buttonurl, buttontext }: buttoncompo) => {
    return (
        <div>
            <Link to={buttonurl} className="btn-get-started scrollto">
                {buttontext}
            </Link>
        </div>
    );
};

export default ButtonCompo;
