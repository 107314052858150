import axios from "./api";

// Add Product
const GetAttribute = () => {
  return axios.get("/attributes");
};

const CreateAttribute = (payload) => {
  return axios.post("/attributes", payload);
};

const GetAttributeSet = () => {
  return axios.get("/attributeset");
};

const GetOneAttribute = (payload) => {
  return axios.get("/attributes/" + payload);
};

const UpdateAttribute = (id, payload) => {
  return axios.put("/attributes/" + id, payload);
};



const attributeService = {
  GetAttribute,
  GetOneAttribute,
  UpdateAttribute,
  GetAttributeSet,
  CreateAttribute

};

export default attributeService;
