import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useSearchParams } from 'react-router-dom';

const Breadcrumbs = () => {
    const [searchParams] = useSearchParams();
    const category = searchParams.get('category') || ''
    const subcategory = searchParams.get('child_category') || ''

    
    return (
        <>
            <div className="bread-crumb">
                <div className="arsa-container-fluid">
                    <div className="menu-name">
                        <span>Engagement Ring</span>
                        <span className='product-count'>340 Designs</span>
                    </div>
                    <div className="page-map">
                        <Breadcrumb>
                            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
                                {category?category:""}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>{subcategory?subcategory:""}</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Breadcrumbs