import React from 'react';
import { BrowserRouter as Router, Routes, Route, redirect, useParams } from "react-router-dom"
import DashboardLayout from "./layouts/Dashboard/DasboardLayout";
import FrontendLayout from "./layouts/Frontend/FrontendLayout";
import AuthLayout from "./layouts/authLayout";
import LoginLayout from "./layouts/LoginLayout";
import Dashboard from "./pages/Dashboard/dashboard/Dashboard";
import Home from "./pages/Frontend/Home";
import Products from "./pages/Dashboard/products/Products";
import Category from "./pages/Dashboard/products/Category";
import Order from "./pages/Dashboard/order/Order";
import AddProduct from "./pages/Dashboard/products/AddProduct";
import Transactions from "./pages/Dashboard/transactions/Transactions";
import OrderDetails from "./pages/Dashboard/order/OrderDetails";
import ProductsFrontend from "./pages/Frontend/Products"
import Login from './pages/Frontend/Login';
import { Toaster } from 'react-hot-toast';
import Register from './pages/Frontend/Register';
import AddCategory from './pages/Dashboard/products/AddCategory';
import UpdateProduct from './pages/Dashboard/products/UpdateProduct';
import SingleProduct from './pages/Frontend/SingleProduct';
import Cart from './pages/Frontend/Cart';
import Checkout from './pages/Frontend/Checkout';
import Massonary from './pages/Dashboard/massonary/Massonary';
import EditCategory from './pages/Dashboard/products/EditCategory';
import Brands from './pages/Dashboard/products/Brands';
import Attributes from './pages/Dashboard/products/Attributes';
import EditAttribute from './pages/Dashboard/products/EditAttribute';
import AddAtrribute from './pages/Dashboard/products/AddAtrribute';
import AddBrand from './pages/Dashboard/products/AddBrand';
import Tag from './pages/Dashboard/products/Tag';
import AddTag from './pages/Dashboard/products/AddTag';
import PriceSetting from './pages/Dashboard/priceSetting/PriceSetting';
import Setting from './pages/Dashboard/setting/Setting';
import Offers from "./pages/Dashboard/offers/Offers"
import OffersAdd from "./pages/Dashboard/offers/addOffers";
import Account from './pages/Frontend/user/Account';
import BasicDetails from './pages/Frontend/user/BasicDetails';
import Address from './pages/Frontend/user/Address'
import UserOrder from './pages/Frontend/user/Order'
import WishList from './pages/Frontend/user/WishList';
import AddressForm from './pages/Frontend/user/AddressForm';
import ErrorPage from './layouts/ErrorPage';
import Productsv2 from './layouts/Productsv2';
import SinglePageProduct from './layouts/SinglePageProduct';
import Login2 from './pages/Frontend/Login2';
import OtherpageLayout from './layouts/Frontend/OtherpageLayout';
import OrderCustomerDetails from './pages/Dashboard/order/OrderDetails';
function App() {
    return (
        <>
            <Toaster />
            <Router>
                <Routes>
                    {/* <Route path="/frontend" element={<Frontend />} /> */}
                    <Route path="/error" element={<ErrorPage />} />
                    <Route path="/productsv2" element={<Productsv2 />} />
                    <Route path="/singleproduct" element={<SinglePageProduct />} />
                    <Route path="/" element={<FrontendLayout />}>
                        <Route index element={<Home />} />
                    </Route>
                    <Route path="/" element={<OtherpageLayout />}>
                        <Route path="/products" element={<ProductsFrontend />} />
                        <Route path="products/:id" element={<SingleProduct />} />
                      
                        <Route path="/cart" element={<Cart />} />
                        <Route path="/" element={<AuthLayout />}>
                            <Route path="/checkout" element={<Checkout />} />
                            <Route path="/account" element={<Account />}>
                                <Route index element={<BasicDetails />} />
                                <Route path="address" element={<Address />} />
                                <Route path="order" element={<UserOrder />} />
                                <Route path="wishlist" element={<WishList />} />
                                <Route path="add-address" element={<AddressForm />} />
                            </Route>
                        </Route>
                    </Route>
                    <Route path="admin" element={<DashboardLayout />}>
                        <Route index element={<Dashboard />} />
                        <Route path="attributes" >
                            <Route index element={<Attributes />} ></Route>
                            <Route path="add" element={<AddAtrribute />} />
                            <Route path="edit/:id" element={<EditAttribute />} />
                        </Route>
                        <Route path="category" >
                            <Route index element={<Category />} ></Route>
                            <Route path="addcategory" element={<AddCategory />} ></Route>
                            <Route path="edit/:slug" element={<EditCategory />} />
                        </Route>
                        <Route path="brands" >
                            <Route index element={<Brands />} ></Route>
                            <Route path="add" element={<AddBrand />} ></Route>
                            <Route path="edit/:slug" element={<EditCategory />} />
                        </Route>
                        <Route path="tag" >
                            <Route index element={<Tag />} ></Route>
                            <Route path="add" element={<AddTag />} ></Route>
                            <Route path="edit/:slug" element={<EditCategory />} />
                        </Route>
                        <Route path="products" >
                            <Route index element={<Products />} />
                            <Route path="add" element={<AddProduct />} />
                            <Route path="edit/:slug" element={<UpdateProduct />} />
                        </Route> 
                        <Route path="order" element={<Order />} />
                        <Route path="detail" element={<OrderDetails />} />
                        <Route path="detail/:id" element={<OrderCustomerDetails />} />
                        <Route path="transactions" element={<Transactions />} />
                        <Route path="pricesetting" element={<PriceSetting />} />
                        <Route path="setting" element={<Setting />} />
                        <Route path="offers">
                            <Route index element={<Offers />} />
                            <Route path="add" element={<OffersAdd />} />

                        </Route>
                    </Route >
                    <Route path="/" element={<LoginLayout />}>
                        {/* <Route path="/login" element={<Login />} /> */}
                        <Route path="/register" element={<Register />} />
                        <Route path="/login" element={<Login2 />} />
                    </Route>
                </Routes >
            </Router >

        </>
    );
}

export default App;
