import { createSlice } from '@reduxjs/toolkit'

const CounterSlice = createSlice({
    name: 'counetr',
    initialState: {
        value: 0
    },
    reducers: {
        addValue(state) {
            state.value += 1
        },
        minusValue(state) {
            state.value -= 1
        },

    }


})


// console.log(CounterSlice.actions);

export const { addValue, minusValue } = CounterSlice.actions;
export default CounterSlice;
