import React, { Fragment, useEffect, useState } from 'react'
import orderService from '../../../service/orderService'
import { useSelector } from 'react-redux';
import { getAmount } from '../../../utils/utils';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';

const Order = () => {
   const [orders, setOrders] = useState<any>([])
   const [isLoading, setisLoading] = useState<boolean>(false)
   const user = useSelector((state: any) => state.user.userdata);
   const { currency, symbol } = useSelector((item: any) => item.currency)

const Navigate = useNavigate()
   const getOrder = async () => {
      setisLoading(true)
      const res = await orderService.getOrdersById(user?._id)
      setOrders(res?.data?.data)
      setisLoading(false)
   }


   useEffect(() => {
      console.log('orders', orders);
      
   }, [orders])

   useEffect(() => {
      getOrder()
   }, [])
   return (
      isLoading ? 
      <div className="loader-wrapper">
          <span className="loader"></span>
      </div> :
      <>
         <div className="table-responsive">
            <table className="table mb-0">
               <tbody>
                  {
                     orders?orders.map((item: any, index: any) =>
                        item?.product.map((order: any) =>
                           <tr>
                              <td><img src={order.feature_image} className='img-fluid order-image' alt="" /></td>
                              <td className="py-3" onClick={()=>Navigate('/products/'+order.slug)} >{order?.title}</td>
                              <td className="py-3">
                                 <span className={`badge ${item.payment_status === "successfull" ? "bg-soft-info" : item.payment_status === "falied" ? "text-soft-danger" : "text-primary"} m-0`}>{item?.payment_status}</span>
                              </td>
                              <td className="py-3">{symbol}{getAmount(currency, item?.payment_amount / 100)}</td>
                              <td><Moment format="YYYY/MM/DD">{item.createdAt}</Moment></td>
                           </tr>
                        )
                     ):
                     <span>no orders yet</span>
                  }
               </tbody>
            </table>
         </div>
      </>
   )
}

export default Order

