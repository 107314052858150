// export const imageToBase64=(element)=>{
//     let e = element.target.value
//     var file = element.files[0];
//     var reader = new FileReader();
//     reader.onloadend = function () {
//         console.log('RESULT', reader.result)
//     }
//     reader.readAsDataURL(file)
//     console.log(reader);
// }

export const currencyChanger = async (el) => {
    if (el?.target?.value === "USD") {
        fetch(`https://v6.exchangerate-api.com/v6/047e336bb3089b64a8fbec6f/latest/${el.target.value}`).then((res) => {
            res.json((data) => {
                console.log("currency", data);
                // return res
            })

        })
    }
}

var currencyRates = {
    usd: 1,
    inr: 0.011982777
}

export const getAmount = (currency, amount) => {
    if (currency === 'INR') {
        return parseFloat((Math.round(amount * 100) / 100).toFixed(2))
    }
    if (currency === 'USD') {
        var USDAmount = amount * currencyRates.inr
        return parseFloat((Math.round(USDAmount / currencyRates.usd * 100) / 100).toFixed(2))
    }
}
export const getOfferAmount = (currency, amount, offer) => {
    console.log('offer', offer);
    const currentAmount = getAmount(currency, amount)
    if (!offer) {
        return currentAmount
    }
    if(offer.offer_type === 'flat rate'){
        return currentAmount - offer.amount
    }
    if(offer.offer_type === 'percentage'){
        return currentAmount - offer.amount
    }
}
export const discountType = (offer) => {
    if(offer.offer_type === 'flat rate'){
        return 'flate ' + offer.amount + ' Off'
    }
    if(offer.offer_type === 'percentage'){
        return 'flate ' + offer.amount + ' % Off'
    }
    
}



export const getDymondPriceById = (diamondTypeList, id) => {
    if(!diamondTypeList){
        return 0
    }
    let data = diamondTypeList?.filter((i) => String(i._id) === String(id))
    if (data && data.length > 0) {
        return parseFloat(data[0]?.price)
    } else {
        return 0
    }
} 

export const getStonePriceById = (stoneTypeList, id) => {
    if(!stoneTypeList){
        return 0
    }
    let data = stoneTypeList.filter((i) => String(i._id) === String(id))
    if (data && data.length > 0) {
        return parseFloat(data[0]?.price)
    } else {
        return 0
    }
} 

