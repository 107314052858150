import React from 'react'
import { Link, Path } from 'react-router-dom'
import ButtonCompo from './ButtonCompo'
interface dashboardboxprops {
    title: String,
    desc: any,
    address: any,
    icon: String,
    buttontext: String,
    buttonurl: any

}
const ContactOption = ({ title, desc, address, icon, buttontext, buttonurl }: dashboardboxprops) => {
    return (
        <>
            <article className="icontext">
                <span className="icon icon-sm rounded-circle bg-primary-light"><i className={"bi " + icon}></i></span>
                <div className="text">
                    <h6 className="mb-1 card-title">{title}</h6>
                    <span className="text-sm"> {desc?.customer_name} </span>
                    <span className="text-sm"> {desc?.customer_phone} </span>
                    <span className="text-sm"> {address?.street_name} {address?.locality} </span>
                    <span className="text-sm"> {address?.dist} {address?.city} {address?.pincode} </span>
                    <span className="text-sm"> {address?.dist} {address?.city} {address?.pincode} </span>
                    <br/>
                    {
                        desc?.product?.map((item: any) =>
                            <>

                                <span className="text-sm">{item._id}</span>
                                <span className="text-sm">{item.title}</span>
                                <span className="text-sm">{item.quantity}</span>
                                <span className="text-sm">{item.price}</span>
                                <span className="text-sm">{item.selectedCustomization.Gender}</span>
                            </>


                        )
                    }
                    {
                        buttonurl ? <ButtonCompo buttonurl={buttonurl} buttontext={buttontext} /> : ""
                    }

                </div>
            </article>
        </>
    )
}

export default ContactOption