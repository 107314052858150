import React from 'react'
import { useSelector } from 'react-redux'
import { getAmount } from '../../utils/utils'

const ProductSummary = ({onClick}:any) => { 
    const cartTotalAmount = useSelector((item:any)=>item.cart.cartTotalAmount)
    const { currency, symbol } = useSelector((item: any) => item.currency)
    return (
        <>
            <aside className="order-summary-wrap">
                <div className="order-summary">
                    <div className="order-summary-top">
                        <h3>Order Summary</h3>
                    </div>
                    <div className="order-summary-middle">
                        <ul className="list-inline order-summary-list">
                            <li><label>Subtotal</label> <span className="price-amount">{symbol}{getAmount(currency, cartTotalAmount)}</span></li>
                        </ul>
                        <div className="order-summary-total">
                            <label>Total</label>
                            <span className="total-price">{symbol}{getAmount(currency, cartTotalAmount)}</span>
                        </div>
                    </div>
                    <div className="order-summary-bottom">
                        <button className='arsa-btn btn custom-btn-dark' onClick={onClick}>Proceed to checkout</button>
                    </div>
                </div>
            </aside>
        </>
    )
}

export default ProductSummary
