import React from 'react'

const Masonary = () => {
  return (
    <section className="masonary">
        <div className="gallery-item-1">
            <img src="./assets/images/gallery-1.jpeg" alt="" className="img-fluid" />
        </div>
        <div className="gallery-item-2">
            <img src="./assets/images/gallery-2.jpeg" alt="" className="img-fluid" />
        </div>
        <div className="gallery-item-3">
            <img src="./assets/images/gallery-3.jpeg" alt="" className="img-fluid" />
        </div>
        <div className="gallery-item-4">
            <img src="./assets/images/gallery-4.jpeg" alt="" className="img-fluid" />
        </div>
        <div className="gallery-item-5">
            <img src="./assets/images/gallery-5.jpeg" alt="" className="img-fluid" />
        </div>
    </section>
  )
}

export default Masonary