import React, { useEffect } from 'react'
import offerService from '../../../service/offerService'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik';
import toast from 'react-hot-toast';

const Offers = () => {
  const navigate = useNavigate()
  useEffect(() => {
    getOffers()
  }, [])

  const createOffer = async () => {
    let response = await offerService.CreateOffer()
    let data = response?.data
  }
  const getOffers = async () => {
    let response = await offerService.GetAllOffers()
    let data = response?.data
    console.log('offer', data);
  }

  const offerCreate = () => {
    navigate('add')
  }

  const { values, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik({

    initialValues: {
      name: "",
      offer_type:"",
      amount:""
    },
    onSubmit: async (value) => {
      try {
        let response = await offerService.CreateOffer(value)
        toast.success(response?.data?.msg)
        resetForm()

      } catch (error) {
        toast.error((error as any)?.response?.data?.msg ? (error as any)?.response?.data?.msg : 'error')
      }
    },




  });

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between pb-4">
            <div className="product-title">
              <h2 className='main-title'>Add New Offer</h2>
            </div>
            <div className="product-btn-area">
              <button className='primary-button ms-1' type='submit'>Publish</button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="card p-4">
                <div className="card-top">
                  <h5 className='m-0'>Basic</h5>
                </div>
                <div className="card-body">
                  <div className="mb-4">
                    <label htmlFor="name" className="form-label">Offer Name</label>
                    <input type="text" placeholder="Type offer name Here" className="form-control create-product" value={values.name} name="name" onChange={handleChange}/>
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Offer Type</label>
                    <select className="form-select" name="offer_type" onChange={handleChange}>
                      <option value="">Select offer type</option>
                      <option value="flat rate">flat rate</option>
                      <option value="percentage">percentage</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Amount / Percentage</label>
                    <input type="number" placeholder="Type amount or percentage" className="form-control create-product" name="amount" value={values.amount} onChange={handleChange}/>
                  </div>
                  <button className='primary-button ms-1' type='submit'>Create</button>
                </div>
              </div>


            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default Offers