import React, { useState } from 'react'
import { useSelector } from 'react-redux'

function StoneInput({ item, onHandleItemChange }: any) { 

    const [fromData, setFromData] = useState(item)

    const onChange = (e: any) => {
        const { name, value } = e.target
        
        setFromData((prev:any) => {
            let stonePrice = prev?.stonePrice 
            if(name === 'stoneCarat'){ 
                stonePrice =  parseFloat(prev?.perCaratePrice) * parseFloat(value)
            }else if(name === 'perCaratePrice'){
                stonePrice =   parseFloat(value) * parseFloat(prev?.stoneCarat)
            }

            const newData = { ...prev, [name]: value, stonePrice } 

            onHandleItemChange(newData)
            return newData
        }) 
    } 

    return (
        <>
      

            <div className="col-lg-4">
                <label className="form-label">Color Stone Name</label>
                <input onBlur={onChange}  type="text" className="form-control create-product" name="stoneType" value={fromData.stoneType} onChange={onChange} />
            </div>
            <div className="col-lg-4">
                <label className="form-label">Per carat Price</label>
                <input onBlur={onChange}  type="number" className="form-control create-product" name="perCaratePrice" value={fromData.perCaratePrice} onChange={onChange} />
            </div>
            <div className="col-lg-4">
                <label className="form-label">Stone Carat (Wt)</label>
                <input onBlur={onChange}  type="number" className="form-control create-product" name="stoneCarat" value={fromData.stoneCarat} onChange={onChange} />
            </div>
            <div className="col-lg-4 pt-2">
                <label className="form-label">Stone Qty</label>
                <input onBlur={onChange}  type="number" className="form-control create-product" placeholder='Stone QTY' name="stoneQty" value={fromData.stoneQty} onChange={onChange} />
            </div>
            <div className="col-lg-4">
                <label className="form-label">Stone Shape</label>
                <input onBlur={onChange}  type="text" className="form-control create-product" placeholder='Stone Shape' name="stoneShape" value={fromData.stoneShape} onChange={onChange} />
            </div>
            <div className="col-lg-4">
                <label className="form-label">Stone Color</label>
                <input onBlur={onChange}  type="text" className="form-control create-product" placeholder='Stone Colour' name="stoneColor" value={fromData.stoneColor} onChange={onChange} />
            </div>
        </>
    )
}

export default StoneInput